/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import listTemplate from './report-schedule-list.html';
import editTemplate from './report-schedule-edit.html';

import standardModule from '../common/standard-module';
import reportScheduleController from './report-schedule-controller';
import localUserPermissions from '../../components/session/local-user-permissions';
import {PERMISSIONS} from "../../components/auth/auth-constants";
import copyTemplate from "../common/copy-actions.html";
import reportScheduleActions from './report-schedule-actions-controller';
import nextReportComponent from "./next-report-component";

const MODULE_NAME = 'report-schedules-module';

angular.module(MODULE_NAME, [standardModule, reportScheduleController, reportScheduleActions, localUserPermissions, nextReportComponent])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('report-schedules.report-schedule', 'ReportSchedule', 'app.rep', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        readOnly: /*@ngInject*/ function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageReportTemplates.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.runReports.apiKey);
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'ReportScheduleCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: 'ReportScheduleActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          }
        }
      }, 'ReportSchedule'));
  });

export default MODULE_NAME;