/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './user-copy-modal.html';
import {Uuid} from "../../../components/util/uuid";

import standardActionsModule from '../../common/standard-actions-module';

const MODULE_NAME = 'user-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('UserActionsCtrl', function($q, toastr, $stateParams, adnModalLoading, $state, $uibModal, localUserProfile, model, modelComms, readOnly) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;

    ctrl.blockDelete = model.id === localUserProfile.get("userId") ? "Cannot archive or delete yourself from the system." : "";
    ctrl.blockDelete = ctrl.blockDelete || model.username === 'adnuntius@adnuntius.com' && localUserProfile.get('username') !== 'adnuntius@adnuntius.com';

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        template: template,
        windowClass: '',
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.user = data;
              modalCtrl.user.name = data.name + " (copy)";
              modalCtrl.user.displayName = data.displayName + " (copy)";
              modalCtrl.user.username = "";
            }
          });

          modalCtrl.destination = true;

          modalCtrl.copyUser = function() {
            modalCtrl.user.id = Uuid.generate();
            modalCtrl.disabled = false;
            modalCtrl.validationErrors = [];
            modalCtrl.user.$save().then(function(savedUser) {
              modalCtrl.disabled = true;
              adnModalLoading.applyModalLoading();
              modalInstance.close({user: savedUser, destination: modalCtrl.destination});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        if (result.destination) {
          $q.all(result.allPromises).then(function() {
            $state.go('app.users.user', {id: result.user.id});
          });
        } else {
          toastr.success("A copy of this user was successfully created.", "User Copied");
        }
      });
    };
  });

export default MODULE_NAME;