/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";
import {LOCALES} from "../../common/constants/locales";

const MODULE_NAME = 'email-translation-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('EmailTranslationCtrl', function(model, modelComms, defaultEmailTranslationsResource, LocalUserPermissions) {
    const ctrl = this;

    let defaultEmailTranslations;

    function specifyEmails() {
      if (!ctrl.model.messageDefinition || !defaultEmailTranslations) {
        return;
      }
      if (!ctrl.model.specifyEmailSubject) {
        ctrl.model.emailSubject = _.get(defaultEmailTranslations[ctrl.model.messageDefinition], 'emailSubject') || "";
      }
      if (!ctrl.model.specifyEmailBody) {
        ctrl.model.emailBody = _.get(defaultEmailTranslations[ctrl.model.messageDefinition], 'emailBody') || "";
      }
    }

    function updateInfo() {
      ctrl.model.specifyLocale = !!ctrl.model.locale;
      ctrl.model.specifyApplications = _.isArray(ctrl.model.applications) && ctrl.model.applications.length > 0;
      ctrl.model.specifyEmailSubject = _.isString(ctrl.model.emailSubject) && _.trim(ctrl.model.emailSubject).length > 0;
      ctrl.model.specifyEmailBody = _.isString(ctrl.model.emailBody) && _.trim(ctrl.model.emailBody).length > 0;
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInit: function() {
        updateInfo();
        specifyEmails();
      }, afterSave: function() {
        updateInfo();
        specifyEmails();
      }
    });

    ctrl.locales = LOCALES;
    ctrl.applications = LocalUserPermissions.getApps();

    defaultEmailTranslationsResource.get().then(function(dts) {
      defaultEmailTranslations = _.keyBy(dts, 'messageDefinition');
      specifyEmails();
    });

    ctrl.typeChange = function() {
      specifyEmails();
    };

    ctrl.onBeforeSubmit = function() {
      if (!ctrl.model.specifyEmailSubject || ctrl.model.emailSubject === _.get(defaultEmailTranslations[ctrl.model.messageDefinition], 'emailSubject')) {
        ctrl.model.emailSubject = "";
      }
      if (!ctrl.model.specifyEmailBody || ctrl.model.emailBody === _.get(defaultEmailTranslations[ctrl.model.messageDefinition], 'emailBody')) {
        ctrl.model.emailBody = "";
      }
      if (!ctrl.model.specifyLocale) {
        ctrl.model.locale = "";
      }
      if (!ctrl.model.specifyApplications) {
        ctrl.model.applications = [];
      }
    };
  });

export default MODULE_NAME;