/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import auth from '../../../components/auth/auth';

import {PERMISSIONS} from "../../../components/auth/auth-constants";
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = 'role-controller';

angular.module(MODULE_NAME, [standardFormModule, auth])

  .controller('RoleCtrl', function(model, modelComms, LocalNetworkProfile, localUserProfile) {
    const ctrl = this;

    const applications = [];
    const apps = [];
    if (LocalNetworkProfile.isDataPlatform()) {
      applications.push('DATA');
      apps.push('DATA');
    }
    if (LocalNetworkProfile.isAdvertisingPlatform()) {
      applications.push('ADVERTISING');
      apps.push('ADVERTISING');
    }
    if (LocalNetworkProfile.isSuiPlatform()) {
      apps.push('SELF_SERVICE');
    }
    if (LocalNetworkProfile.isMarketplacePlatform()) {
      apps.push('MARKETPLACE');
    }
    ctrl.isSuiPlatform = LocalNetworkProfile.isSuiPlatform();
    ctrl.applications = applications;

    const permissionByType = {
      INTERNAL: _.map(_.filter(PERMISSIONS, function(p) {
        return (!p.roleType || p.roleType === 'INTERNAL') &&
          apps.indexOf(p.app) > -1 &&
          p.application && applications.indexOf(p.application) > -1 &&
          (!p.checkAdvancedLogging || LocalNetworkProfile.get('advancedLogging'));
      }), _.iteratee()),
      EXTERNAL: _.map(_.filter(PERMISSIONS, function(p) {
        return p.roleType === 'EXTERNAL' && apps.indexOf(p.app) > -1;
      }), _.iteratee())
    };

    const setUp = function() {
      ctrl.model.application = ctrl.model.application || localUserProfile.getCurrentApp() || '';
      ctrl.model.roleType = _.find(ctrl.model.permissions, function(p) {
        const perm = _.find(PERMISSIONS, function(foundPerm) {
          return foundPerm.apiKey === p || foundPerm.apiKey === p.apiKey;
        });
        return perm && perm.roleType === 'EXTERNAL';
      }) ? "EXTERNAL" : "INTERNAL";

      ctrl.model.scope = ctrl.model.scope || 'NETWORK';
    };
    adnFormHelper.setUpForm(ctrl, model, {afterInit: setUp, afterSave: setUp, modelComms: modelComms});

    ctrl.model.allPermissions = permissionByType;

    ctrl.onBeforeSubmit = function() {
      _.remove(ctrl.model.permissions, function(p) {
        const foundPerm = _.find(PERMISSIONS, ['apiKey', p]);
        return foundPerm ? foundPerm.scope !== ctrl.model.scope || (!!foundPerm.roleType && foundPerm.roleType !== ctrl.model.roleType) : false;
      });
    };
  });

export default MODULE_NAME;