import angular from 'angular';

import editTemplate from './data-export-edit.html';
import copyTemplate from '../../common/copy-actions.html';
import listTemplate from './data-export-list.html';

import standardModule from '../../common/standard-module';
import dataExportController from './data-export-controller';
import dataExportActionsController from './data-export-actions-controller';
import {PERMISSIONS} from "../../../components/auth/auth-constants";

const MODULE_NAME = 'data-export-module';

angular.module(MODULE_NAME, [standardModule, dataExportController, dataExportActionsController])
  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('data-exports.data-export', 'DataExport', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      data: {
        app: ['DATA', 'ADVERTISING']
      },
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetworkPermission(PERMISSIONS.axAdvertiserAdmin.apiKey) && !LocalUserPermissions.hasNetworkPermission(PERMISSIONS.axPublisherAdmin.apiKey)
            && !LocalUserPermissions.hasNetworkPermission(PERMISSIONS.manageSystem.apiKey)  && !LocalUserPermissions.hasNetworkPermission(PERMISSIONS.downloadLogs.apiKey);
        },
        listConfig: /*@ngInject*/ function(localUserProfile, LocalUserPermissions) {
          if (localUserProfile.getCurrentApp() === 'DATA') {
            return {where: "source in STATS"};
          } else if (!LocalUserPermissions.hasNetworkPermission(PERMISSIONS.manageSystem.apiKey) && !LocalUserPermissions.hasNetworkPermission(PERMISSIONS.downloadLogs.apiKey)) {
            return {where: "source in STATS"};
          } else {
            return {where: "source in RAW_LOG,STATS"};
          }
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        data: {
          app: ['DATA', 'ADVERTISING']
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'DataExportCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'DataExportActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'DataExport'));
  });

export default MODULE_NAME;
