import _ from "lodash";

export const folioListCommons = function(ctrl, afterFill, LocalNetworkProfile, LocalUserPermissions) {
  if (afterFill) {
    return;
  }
  ctrl.hasObjectSource = LocalNetworkProfile.hasObjectSource();
  ctrl.folioFields = ['name', {param: 'externalReference', label: 'extReference'}, {param: 'Advertiser', label: 'advertiser'}, {param: 'Team', label: 'team'}, 'createUser', 'updateTime'];
  if (ctrl.hasObjectSource) {
    ctrl.folioFields.splice(1, 0, 'source');
  }
  if (!LocalUserPermissions.getAllVisPermissions().MANAGE_ADVERTISING) {
    ctrl.folioFields = ['name', {param: 'externalReference', label: 'extReference'}, {param: 'Advertiser', label: 'advertiser'}, 'createUser', 'updateTime'];
  }
  const skipForDefaults = ['source', 'createUser'];
  ctrl.defaultFields = _.filter(ctrl.folioFields, function(f) {
    return skipForDefaults.indexOf(f.label || f.param || f) < 0;
  });
  ctrl.sel = {};
  ctrl.perms = LocalUserPermissions.getAllVisPermissions();

  ctrl.applyLimitedFilter = function() {
    ctrl.limitedFilter = true;
  };
};
