import angular from 'angular';

import editTemplate from './mapping-edit.html';

import standardModule from '../common/standard-module';
import mappingController from './mapping-controller';
import titleTemplate from "../base/default-title-template.html";

const MODULE_NAME = 'mapping-module';

angular.module(MODULE_NAME, [standardModule, mappingController])

  .config(function($stateProvider) {
    $stateProvider.state('app.mappings', {
      url: '/mappings',
      parent: 'app.dat-map',
      resolve: {
        model: function() {
        }
      },
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'MappingCtrl',
          controllerAs: 'resource'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Mappings"
      }
    });
  });

export default MODULE_NAME;
