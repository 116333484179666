/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'creative-user-states',
  template: `
    <div class="btn-group">
      <button type="button" class="btn btn-default btn-xs" (click)="list.doBulkAction('NOT_READY', 'userState')">
        Mark Incomplete
      </button>
      <button type="button" class="btn btn-default btn-xs" (click)="list.doBulkAction('APPROVED', 'userState')">
        Approve
      </button>
      <button type="button" class="btn btn-default btn-xs" (click)="list.doBulkAction('PAUSED', 'userState')">
        Pause
      </button>
    </div>
  `
})
export class CreativeUserStatesComponent {
  @Input() list: any;
}