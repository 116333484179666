/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

export class LocaleHelper {
  static toApiFormat(locale) {
    const localeArray = locale.split('-');
    // maybe check the length and bail if too short
    if (localeArray.length < 2) {
      return locale;
    }
    const first = localeArray.shift(),
      last = localeArray.pop().toUpperCase(),
      rest = _.map(localeArray, _.capitalize);
    rest.unshift(first);
    rest.push(last);
    return rest.join('_');
  }

  static toAngularFormat(locale) {
    return locale.replace(/_/g, '-').toLowerCase();
  }

  static getNavigatorLanguage(win) {
    if (win.navigator.languages && win.navigator.languages.length) {
      return win.navigator.languages[0];
    } else {
      return win.navigator.userLanguage || win.navigator.language || win.navigator.browserLanguage || 'en-GB';
    }
  }
}