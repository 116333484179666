/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './context-service-configuration-edit.html';
import deleteTemplate from '../../common/delete-actions.html';
import listTemplate from './context-service-configuration-list.html';

import standardModule from '../../common/standard-module';
import adminModule from '../admin-module';
import contextServiceConfigurationCtrl from './context-service-configuration-controller';

const MODULE_NAME = 'context-service-configuration-module';

angular.module(MODULE_NAME, [adminModule, contextServiceConfigurationCtrl, standardModule])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('context-service-configurations.context-service-configuration', 'ContextServiceConfiguration', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      data: {
        app: ['ADVERTISING', 'DATA']
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'ContextServiceConfigurationCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          }
        }
      }, 'ContextServiceConfiguration'));
  });

export default MODULE_NAME;