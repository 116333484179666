/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {interpolateWarning} from "../common/directives/error/interpolate-warnings-filter";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'creative-status-label',
  template: `<span class="label {{labelClass}}">{{ "creative.userState." + status | translate }}</span><info-icon *ngIf="warningsOutput && massagedWarnings.length > 0" [text]="this.warningsOutput" placement="bottom"></info-icon>`
})
export class CreativeStatusLabelComponent implements OnInit {
  @Input() warnings: any;
  @Input() status: string;
  massagedWarnings: any;
  warningsOutput: string;
  labelClass: string;

  constructor(@Inject('$interpolate') private $interpolate: any, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = "label-success";

    const skipThese = ['validation.warning.creative.blocked.by.scanner'];
    this.massagedWarnings = _.filter(this.warnings, function (w) {
      return !_.includes(skipThese, w.code);
    });
    const adServerWarnings = _.filter(this.massagedWarnings, function (w) {
      return w.preventsAdServing;
    });

    if (this.status === 'REJECTED') {
      this.labelClass = "label-danger";
    } else if (this.status !== 'APPROVED') {
      this.labelClass = "label-warning";
    } else if (this.status === 'APPROVED' && adServerWarnings.length === 0 && this.massagedWarnings.length > 0) {
      this.labelClass = "label-info";
    } else if (this.status === 'APPROVED' && adServerWarnings.length > 0 && this.massagedWarnings.length > 0) {
      this.labelClass = "label-warning";
    }

    this.warningsOutput = "<ul>";
    _.forEach(this.warnings, (warning) => {
      this.warningsOutput += "<li>" + interpolateWarning(this.$interpolate, warning) + "</li>";
    });
    this.warningsOutput += "</ul>";
  }
}
