/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import { OBJECT_TYPE } from "../../common/constants/object-type";
import {ALL_FIELDS, WIDGET_TYPE} from "../../common/constants/widget-type";
import moment from 'moment';

import template from './line-item-list.html';

import timeShifter from '../../../components/util/time-shifter';
import standardFormModule from '../../common/standard-form-module';
import standardListModule from '../../common/standard-list-module';
import lineItemListSummaryDir from '../../line-item/line-item-list-summary-directive';

const MODULE_NAME = "line-item-list-directive";

angular.module(MODULE_NAME, [standardFormModule, standardListModule, timeShifter, lineItemListSummaryDir])

  .directive('adnLineItemList', function(adnTimeShifter, adnListHelper, StarService, dataViewResource, LocalNetworkProfile) {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        widgetType: '<adnLineItemList'
      },
      template: template,
      controllerAs: 'ctrl',
      controller: function postLink() {
        const ctrl = this;
        let ids;

        ctrl.$onInit = function() {
          const sysDefined = angular.copy(WIDGET_TYPE[ctrl.widgetType.systemDefined.id].systemDefined);

          let widgetParams = sysDefined.widgetParams,
            useStarredIds = sysDefined.starredIds;

          ctrl.list = {};

          ctrl.fields = _.map(ctrl.widgetType.fields, function(f) {
            return ALL_FIELDS[f];
          });
          ctrl.fields = _.compact(ctrl.fields);

          const sortableFields = {
            "name": true,
            "startDate": true,
            "endDate": true,
            "velocity": true,
            "deliveryPercentage": true,
            "elapsedTimePercentage": true
          };

          ctrl.columnHeaderFields = _.map(ctrl.fields, function(f) {
            if (sortableFields[f.id]) {
              return f.id;
            }
            const fieldInformation = {label: f.id, numeric: f.numeric};
            const trans = LocalNetworkProfile.getNetworkTranslations(f.transKey || f.id);
            if (trans) {
              fieldInformation.labelInstant = trans;
            }
            return fieldInformation;
          });

          if (useStarredIds) {
            ids = StarService.getStarred(OBJECT_TYPE.LineItem);
            // bail early if this is the starred line item list and the array is empty
            if (ids.length === 0) {
              ctrl.list.items = [];
              return;
            }
          }
          let dataViewParams = {
            ids: ids,
            currency: LocalNetworkProfile.get('defaultCurrency') || 'EUR'
          };

          if (!angular.isArray(ids)) {
            delete dataViewParams.ids;
          }

          let excludeIfMissing = [];
          _.forEach(sysDefined.excludeDefaults, function(value, key) {
            ctrl.widgetType.excludeValues = ctrl.widgetType.excludeValues || {};
            if (Object.prototype.hasOwnProperty.call(ctrl.widgetType.excludeValues, key)) {
              if (ctrl.widgetType.excludeValues[key]) {
                excludeIfMissing.push(key);
              }
            } else if (value.value) {
              excludeIfMissing.push(key);
            }
          });

          const labels = _.get(ctrl.widgetType, 'labels', []);
          let whereString = labels.length > 0 ? labels[0] + " in lineItem.labels" : "";

          const startDate = ctrl.widgetType.startDate ? moment(ctrl.widgetType.startDate).format("YYYY-MM-DD") : null;
          const endDate = ctrl.widgetType.endDate ? moment(ctrl.widgetType.endDate).format("YYYY-MM-DD") : null;
          if (sysDefined.hasStartDate && moment(startDate).isValid() && moment(endDate).isValid()) {
            whereString += whereString.length > 0 ? ";" : "";
            whereString += "lineItem.startDate>" + startDate + ";lineItem.endDate<" + endDate;
          }

          adnListHelper.setUpBasicList(ctrl.list, dataViewResource, ctrl.widgetType.id, {
            listMethod: 'getView',
            moreParams: dataViewParams,
            viewModel: 'LineItemListView',
            where: whereString,
            filterBy: _.get(widgetParams, 'filterBy') || '',
            filterByValue: _.get(widgetParams, 'filterByValue') || '',
            excludeIfMissing: excludeIfMissing,
            pageSize: Math.min((_.isFinite(ctrl.widgetType.rows) && ctrl.widgetType.rows > 0 ? ctrl.widgetType.rows : 10), 100),
            afterFill: function() {
              _.forEach(ctrl.list.items, function(row) {
                // make sure velocities are in range
                row.velocity = _.isFinite(row.velocity) ? row.velocity : null;

                if (row.startDate) {
                  row.modstartDate = adnTimeShifter.timeShift(row.startDate);
                }
                if (row.endDate) {
                  row.modendDate = adnTimeShifter.timeShift(row.endDate);
                }
              });
            }
          });
        };
      }
    };
  });

export default MODULE_NAME;