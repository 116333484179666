/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './layout-edit.html';
import layoutItemActionsTemplate from './layout-item-actions.html';
import listTemplate from './layout-list.html';

import adminModule from '../admin-module';
import layoutController from './layout-controller';
import layoutActionsController from './layout-actions-controller';
import { PERMISSIONS } from "../../../components/auth/auth-constants";
import _ from "lodash";

const MODULE_NAME = 'layout-module';

angular.module(MODULE_NAME, [adminModule, layoutController, layoutActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('layouts.layout', 'Layout', 'Creative', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageLayouts.apiKey) && !LocalUserPermissions.hasNetwork(PERMISSIONS.axPublisherAdmin.apiKey);
        },
        extension: /*@ngInject*/ function(LocalNetworkProfile, localUserProfile) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                return;
              }
              ctrl.layoutFields = ['name', {param: 'layoutType', label: 'type'}, {param: '', label: 'components'}, 'layoutCategory'];

              const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
              if (LocalNetworkProfile.isPureMarketplacePlatform() && isSpecialUser) {
                ctrl.layoutFields.push({param: '', label: 'approvalStatus'});
              }

              ctrl.selFields = _.map(ctrl.teamFields, function(field) {
                return _.isString(field) ? field : field.label;
              });
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'LayoutCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: layoutItemActionsTemplate,
            controller: 'LayoutActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Layout'));
  });

export default MODULE_NAME;