/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import 'moment-timezone';

const MODULE_NAME = 'perms-controller';

angular.module(MODULE_NAME, [])

  .controller('PermsCtrl', function() {
    const ctrl = this;

    ctrl.isNew = true;

    ctrl.excludeValue = {value: 'bad'};
    ctrl.includeValue = {value: 'good'};

    ctrl.wsj = 'Safe For Work';
    ctrl.fox = 'Premium';
    ctrl.australian = 'Premium';
    ctrl.nyp = 'Safe For Work';
  });

export default MODULE_NAME;