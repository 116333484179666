/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = 'notification-preferences-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('NotificationPreferencesCtrl', function(model, localUserProfile) {
    const ctrl = this;

    ctrl.notificationLevels = ['INFO', 'WARNING', 'IMPORTANT', 'ERROR'];
    ctrl.notificationObjects = ['LINE_ITEM', 'CREATIVE', 'REPORT_EXECUTION', 'DATA_EXPORT'];
    ctrl.notificationMethods = ['EMAIL', 'UI'];
    ctrl.notificationMessages = [
      {id: 'LINE_ITEM_READY', sev: 'INFO'},
      {id: 'LINE_ITEM_RUNNING', sev: 'IMPORTANT'},
      {id: 'LINE_ITEM_STOPPED_RUNNING', sev: 'IMPORTANT'},
      {id: 'LINE_ITEM_ENDED_END_DATE_PASSED', sev: 'IMPORTANT'},
      {id: 'LINE_ITEM_ENDED_OBJECTIVE_REACHED', sev: 'IMPORTANT'},
      {id: 'LINE_ITEM_NOT_STARTED', sev: 'WARNING'},
      {id: 'LINE_ITEM_DELETED', sev: 'INFO'},
      {id: 'LINE_ITEM_SUBMITTED', sev: 'IMPORTANT'},
      {id: 'LINE_ITEM_REJECTED', sev: 'IMPORTANT'},
      {id: 'CREATIVE_REJECTED', sev: 'IMPORTANT'},
      {id: 'REPORT_COMPLETE', sev: 'INFO'},
      {id: 'DATA_EXPORT_HAS_FAILED', sev: 'ERROR'},
    ];

    let resetModel = function() {
      _.forEach(ctrl.notificationObjects, function(o) {
        ctrl.model.subscriptions = ctrl.model.subscriptions || {};
        ctrl.model.subscriptions[o] = ctrl.model.subscriptions[o] || {};
        ctrl.model.subscriptions[o].subscribed = !!(_.get(ctrl.model.subscriptions[o], 'deliveryMethods') || []).length;
      });
      ctrl.model.user = {id: localUserProfile.get('userId')};
    };

    adnFormHelper.setUpForm(ctrl, model, {
      afterInit: function() {
        resetModel();
      },
      afterSave: function() {
        resetModel();
      }
    });

    ctrl.onBeforeSubmit = function() {
      _.forEach(ctrl.notificationObjects, function(o) {
        if (!_.get(ctrl.model.subscriptions[o], 'subscribed')) {
          delete ctrl.model.subscriptions[o];
        }
      });
    };

    ctrl.checkMethod = function(index, obj) {
      if (index > 0) {
        return false;
      }
      return _.get(ctrl.model.subscriptions[obj], 'subscribed') === true && !(_.get(ctrl.model.subscriptions[obj], 'deliveryMethods') || []).length;
    };

  });

export default MODULE_NAME;