export const httpServiceProvider = {
  provide: '$http',
  useFactory: ($injector: any) => $injector.get('$http'),
  deps: ['$injector']
};

export const qServiceProvider = {
  provide: '$q',
  useFactory: ($injector: any) => $injector.get('$q'),
  deps: ['$injector']
};

export const logServiceProvider = {
  provide: '$log',
  useFactory: ($injector: any) => $injector.get('$log'),
  deps: ['$injector']
};

export const interpolateServiceProvider = {
  provide: '$interpolate',
  useFactory: ($injector: any) => $injector.get('$interpolate'),
  deps: ['$injector']
};

export const translateServiceProvider = {
  provide: '$translate',
  useFactory: ($injector: any) => $injector.get('$translate'),
  deps: ['$injector']
};

export const chartOptionsUtilProvider = {
  provide: 'ChartOptionsUtil',
  useFactory: ($injector: any) => $injector.get('ChartOptionsUtil'),
  deps: ['$injector']
};

export const localUserProfileProvider = {
  provide: 'localUserProfile',
  useFactory: ($injector: any) => $injector.get('localUserProfile'),
  deps: ['$injector']
};

export const localUserPermissionsProvider = {
  provide: 'LocalUserPermissions',
  useFactory: ($injector: any) => $injector.get('LocalUserPermissions'),
  deps: ['$injector']
};

export const localNetworkProfileProvider = {
  provide: 'LocalNetworkProfile',
  useFactory: ($injector: any) => $injector.get('LocalNetworkProfile'),
  deps: ['$injector']
};

export const localeProvider = {
  provide: '$locale',
  useFactory: ($injector: any) => $injector.get('$locale'),
  deps: ['$injector']
};

export const resourceProvider = {
  provide: 'adnResource',
  useFactory: ($injector: any) => $injector.get('adnResource'),
  deps: ['$injector']
};