/**
 * Copyright © 2024 Adnuntius AS.
 */
export const ADN_GRIDSTER_CONFIG = {
    WORKSPACES: {
      minRows: 1,
      resizable: {
        start: '', resize: '', stop: ''
      },
      rowHeight: 210,
      draggable: {
        handle: '.dragDropHandle',
        start: '', drag: '', stop: ''
      },
      mobileModeEnabled: false,
      margins: [10, 10]
    },
    RESOURCES: {
      mobileModeEnabled: false,
      columns: 1,
      defaultSizeX: 1,
      defaultSizeY: 0,
      rowHeight: 165,
      minRows: 2,
      resizable: {enabled: false}
    }
};
