/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {interpolateWarning} from "../common/directives/error/interpolate-warnings-filter";

@Component({
  selector: 'validation-info',
  template: `<info-icon *ngIf="warningsOutput && warnings.length > 0" [text]="warningsOutput" [placement]="placement"></info-icon>`
})
export class ValidationInfoComponent implements OnInit {
  @Input() warnings: any;
  @Input() placement = "bottom";
  warningsOutput: string;

  constructor(@Inject('$interpolate') private $interpolate: any) {
  }

  ngOnInit(): void {
    this.warningsOutput = "<ul>";
    _.forEach(this.warnings, (warning) => {
      this.warningsOutput += "<li>" + interpolateWarning(this.$interpolate, warning) + "</li>";
    });
    this.warningsOutput += "</ul>";
  }
}
