/**
 * Copyright © 2024 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

export class ReportTranslationDefaultsResource {
  private readonly uri: string = ApiConfig.obtain().getVersionedUri('reporttranslationdefaults');

  reportTranslationDefaults: any;

  constructor(@Inject('$http') private $http: any, @Inject('$q') private $q: any) {
  }

  get() {
    return this.reportTranslationDefaults ? this.$q.resolve(this.reportTranslationDefaults) : this.$http.get(this.uri, {
      params: {context: null}
    }).then((info) => {
      this.reportTranslationDefaults = info.data;
      return this.reportTranslationDefaults;
    });
  }
}
