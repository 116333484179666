/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './blocklist-edit.html';
import listTemplate from './blocklist-list.html';

import standardModule from '../common/standard-module';
import siteController from './blocklist-controller';

const MODULE_NAME = 'blocklist-module';

angular.module(MODULE_NAME, [standardModule, siteController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('blocklists.blocklist', 'Blocklist', null, 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'BlocklistCtrl',
            controllerAs: 'resource'
          }
        }

      }, 'Blocklist', []));
  });

export default MODULE_NAME;