/**
 * Copyright © 2024 Adnuntius AS.
 */
import { ApiConfig } from "../../api";
import { Inject } from "@angular/core";

export class CurrenciesResource {
  private readonly uri: string = ApiConfig.obtain().getVersionedUri('availablecurrencies');

  constructor(@Inject('$http') private $http: any) {
  }

  get() {
    return this.$http.get(this.uri).then((info) => {
      return info.data.results;
    });
  }
}