/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import {DataServerConfig} from "../../api";
import {Inject} from "@angular/core";

type ApiCallbackFunc = (obj) => void;

export class VisitorProfileResource {
  private readonly secVisitorUri = DataServerConfig.obtain().getUri('synchronous/visitor');

  constructor(@Inject('$http') private $http: any) {
  }

  getVisitor(folderId: string, browserId: string, cb: ApiCallbackFunc, errCb: ApiCallbackFunc, status) {
    status.isPending = true;
    return this.$http.get(this.secVisitorUri, {params: {folderId: folderId, browserId: browserId}}).then(function(response) {
      status.isPending = false;
      return (cb || _.noop)(response.data);
    }).catch(function(response) {
      status.isPending = false;
      status.hasError = true;
      return (errCb || _.noop)(response);
    });
  }
}
