import {Component, ElementRef, HostListener, Input} from "@angular/core";
import {AssetTranscode, TranscodeItem} from "./asset-transcode";

@Component({
  selector: 'asset-transcode',
  template: `
    <div *ngIf="this.assetTranscode.size > 0" class="caption text-center">
      <div class="dropdown" [ngClass]="{open: popupOpened}">
        <a (click)="togglePopup()">
          <span *ngIf="transcodeProgress">Transcoding... <span class="fa fa-cog fa-spin"></span> <span class="fa fa-fw fa-info-circle"></span></span>
          <span *ngIf="transcodeSuccess">Formats <span class="fa fa-fw fa-info-circle"></span></span>
          <span *ngIf="transcodeErrors" class="text-danger">Formats <span class="fa fa-fw fa-info-circle"></span></span>
        </a>
        <div *ngIf="popupOpened" class="dropdown-menu dropdownWithArrow" style="min-width: 300px" aria-labelledby="transcodePopup">
          <div class="row" *ngFor="let transcodeItem of assetTranscode | keyvalue">
            <label class="col-md-4 control-label">{{ transcodeItem.key }}</label>
            <div class="col-md-8">
              <div class="form-control-static">
                <span *ngIf="transcodeItem.value.status === 'TRANSCODING'" class="fa fa-cog fa-spin"></span>
                <span *ngIf="transcodeItem.value.status === 'QUEUED'" class="fa fa-cog fa-spin"></span>
                <span *ngIf="transcodeItem.value.width && transcodeItem.value.height">{{ transcodeItem.value.width }}x{{ transcodeItem.value.height }}, </span>
                <span *ngIf="transcodeItem.value.bps && transcodeItem.value.bps < 1000000">{{ transcodeItem.value.bps / 1000 }} kbps, </span>
                <span *ngIf="transcodeItem.value.bps && transcodeItem.value.bps >= 1000000">{{ transcodeItem.value.bps / 1000000 }} Mbps, </span>
                <span>{{ transcodeItem.value.mimeType | mimeTypeKey | translate }}</span>
                <div *ngIf="transcodeItem.value.errorMessage">
                  <div class="label label-danger">Error Transcoding</div>
                </div>
                &nbsp;<a *ngIf="transcodeItem.value.cdnId" [href]="transcodeItem.value.cdnId" target="_blank"><span class="fa fa-external-link"></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class AssetTranscodeComponent {
  @Input() assetTranscodeObject!: any;
  assetTranscode!: AssetTranscode;
  popupOpened: boolean;
  transcodeProgress: boolean;
  transcodeSuccess: boolean;
  transcodeErrors: boolean;

  constructor(private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hidePopup();
    }
  }

  ngOnInit(): void {
    this.assetTranscode = new Map<string, TranscodeItem>(Object.entries(this.assetTranscodeObject));
    this.transcodeProgress = Array.of(...this.assetTranscode.values()).some(item => item.status === 'QUEUED' || item.status === 'TRANSCODING');
    this.transcodeSuccess = Array.of(...this.assetTranscode.values()).some(item => item.status === 'SUCCESS');
    this.transcodeErrors = Array.of(...this.assetTranscode.values()).some(item => item.status === 'ERROR');
  }

  togglePopup() {
    this.popupOpened = !this.popupOpened;
  }

  hidePopup() {
    this.popupOpened = false;
  }
}