/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiCodemirror from 'angular-ui-codemirror';
import textAngular from 'textangularjs';

import standardFormModule from '../../common/standard-form-module';
import listHelper from '../../common/controller/list-helper';
import {adnFormHelper} from "../../common/controller/form-helper";
import vastLoadTemplate from './examples/vast-load.html';
import parallaxLoadTemplate from './examples/parallax-load.html';

const MODULE_NAME = 'layout-example-controller';

angular.module(MODULE_NAME, [
  uiCodemirror,
  textAngular,
  standardFormModule,
  listHelper
])

  .controller('LayoutExampleCtrl', function($window, $document, $element, $timeout, model) {
    const ctrl = this;

    adnFormHelper.setUpForm(ctrl, model);

    $timeout(function() {
      if (model.vastUrl) {
        setupIframe(vastLoadTemplate.replace("{VAST_URL}", model.vastUrl));

        return;
      } else if (model.id === 'parallax-layout-example') {
        setupIframe(parallaxLoadTemplate);

        return;
      }

      $window.adn = $window.adn || {};
      $window.adn.calls = $window.adn.calls || [];
      $window.adn.calls.push(function() {
        $window.adn.request({
          env: 'production',
          adUnits: [{
            targetId: 'ad-container',
            auId: '000000000007eb8e',
            auW: '500',
            auH: '600',
            kv: {'examples': [model.kv]}
          }]
        });
      });
    }, 20);

    function setupIframe(template) {
      const ifr = $document[0].createElement('iframe');
      ifr.allowTransparency = true;
      ifr.setAttribute('id', "iframe-window");
      ifr.setAttribute('width', "440");
      ifr.setAttribute('height', "440");

      const divContainer = $element[0].querySelector('#iframeContainer');
      ifr.frameBorder = "0";

      divContainer.append(ifr);

      const iframeDoc = ifr.contentDocument || ifr.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(template);
      iframeDoc.close();

      const iframeElement = angular.element(iframeDoc);
      const bodyElement = iframeElement.find("body");
      bodyElement.attr('style', 'margin: 0; padding: 0;');
    }
  });

export default MODULE_NAME;