/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './tier-edit.html';
import listTemplate from './tier-list.html';
import titleTemplate from '../../base/default-title-template.html';

import adminModule from '../admin-module';
import tierController from './tier-controller';
import tierListController from './tier-list-controller';
import deleteTemplate from "../../common/delete-actions.html";

const MODULE_NAME = 'tier-module';

angular.module(MODULE_NAME, [adminModule, tierController, tierListController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('tiers.tier', 'tierResource', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), _.merge(rh.list(), {
      views: {
        'content@app': {
          controller: 'TierListCtrl',
          controllerAs: 'list',
          template: listTemplate
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          tierEnabled: /*@ngInject*/ function($stateParams, Network, LocalNetworkProfile) {
            return Network.get({id: LocalNetworkProfile.getNetworkId()}).$promise.then(function(network) {
              return _.some(network.tiers, ['id', $stateParams.id]);
            });
          }
        },
        views: {
          'content@app': {
            controller: 'TierCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams, tierEnabled) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
              ctrl.blockDelete = tierEnabled;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          },
          'title@app': {
            template: titleTemplate
          }
        }
      }, 'Tier'));
  });

export default MODULE_NAME;