/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './email-translation-edit.html';
import deleteTemplate from '../../common/delete-actions.html';
import listTemplate from './email-translation-list.html';

import standardModule from '../../common/standard-module';
import adminModule from '../admin-module';
import emailTranslationCtrl from './email-translation-controller';

const MODULE_NAME = 'email-translation-module';

angular.module(MODULE_NAME, [adminModule, emailTranslationCtrl, standardModule])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('email-translations.email-translation', 'EmailTranslation', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'EmailTranslationCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          }
        }
      }, 'EmailTranslation'));
  });

export default MODULE_NAME;