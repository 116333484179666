import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "angular.grad.resize";

angular.module(MODULE_NAME, [])
  .directive('draggable', function($document, $timeout) {
    return {
      restrict: 'A',
      scope: {
        resizeHook: '<',
        mode: '<',
        aspectRatio: '<'
      },
      link: function(scope, element) {
        const refPosition = {};
        const mode = scope.mode || 'FREEFORM';
        let aspectRatio = scope.aspectRatio || null;

        function refreshParent(element) {
          const referenceEl = $document[0].getElementById("video-target") || element.parent()[0];
          refPosition.x = referenceEl.getBoundingClientRect().left;
          refPosition.y = referenceEl.getBoundingClientRect().top;
          refPosition.rect = referenceEl.getBoundingClientRect();
        }

        function centerY() {
          refreshParent();
          const clientRect = element[0].getBoundingClientRect();
          const shiftFromCenter = clientRect.height / 2;
          const topPos = refPosition.rect.height / 2 - shiftFromCenter;

          element.css({
            top: topPos + 'px'
          });

          scope.resizeHook.onChange({
            width: clientRect.width,
            height: clientRect.height,
            x: parseInt(element.css('left'), 10),
            y: topPos
          });
        }

        function centerX() {
          refreshParent();
          const clientRect = element[0].getBoundingClientRect();
          const shiftFromCenter = clientRect.width / 2;
          const leftPos = refPosition.rect.width / 2 - shiftFromCenter;

          element.css({
            left: leftPos + 'px'
          });

          scope.resizeHook.onChange({
            width: clientRect.width,
            height: clientRect.height,
            x: leftPos,
            y: parseInt(element.css('top'), 10)
          });
        }

        const position = {};
        element.bind('mousedown', function($event) {
          refreshParent();
          position.x = element[0].getBoundingClientRect().left - refPosition.x;
          position.y = element[0].getBoundingClientRect().top - refPosition.y;
          position.initialMouseX = $event.clientX - refPosition.x;
          position.initialMouseY = $event.clientY - refPosition.y;
          $document.bind('mousemove', mousemove);
          $document.bind('mouseup', mouseup);
          return false;
        });

        if (scope.resizeHook) {
          scope.resizeHook.resizeAspect = function(aspect) {
            refreshParent();
            aspectRatio = aspect;
            let newWidth = Math.min(aspect.exampleWidth, refPosition.rect.width);
            let newHeight = Math.min(aspect.exampleHeight, refPosition.rect.height);
            if (newWidth < aspect.exampleWidth) {
              newHeight = Math.round(newWidth * aspectRatio.height / aspectRatio.width);
            } else if (newHeight < aspect.exampleHeight) {
              newWidth = Math.round(newHeight * aspectRatio.width / aspectRatio.height);
            }
            element.css({
              top: '0',
              left: '0',
              width: newWidth + 'px',
              height: newHeight + 'px'
            });

            centerX();
            centerY();
          };

          scope.resizeHook.resize = function(pos) {
            element.css({
              top: pos.y + 'px',
              left: pos.x + 'px',
              width: pos.width + 'px',
              height: pos.height + 'px'
            });
          };

          scope.resizeHook.centerY = function() {
            centerY();
          };

          scope.resizeHook.centerX = function() {
            centerX();
          };

          if (scope.resizeHook.init) {
            scope.resizeHook.resize(scope.resizeHook.init);
          }
        }

        function mousemove($event) {
          refreshParent();
          const clientRect = element[0].getBoundingClientRect();
          const maxTop = refPosition.rect.height - clientRect.height;
          const maxLeft = refPosition.rect.width - clientRect.width;

          const dx = $event.clientX - refPosition.x - position.initialMouseX;
          const dy = $event.clientY - refPosition.y - position.initialMouseY;

          const cssTop = Math.max(0, Math.min(maxTop, position.y + dy));
          const cssLeft = Math.max(0, Math.min(maxLeft, position.x + dx));

          element.css({
            top: cssTop + 'px',
            left: cssLeft + 'px'
          });

          if (_.isFunction(scope.resizeHook.onChange)) {
            scope.resizeHook.onChange({
              width: clientRect.width,
              height: clientRect.height,
              x: cssLeft,
              y: cssTop
            });
          }

          return false;
        }

        function mouseup() {
          $document.unbind('mousemove', mousemove);
          $document.unbind('mouseup', mouseup);
        }

        // EXPANDABLE SECTION

        const dimension = {};
        const expandablePosition = {};

        const resizer = $document[0].createElement("span");
        resizer.style.height = "10px";
        resizer.style.width = "100%";
        resizer.style.bottom = 0;
        resizer.style.left = 0;
        resizer.style.position = 'absolute';
        resizer.style.cursor = "nwse-resize";

        //create button for resizing
        const btnBottomRight = $document[0].createElement("span");
        btnBottomRight.style.width = '15px';
        btnBottomRight.style.height = '15px';
        btnBottomRight.innerHTML = "<svg>\
            <circle cx='12.5' cy='2.5' r='2' fill='#777777'></circle>\
            <circle cx='7.5' cy='7.5' r='2' fill='#777777'></circle>\
            <circle cx='12.5' cy='7.5' r='2' fill='#424242'></circle>\
            <circle cx='2.5' cy='12.5' r='2' fill='#777777'></circle>\
            <circle cx='7.5' cy='12.5' r='2' fill='#424242'></circle>\
            <circle cx='12.5' cy='12.5' r='2' fill='#212121'></circle></svg>"
        ;
        btnBottomRight.style.bottom = 0;
        btnBottomRight.style.right = 0;
        btnBottomRight.style.position = 'absolute';
        btnBottomRight.style.visibility = 'hidden';
        btnBottomRight.style.cursor = 'nwse-resize';
        //bind resize function to button;
        btnBottomRight.onmousedown = function($event) {
          $event.stopImmediatePropagation();
          expandablePosition.x = $event.clientX;
          expandablePosition.y = $event.clientY;
          dimension.width = element.prop('offsetWidth');
          dimension.height = element.prop('offsetHeight');
          $document.bind('mousemove', mousemoveExpandable);
          $document.bind('mouseup', mouseupExpandable);
          return false;
        };

        let currentDirection = null;
        function mousemoveExpandable($event) {
          refreshParent();

          const newDimensions = {};
          const newDimensionPixels = {};
          const clientRect = element[0].getBoundingClientRect();
          const maxDeltaHeight = refPosition.rect.height - clientRect.y + refPosition.y;
          const maxDeltaWidth = refPosition.rect.width - clientRect.x + refPosition.x;

          const diffHeight = expandablePosition.y - $event.clientY;
          const diffWidth = expandablePosition.x - $event.clientX;

          const absDiffHeight = Math.abs(diffHeight);
          const absDiffWidth = Math.abs(diffWidth);

          let valueHeight = diffHeight;
          let valueWidth = diffWidth;
          if (mode === 'MAINTAIN_RATIO') {
            if (currentDirection === "HEIGHT" || absDiffHeight > absDiffWidth) {
              currentDirection = "HEIGHT";
              valueHeight = diffHeight;
              valueWidth = diffHeight;
            } else {
              currentDirection = "WIDTH";
              valueHeight = diffWidth;
              valueWidth = diffWidth;
            }
          }

          const deltaHeight = dimension.height - valueHeight;
          const deltaWidth = dimension.width - valueWidth;

          newDimensions.newWidth = Math.max(20, Math.min(maxDeltaWidth, deltaWidth));
          newDimensions.newHeight = Math.max(20, Math.min(maxDeltaHeight, deltaHeight));

          if (mode === 'MAINTAIN_RATIO') {
            if (currentDirection === 'HEIGHT') {
              newDimensions.newWidth = Math.round(newDimensions.newHeight * aspectRatio.width / aspectRatio.height);
            } else {
              newDimensions.newHeight = Math.round(newDimensions.newWidth * aspectRatio.height / aspectRatio.width);
            }
          }

          newDimensionPixels.width = newDimensions.newWidth + 'px';
          newDimensionPixels.height = newDimensions.newHeight + 'px';
          element.css(newDimensionPixels);

          if (_.isFunction(scope.resizeHook.onChange)) {
            scope.resizeHook.onChange({
              width: newDimensions.newWidth,
              height: newDimensions.newHeight,
              x: parseInt(element.css('left'), 10),
              y: parseInt(element.css('top'), 10)
            });
          }

          return false;
        }

        function mouseupExpandable() {
          $document.unbind('mousemove', mousemoveExpandable);
          $document.unbind('mouseup', mouseupExpandable);
          currentDirection = null;
        }

        element.append(resizer);
        element.append(btnBottomRight);
        //show button on hover
        element.bind('mouseover', function() {
          btnBottomRight.style.visibility = 'visible';
        });
        element.bind('mouseout', function() {
          btnBottomRight.style.visibility = 'hidden';
        });

        $timeout(function() {
          if (_.isFunction(_.get(scope.resizeHook, ['hasLoaded']))) {
            scope.resizeHook.hasLoaded();
          }
        }, 300);
      }
    };
  });

export default MODULE_NAME;
