/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiTranslate from 'angular-translate';

import {MediaTypeHelper} from "../../../components/util/media-type-helper";

const MODULE_NAME = "media-type-filters";

angular.module(MODULE_NAME, [uiTranslate])

  .filter('mediaTypesTranslate', function($translate) {
    return function(mimeTypes) {
      mimeTypes = _.isArray(mimeTypes) ? mimeTypes : [mimeTypes];
      let mediaTypes = _.map(mimeTypes, function(mimeType) {
        return MediaTypeHelper.getMediaType(mimeType);
      });
      let translations = _.map(_.uniq(_.compact(mediaTypes)), function(mediaType) {
        return $translate.instant('media.' + mediaType.id);
      });
      return translations.join(', ');
    };
  })

  .filter('mediaTypeKey', function() {
    return function(mimeType) {
      let mediaType = MediaTypeHelper.getMediaType(mimeType);
      return mediaType ? 'media.' + mediaType.id : '';
    };
  });

export default MODULE_NAME;