/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";

import {OBJECT_TYPE} from "../common/constants/object-type";
import {Uuid} from "../../components/util/uuid";

import template from './creative-copy-modal.html';
import linkTemplate from './link-creative-copy-modal.html';
import standardActionsModule from '../common/standard-actions-module';
import searchSelect from '../common/controller/search-select-component';

const MODULE_NAME = "creative-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule,
  searchSelect
])
  .controller('CreativeActionsCtrl', function(toastr, $log, adnModalLoading, LocalNetworkProfile, $stateParams, $state, $uibModal, model, modelComms, Creative, readOnly) {
    const ctrl = this;

    ctrl.objectType = OBJECT_TYPE.LineItem.type;
    ctrl.objectId = model.id;
    ctrl.readOnly = readOnly;
    ctrl.isNew = $stateParams.isNew;

    ctrl.model = model;
    ctrl.modelComms = modelComms;

    modelComms.addSubscriber(function(data, type) {
      if (_.get(type, ['name']) === 'Advertiser') {
        ctrl.specifiedAdvertiser = data;
      }
      if (!type) {
        ctrl.model.libraryCreative = data.libraryCreative;
      }
    });

    ctrl.linkLibrary = function() {
      let modalInstance = $uibModal.open({
        windowClass: '',
        template: linkTemplate,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.creative = data;
            }
            if (_.get(type, 'type') === OBJECT_TYPE.LineItem.type) {
              modalCtrl.creative.lineItem = data;
            }
          });

          modalCtrl.destination = true;

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.linkCreative = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();

            modalCtrl.creative.libraryCreative = modalCtrl.libraryCreative;

            modalCtrl.creative.savePartial(['name', 'libraryCreative']).then(function(creative) {
              modalCtrl.disabled = false;
              modalInstance.close();
              adnModalLoading.removeModalLoading();
              if (creative.id) {
                $state.go('app.creatives.creative', {id: creative.id, isNew: false}, {reload: true});
              }
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
    };

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        windowClass: '',
        template: template,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          const firstUiId = Uuid.generate();
          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.creative = data;
              modalCtrl.creative.name = data.name + " (copy)";
            }
            modalCtrl.creative.lineItems = modalCtrl.creative.lineItems || {};
            if (_.isEmpty(modalCtrl.creative.lineItems)) {
              modalCtrl.creative.lineItems[firstUiId] = {uiId: firstUiId};
            }
            if (_.get(type, 'type') === OBJECT_TYPE.LineItem.type) {
              if (_.isEmpty(modalCtrl.creative.lineItems) || !modalCtrl.creative.lineItems[firstUiId].id) {
                modalCtrl.creative.lineItems[firstUiId] = _.assign({uiId: firstUiId}, data);
              }
            }
          });
          if (LocalNetworkProfile.isPureMarketplacePlatform() && _.get(ctrl, ['specifiedAdvertiser', 'id'])) {
            modalCtrl.specifiedAdvertiser = ctrl.specifiedAdvertiser;
          }

          modalCtrl.addLineItem = function() {
            const uiId = Uuid.generate();
            modalCtrl.creative.lineItems[uiId] = {uiId: uiId};
          };

          modalCtrl.removeLineItem = function(index) {
            delete modalCtrl.creative.lineItems[index];
            if (_.isEmpty(modalCtrl.creative.lineItems)) {
              modalCtrl.addLineItem();
            }
          };

          modalCtrl.destination = true;

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.copyCreative = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();

            const lineItemDests = _.uniqBy(_.filter(modalCtrl.creative.lineItems, function(li) {
              return li.id;
            }), 'id');

            if (lineItemDests.length < 1) {
              lineItemDests.push({});
            }

            _.forEach(lineItemDests, function(li, index) {
              let newId = Uuid.generate();
              if (index === 0) {
                Creative.copyCreative(modalCtrl.creative, li, newId, modalCtrl.creative.userState).then(function() {
                  modalCtrl.disabled = false;
                  modalInstance.close({destination: modalCtrl.destination, creativeId: newId});
                  adnModalLoading.removeModalLoading();
                }, function(errors) {
                  modalCtrl.disabled = false;
                  modalCtrl.validationErrors = errors.data.errors;
                  adnModalLoading.removeModalLoading();
                });
              } else {
                Creative.copyCreative(modalCtrl.creative, li, newId, modalCtrl.creative.userState);
              }
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        adnModalLoading.removeModalLoading();
        if (result.destination && result.creativeId) {
          $state.go('app.creatives.creative', {id: result.creativeId});
        }
        if (result.errors) {
          $log.warn(result.errors);
          toastr.warning("This creative could not be copied.", "Creative Copy Failed");
        } else if (!result.destination) {
          toastr.success("A copy of this creative was successfully created.", "Creative Copied");
        }
      });
    };
  });

export default MODULE_NAME;