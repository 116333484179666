/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import _ from 'lodash';

import localNetworkProfile from '../../components/session/local-network-profile';
import resources from '../../components/api/resources/resources';
import networkHelper from '../../components/util/network-helper';

import templateApi404 from './api404.html';
import template404 from './404.html';

const MODULE_NAME = 'error-module';

angular.module(MODULE_NAME, [uiRouter, localNetworkProfile, resources, networkHelper])

  .config(function($stateProvider) {
    $stateProvider.state('app.errors', {
      abstract: true
    }).state('app.errors.404', {
      url: '/404',
      views: {
        'content@app': {
          template: template404
        }
      },
      data: {
        title: "Sorry, your page wasn't found"
      }
    }).state('app.errors.wrong-app', {
      params: {appsToGo: []},
      views: {
        'content@app': {
          template: template404,
          controller: /*@ngInject*/ function($state, $stateParams, localUserProfile) {
            const ctrl = this;
            ctrl.wrongApp = _.isArray($stateParams.appsToGo) ? $stateParams.appsToGo.length > 0 : false;

            ctrl.appsToGo = $stateParams.appsToGo;

            ctrl.switchApp = function(newApp) {
              localUserProfile.set("app", newApp);
              $state.go($state.previousState.name, $state.previousParams, {
                reload: true
              });
            };
          },
          controllerAs: 'ctrl'
        }
      },
      data: {
        title: "Sorry, your page wasn't found"
      }
    }).state('app.errors.api404', {
      params: {rejection: null},
      views: {
        'content@app': {
          template: templateApi404,
          controller: 'Api404Ctrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        title: "Data not found"
      }
    });
  })

  .controller('Api404Ctrl', function($rootScope, $state, $stateParams, $location, LocalNetworkProfile, localUserProfile, adnNetworkHelper, Network) {
    const ctrl = this;

    let getObjectId = function(url) {
      let splitArray = url.split("/");
      return splitArray[splitArray.length - 1];
    };

    let rejection = $stateParams.rejection;
    ctrl.url = rejection.config.url;

    if (!rejection.data) {
      ctrl.errorType = "default";
      return;
    }

    if (rejection.data.code === 'error.object.not.found') {
      ctrl.errorType = "objectNotFound";
      ctrl.objectId = getObjectId(ctrl.url);
      ctrl.username = $location.search().username;

      const networkTagIds = _.filter(localUserProfile.get("networkTagIds"), function(networkTagId) {
        return networkTagId !== _.get(rejection, ['config', 'params', 'context']);
      });
      ctrl.hasOtherNetworks = networkTagIds.length > 0;
      ctrl.allChecked = networkTagIds.length < 2 || false;

      const currentNetworkId = LocalNetworkProfile.getNetworkId();
      Network.query(_.isString(ctrl.username) ? {username: ctrl.username} : {object: ctrl.objectId}, function(data) {
        ctrl.networks = _.filter(data.results, function(n) {
          return n.id !== currentNetworkId;
        });
        ctrl.allChecked = true;
      }, function() {
        ctrl.networks = null;
        ctrl.allChecked = true;
      });

      ctrl.switchNetwork = function(network) {
        adnNetworkHelper.switchNetwork(ctrl, $rootScope, network);
        if (ctrl.username) {
          $state.go('app.users');
        }
      };

    } else {
      ctrl.errorType = "default";
    }
  });

export default MODULE_NAME;