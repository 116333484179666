/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {LOCALES} from "../common/constants/locales";
import ngSanitize from 'angular-sanitize';

const MODULE_NAME = 'invoice-translation-controller';

angular.module(MODULE_NAME, [standardFormModule, ngSanitize])

  .controller('InvoiceTranslationCtrl', function(model, modelComms, $stateParams, $document, $element, $sce, defaultInvoiceTranslationsResource, LocalNetworkProfile, InvoiceTranslationPreview, LocalUserPermissions) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    const paymentProvider = LocalNetworkProfile.getPaymentProvider().replace("_SANDBOX", "");
    ctrl.notValid = paymentProvider !== 'PAYPAL' && paymentProvider !== 'PIN_PAYMENTS';

    function updateInfo() {
      ctrl.model.specifyLocale = !!ctrl.model.locale;

      if (ctrl.defaultTrans) {
        ctrl.model.translations = !ctrl.model.translations || _.isEmpty(ctrl.model.translations) ? angular.copy(ctrl.defaultTrans) : ctrl.model.translations;
      }
    }

    function setupIframe() {
      if (!ctrl.preview) {
        return;
      }

      const divContainer = $element[0].querySelector("#iframeContainer");
      divContainer.innerHTML = "";

      const ifr = $document[0].createElement('iframe');
      ifr.allowTransparency = true;
      ifr.setAttribute('id', "iframe-window");
      ifr.setAttribute('width', "100%");
      ifr.setAttribute('height', "700px");
      ifr.setAttribute('style', "outline: 2px solid #bbb;display:block;borderWidth:0;padding:0;margin:0;");
      ifr.frameBorder = "0";

      divContainer.append(ifr);

      const iframeDoc = ifr.contentDocument || ifr.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(ctrl.preview);
      iframeDoc.close();
    }

    function doPreview() {
      ctrl.doingPreview = true;
      InvoiceTranslationPreview.preview(model.id, paymentProvider).then(function(preview) {
        ctrl.doingPreview = false;
        ctrl.preview = $sce.trustAsHtml(preview.html);
        setupIframe();
      }).catch(function() {
        ctrl.doingPreview = false;
      });
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInit: function() {
        updateInfo();
      }, afterSave: function() {
        updateInfo();
        doPreview();
      }
    });

    ctrl.locales = LOCALES;
    ctrl.applications = LocalUserPermissions.getApps();

    ctrl.updateTranslations = function() {
      ctrl.model.translations = angular.copy(ctrl.defaultTrans);
      ctrl.editsMade();
    };

    if (!ctrl.isNew) {
      doPreview();
    }

    defaultInvoiceTranslationsResource.get().then(function(dts) {
      ctrl.defaultTrans = dts;
      updateInfo();
    });

    ctrl.onBeforeSubmit = function() {
      if (!ctrl.model.specifyLocale) {
        ctrl.model.locale = "";
      }
      if (_.isEqual(ctrl.model.translations, ctrl.defaultTrans)) {
        ctrl.model.translations = null;
      }
    };
  });

export default MODULE_NAME;