/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './earnings-account-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import { actionsHelper } from "../common/controller/actions-helper";

const MODULE_NAME = 'earnings-account-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('EarningsAccountActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly, LocalUserPermissions) {
    const ctrl = this;

    ctrl.blockDelete = readOnly || LocalUserPermissions.isAnyAxPublisher();

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.earnings-accounts.earnings-account',
      lcase: 'earningsAccount',
      ucase: 'EarningsAccount'
    });
  });

export default MODULE_NAME;