/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from '../base/default-title-template.html';
import searchTemplate from './search.html';

import standardModule from '../common/standard-module';
import objectStateParamsFilter from '../common/filters/object-type-state-params-filter';
import searchCoordinator from './search-coordinator';
import searchController from './search-controller';
import globalSearchDirective from './global-search-directive';

const MODULE_NAME = 'search-module';

angular.module(MODULE_NAME, [
  standardModule,
  objectStateParamsFilter,
  searchCoordinator,
  searchController,
  globalSearchDirective
])

  .config(function($stateProvider) {
    $stateProvider.state('app.search', {
      url: '/search?q&types&labels&exclusionLabels&matchingLabels&objectState&rateControlLabels',
      reloadOnSearch: false,
      views: {
        content: {
          template: searchTemplate,
          controller: 'SearchCtrl',
          controllerAs: 'ctrl'
        },
        title: {
          template: template
        }
      },
      data: {
        title: 'Search Results'
      }
    });
  })

  .filter('adnSearchAggregationApplied', function($location) {
    return function(aggregations, inverse) {
      let filters = $location.search();
      return _.filter(aggregations, function(aggregation) {
        let isFiltered = filters[aggregation.name];
        return inverse ? !isFiltered : isFiltered;
      });
    };
  });

export default MODULE_NAME;