/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../../common/standard-analysis-module';
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = "team-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('TeamAnalysisCtrl', function($stateParams, Role, localUserProfile, LocalUserPermissions, AdvertisingAccessGroup, Team, Site, LocalNetworkProfile, model, modelComms, User) {
    const ctrl = this;
    ctrl.query = {};

    const networkId = LocalNetworkProfile.getNetworkId();

    function updateOtherUsers() {
      ctrl.query = Role.query(function(page) {
        ctrl.roles = page.results;

        ctrl.query = User.query({concise: true, pageSize: LocalNetworkInfo.obtain().getInfo("userCount")}, function(page) {
          ctrl.allUsers = page.results;
          _.forEach(ctrl.allUsers, function(item) {
            item.obfuscated = item.username.indexOf("@") < 0 && item.username.indexOf("*") > 0;
          });
          let selection = function(u) {
            let rolesByNetwork = _.find(u.userRoles.rolesByNetwork, function(x) { return x.network.id === networkId || x.network.tagId === networkId; });
            if (!rolesByNetwork) {
              return false;
            }
            let teamRole = _.find(rolesByNetwork.roles, ['team.id', model.id]);
            if (teamRole) {
              u.teamRole = _.find(ctrl.roles, ['id', teamRole.role.id]);
            }
            return u.teamRole;
          };
          ctrl.users = _.filter(ctrl.allUsers, selection);
          ctrl.otherUsers = _.reject(ctrl.allUsers, selection);
        });
      });
    }

    ctrl.seesLinks = LocalUserPermissions.hasNetworkPermission('MANAGE_SYSTEM');
    modelComms.addSubscriber(function(data, type, isNew) {
      if (!type) {
        ctrl.showAccessGroups = data.type === 'AX_PUBLISHER' && (localUserProfile.get("username") === 'adnuntius@adnuntius.com' || localUserProfile.get("username") === 'broker1@adnuntius.com');

        if (!isNew && !ctrl.otherUsers) {
          updateOtherUsers();
        }
      }
    });

    ctrl.addAag = function() {
      ctrl.aags = ctrl.aags || [];
      ctrl.aags.unshift(AdvertisingAccessGroup.create({edited: true, isNew: true}));
      ctrl.addAagDisabled = true;
    };

    ctrl.deleteAag = function(aag) {
      aag.markForDeleted = true;
      ctrl.addAagDisabled = true;
    };

    ctrl.cancelDelete = function(aag) {
      aag.markForDeleted = false;
      ctrl.addAagDisabled = false;
    };

    ctrl.selectAllSites = function(aag) {
      aag.siteIds = _.map(ctrl.allSites, 'id');
    };

    ctrl.deselectAllSites = function(aag) {
      aag.siteIds = [];
    };

    ctrl.selectAllTeams = function(aag) {
      aag.teamIds = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectAllTeams = function(aag) {
      aag.teamIds = [];
    };

    ctrl.confirmDeleteAag = function(aag) {
      aag.objectState = 'HIDDEN';
      ctrl.saveAag(aag).then(function() {
        aag.markForDeleted = false;

        _.remove(ctrl.aags, function(savedAag) {
          return savedAag.id === aag.id;
        });
      }).finally(function() {
        ctrl.addAagDisabled = false;
      });
    };

    ctrl.toggleAag = function(aag) {
      aag.edited = !aag.edited;
      ctrl.addAagDisabled = aag.edited;
      _.forEach(ctrl.aags, function(savedAag) {
        if (savedAag.id !== aag.id) {
          savedAag.edited = false;
        }
      });
      if (aag.isNew) {
        _.remove(ctrl.aags, function(savedAag) {
          return savedAag.id === aag.id;
        });
      }
    };

    if (!$stateParams.isNew) {

      AdvertisingAccessGroup.query({ownerTeam: model.id}, function(data) {
        ctrl.aags = _.map(data.results, function(aag) {
          aag.siteIds = _.map(aag.sites || [], 'id');
          aag.teamIds = _.map(aag.teams || [], 'id');
          return aag;
        });
      });

      const params = {pageSize: 1000, filterBy: 'ownerTeam', filterByValue: model.id};
      Site.query(params, function(page) {
        ctrl.allSites = _.filter(page.results, function(s) {
          return _.get(s, ['ownerTeam', 'id']) === model.id;
        });
      });

      const teamParams = {pageSize: 1000, filterBy: 'type', filterByValue: 'AX_ADVERTISER'};
      Team.query(teamParams, function(page) {
        ctrl.allTeams = page.results;
      });

      ctrl.saveAag = function(aag) {
        aag.sites = aag.siteIds;
        aag.teams = aag.teamIds;
        delete aag.siteIds;
        delete aag.teamIds;
        aag.isSaving = true;
        return aag.savePartial().then(function(newAag) {
          aag.edited = false;
          aag.sites = newAag.sites;
          aag.teams = newAag.teams;
          aag.siteIds = _.map(aag.sites || [], 'id');
          aag.teamIds = _.map(aag.teams || [], 'id');
          aag.isNew = false;
        }).finally(function() {
          aag.isSaving = false;
          ctrl.addAagDisabled = false;
        });
      };

      updateOtherUsers();
    } else {
      ctrl.query.$resolved = true;
    }

    ctrl.addUser = function() {
      ctrl.hasNewUser = true;
      let defUser = ctrl.otherUsers[0];
      ctrl.users.unshift({id: defUser.id, username: defUser.username, displayName: defUser.displayName, newUser: true, active: true, edited: true, teamRole: ctrl.roles[0]});
    };

    ctrl.newUserChange = function(user) {
      let otherUser = _.find(ctrl.otherUsers, ['id', user.id]);
      user.username = otherUser.username;
      user.displayName = otherUser.displayName;
    };

    ctrl.editUser = function(user) {
      user.edited = true;
      user.active = true;
      user.prevRole = user.teamRole;
    };

    ctrl.cancelUser = function(user, index) {
      user.edited = false;
      user.teamRole = user.prevRole;
      if (user.newUser) {
        _.pullAt(ctrl.users, index);
        ctrl.hasNewUser = false;
      }
    };

    ctrl.saveUser = function(user) {
      User.get({id: user.id}, function(apiUser) {
        let networkRoles = _.find(apiUser.userRoles.rolesByNetwork, ['network.tagId', networkId]);
        if (networkRoles && networkRoles.roles) {
          let team = _.find(networkRoles.roles, ['team.id', model.id]);
          if (user.active) {
            let roleUpdate = {id: user.teamRole.id};
            if (team) {
              team.role = roleUpdate;
            } else {
              networkRoles.roles.push({team: {id: model.id}, role: roleUpdate});
            }
          } else {
            let indexEntryRemove = _.findIndex(networkRoles.roles, ['team.id', model.id]);
            _.pullAt(networkRoles.roles, indexEntryRemove);
          }
        }
        apiUser.savePartial('userRoles').then(function() {
          user.edited = false;
          user.failSave = false;
          if (user.newUser) {
            user.newUser = false;
            _.remove(ctrl.otherUsers, function(u) {
              return u.id === user.id;
            });
          } else if (!user.active) {
            _.remove(ctrl.users, function(u) {
              return u.id === user.id;
            });
            ctrl.otherUsers.push(user);
          }
          ctrl.hasNewUser = false;
        }, function() {
          user.failSave = true;
        });
      }, function() {
        user.failSave = true;
      });
    };
  });

export default MODULE_NAME;