/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import 'angular-gridster';
import uiSelect from 'ui-select';
import moment from 'moment';
import 'moment-timezone';

import {ADN_GRIDSTER_CONFIG} from "../config/gridster-config";
import {WIDGET_TYPE} from "../common/constants/widget-type";
import {WIDGET_CHART_DATE_OPTIONS} from "../common/constants/widget-type";
import {OBJECT_TYPE} from "../common/constants/object-type";
import {Uuid} from "../../components/util/uuid";

import widgetModalTemplate from './workspace-widget-modal.html';

import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";

const MODULE_NAME = 'workspace-controller';

angular.module(MODULE_NAME, [standardFormModule, 'gridster', uiSelect])

  .controller('WorkspaceCtrl', function($state, $uibModal, $translate, workspace, modelComms, searchResource, WorkspaceRegistry, Team, LocalUserPermissions, LocalNetworkProfile, adnTimeShifter) {

    const updateTeams = function(){
      const perms = LocalUserPermissions.getAllVisPermissions();
      const params = {minimal: true};
      if (perms.AX_ADVERTISER_ADMIN === true) {
        params.filterBy = 'type';
        params.filterByValue = 'AX_ADVERTISER';
      }
      if (perms.AX_PUBLISHER_ADMIN === true) {
        params.filterBy = 'type';
        params.filterByValue = 'AX_PUBLISHER';
      }
      Team.query(params).$promise.then(function(page) {
        ctrl.allTeams = page.results || [];
      });
    };

    const ctrl = this,
      setUpDefaults = function() {
        _.forEach(ctrl.model.data.widgets, function(widget) {
          // want to make sure we can update systemDefined parameters at any stage in the UI without breaking stored widgets.
          widget.systemDefined = angular.copy(WIDGET_TYPE[widget.systemDefined.id].systemDefined);
          widget.fields = widget.fields || angular.copy(WIDGET_TYPE[widget.systemDefined.id].fields);
          widget.fields = _.map(widget.fields, function(f) {
            return f.id || f;
          });
          widget.totalsType = widget.totalsType || 'HIDE'; // required to make a default for existing widgets that are out there
          widget.fieldComparisons = widget.systemDefined.fieldComparison && !_.get(widget, ['fieldComparisons', 'length']) ? [{field: 'rendered', target: undefined}] : widget.fieldComparisons;
          widget.startDate = (_.has(widget, 'startDate') && !widget.startDate) ? moment.tz(LocalNetworkProfile.get('timeZone') || 'utc').startOf("year").startOf('day').toDate() : widget.startDate;
          widget.modStartDate = widget.startDate ? adnTimeShifter.timeShift(widget.startDate) : undefined;
          widget.endDate = _.has(widget, 'endDate') && !widget.endDate ? moment.tz(LocalNetworkProfile.get('timeZone') || 'utc').endOf("year").endOf('day').toDate() : widget.endDate;
          widget.modEndDate = widget.endDate ? adnTimeShifter.timeShift(widget.endDate) : undefined;
          widget.dateOptionStartDate = (widget.systemDefined.hasChartDates && !widget.dateOptionStartDate) ? moment.tz(LocalNetworkProfile.get('timeZone') || 'utc').subtract(7, 'd').startOf('day').toDate() : widget.dateOptionStartDate;
          widget.modDateOptionStartDate = widget.dateOptionStartDate ? adnTimeShifter.timeShift(widget.dateOptionStartDate) : undefined;
          widget.dateOptionEndDate = (widget.systemDefined.hasChartDates && !widget.dateOptionEndDate) ? moment.tz(LocalNetworkProfile.get('timeZone') || 'utc').toDate() : widget.dateOptionEndDate;
          widget.modDateOptionEndDate = widget.dateOptionEndDate ? adnTimeShifter.timeShift(widget.dateOptionEndDate) : undefined;
          widget.rows = Math.min((_.isFinite(widget.rows) && widget.rows > 0 ? widget.rows : 10), 100);
          _.forEach(widget.systemDefined, function(value, key) {
            widget.excludeValues = widget.excludeValues || {};

            if (!Object.prototype.hasOwnProperty.call(widget.excludeValues, key)) {
              widget.excludeValues[key] = value.value;
            }
          });

          function doTrans(f) {
            f.label = $translate.instant((widget.systemDefined.statFields ? 'report.field.' : 'label.') + f.id);
            const trans = LocalNetworkProfile.getNetworkTranslations(f.transKey || f.id);
            if (trans) {
              f.labelInstant = trans;
            }
          }

          _.forEach(widget.systemDefined.allFields, doTrans);
          _.forEach(widget.systemDefined.statFields, doTrans);
          _.forEach(widget.systemDefined.pubFields, doTrans);
          _.forEach(widget.systemDefined.advFields, doTrans);
        });
      },
      init = function() {
        ctrl.hasNetworkPermission = LocalUserPermissions.hasNetworkPermission("MANAGE_NETWORK_DASHBOARDS");
        ctrl.model.data = ctrl.model.data || {};
        ctrl.model.data.widgets = ctrl.model.data.widgets || [];

        if (!ctrl.model.type) {
          ctrl.model.type = ctrl.hasNetworkPermission && !LocalNetworkProfile.isPureMarketplacePlatform() ? 'NETWORK' : 'PERSONAL';
        }
        if (ctrl.model.type === 'TEAM') {
          updateTeams();
        }
        setUpDefaults();
      },
      afterSave = function() {
        angular.extend(workspace, ctrl.model);
        WorkspaceRegistry.update(ctrl.model);

        $state.go('app.workspaces.workspace', {id: ctrl.model.id});
      };

    adnFormHelper.setUpForm(ctrl, workspace, {afterInit: init, afterSave: afterSave, modelComms: modelComms});

    ctrl.eventHook = {};
    ctrl.addComparisons = function(widget) {
      widget.fieldComparisons = widget.fieldComparisons || [];
      widget.fieldComparisons.push({});
      ctrl.editsMade();
    };
    ctrl.removeComparisons = function(widget, index) {
      _.pullAt(widget.fieldComparisons, index);
      ctrl.eventHook.removeControlHook('fc' + widget.id + '-' + index + '.target');
      ctrl.eventHook.removeControlHook('fc' + widget.id + '-' + index + '.field');
      ctrl.editsMade();
    };

    let editable = $state.current.data.editable;
    let myOpts = angular.copy(ADN_GRIDSTER_CONFIG.WORKSPACES);
    myOpts.draggable.enabled = editable;
    myOpts.resizable.enabled = editable;
    ctrl.gridsterOpts = _.merge(myOpts, {
      resizable: {
        stop: function() {
          ctrl.editsMade();
        }
      },
      draggable: {
        stop: function() {
          ctrl.editsMade();
        }
      }
    });
    if (!editable) {
      myOpts.margins = [14, 14];
    }

    ctrl.objSearch = function(searchElement, objectType) {
      const safeObjectType = OBJECT_TYPE[objectType] || OBJECT_TYPE.LineItem;
      searchResource.query({"q": searchElement.search, "types": safeObjectType.type}).then(function(data) {
        ctrl.objResults = _.map(data.searchResults, function(sr) {
          return {
            id: sr.id,
            name: sr.name
          };
        });
      });
    };

    ctrl.chartDateOptions = WIDGET_CHART_DATE_OPTIONS;

    ctrl.changeType = function(widget) {
      widget.objIds = [];
    };

    ctrl.updateType = function() {
      switch (ctrl.model.type) {
        case 'TEAM':
          updateTeams();
          break;
        default:
          ctrl.model.team = undefined;
      }
    };

    ctrl.whichFields = function(fieldType, widget) {
      if (widget.objectType === 'Creative' || widget.objectType === 'LineItem' || widget.objectType === 'Order' || widget.objectType === 'Advertiser') {
        return fieldType === 'advFields';
      }
      return fieldType === 'pubFields';
    };

    ctrl.selectWidgets = function() {

      $uibModal.open({
        template: widgetModalTemplate,
        controllerAs: 'modalCtrl',
        controller: 'WorkspaceModalCtrl',
        backdrop: 'static',
        size: 'lg'
      }).result.then(function(selections) {
        if (!selections.length) {
          return;
        }
        ctrl.editsMade();
        _.forEach(selections, function(sel) {
          ctrl.updateWidgets(sel, true);
        });

        setUpDefaults();
      });
    };

    ctrl.updateWidgets = function(widget, add) {
      ctrl.editsMade();
      if (!add) {
        _.remove(ctrl.model.data.widgets, function(savedWidget) {
          return widget.id === savedWidget.id;
        });
        return;
      }
      let newWidget = angular.copy(widget);
      newWidget.id = Uuid.generate();
      ctrl.model.data.widgets.push(newWidget);
    };

    ctrl.deleteWidget = function(widget) {
      ctrl.updateWidgets(widget, false);
    };

    ctrl.onBeforeSubmit = function() {
      _.forEach(ctrl.model.data.widgets, function(widget) {
        let unorderedFields = angular.copy(widget.fields);
        widget.fields = [];
        _.forEach(widget.systemDefined.allFields || widget.systemDefined.statFields, function(f) {
          if (_.indexOf(unorderedFields, f.id) > -1) {
            widget.fields.push(f.id);
          }
        });
        widget.fieldComparisons = _.filter(widget.fieldComparisons, function(cf) {
          return cf.field && cf.target;
        });
        widget.objId = _.pick(widget.objId, ['id', 'name']);
        widget.objMulti = _.map(widget.objMulti, function(om) {
          return {
            id: om.id,
            name: om.name
          };
        });


        widget.startDate = _.isDate(widget.modStartDate) ? adnTimeShifter.timeShift(widget.modStartDate, true) : null;
        widget.startDate = _.isDate(widget.startDate) ? moment.tz(widget.startDate, LocalNetworkProfile.get('timeZone') || 'utc').startOf('day') : null;
        widget.dateOptionStartDate = _.isDate(widget.modDateOptionStartDate) ? adnTimeShifter.timeShift(widget.modDateOptionStartDate, true) : null;
        widget.dateOptionStartDate = _.isDate(widget.dateOptionStartDate) ? moment.tz(widget.dateOptionStartDate, LocalNetworkProfile.get('timeZone') || 'utc').toDate() : null;
        widget.endDate = _.isDate(widget.modEndDate) ? adnTimeShifter.timeShift(widget.modEndDate, true) : null;
        widget.endDate = _.isDate(widget.endDate) ? moment.tz(widget.endDate, LocalNetworkProfile.get('timeZone') || 'utc').endOf('day') : null;
        widget.dateOptionEndDate = _.isDate(widget.modDateOptionEndDate) ? adnTimeShifter.timeShift(widget.modDateOptionEndDate, true) : null;
        widget.dateOptionEndDate = _.isDate(widget.dateOptionEndDate) ? moment.tz(widget.dateOptionEndDate, LocalNetworkProfile.get('timeZone') || 'utc').toDate() : null;

        if (!widget.systemDefined.hasChartDates) {
          widget.dateOptionStartDate = null;
          widget.dateOptionsEndDate = null;
        }
      });
    };
  })

  .controller("WorkspaceModalCtrl", function($uibModalInstance, LocalUserPermissions) {
    const modalCtrl = this,
      allWidgets = _.filter(angular.copy(WIDGET_TYPE), function(w) {
        return !w.systemDefined.permissions || _.intersection(_.filter(_.map(LocalUserPermissions.getAllNetworkPermissions(), function(perm, key) {
          return perm ? key : null;
        }), function(p) {
          return p;
        }), w.systemDefined.permissions).length > 0;
      });

    modalCtrl.widgetsForShow = allWidgets;
    modalCtrl.countWidgets = _.size(allWidgets);

    let totalTags = [];
    _.forEach(allWidgets, function(widget) {
      totalTags.push(widget.systemDefined.tags);
    });
    totalTags = _.flatten(totalTags);
    let uniqueCount = {};
    _.forEach(totalTags, function(tag) {
      uniqueCount[tag] = (uniqueCount[tag] || 0) + 1;
    });
    modalCtrl.tags = uniqueCount;

    modalCtrl.filterTag = function(tag) {
      modalCtrl.selectedTag = tag;
      if (!tag) {
        modalCtrl.widgetsForShow = allWidgets;
        return;
      }
      let widgetsToShow = [];
      _.forEach(allWidgets, function(widget) {
        if (_.indexOf(widget.systemDefined.tags, tag) > -1) {
          widgetsToShow.push(widget);
        }
      });
      modalCtrl.widgetsForShow = widgetsToShow;
    };

    modalCtrl.saveWidgets = function() {
      $uibModalInstance.close(_.filter(allWidgets, 'added'));
    };

    modalCtrl.addWidget = function(widget) {
      widget.added = !widget.added;
    };
  });

export default MODULE_NAME;