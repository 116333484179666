export const TTL = [
  'FIXED_DURATION',
  'TODAY',
  'THIS_WEEK',
  'THIS_MONTH',
  'UNTIL_MONDAY',
  'UNTIL_TUESDAY',
  'UNTIL_WEDNESDAY',
  'UNTIL_THURSDAY',
  'UNTIL_FRIDAY',
  'UNTIL_SATURDAY',
  'UNTIL_SUNDAY'
];