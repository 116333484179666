/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './product-edit.html';
import listTemplate from './product-list.html';
import copyTemplate from '../../common/copy-actions.html';

import adminModule from '../admin-module';
import productController from './product-controller';
import productActionsController from './product-actions-controller';
import { Uuid } from "../../../components/util/uuid";

const MODULE_NAME = 'product-module';

angular.module(MODULE_NAME, [adminModule, productController, productActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('products.product', 'Product', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(NetworkData, Product, $log) {
          return {
            func: function(ctrl) {
              if (ctrl.initialised) {
                return false;
              }

              Product.query({minimal: true, pageSize: 101}).$promise.then(function(page) {
                ctrl.productInfo = {};
                _.forEach(page.results, function(item) {
                  ctrl.productInfo[item.id] = {
                    id: item.id,
                    name: item.name
                  };
                });
              });

              function cleanData() {
                _.forEach(_.get(ctrl.productSelections, ['data', 'filter']) || [], function(ps) {
                  delete ps.editable;

                  _.forEach(ps.options, function(opt) {
                    if (_.trim(ps.key).length < 1) {
                      ps.deleteMe = true;
                    }
                    if (!(_.trim(opt.value).length > 0 && opt.products.length > 0)) {
                      opt.deleteMe = true;
                    }
                    opt.products = _.filter(opt.products, function(productId) {
                      return ctrl.productInfo[productId];
                    });
                  });
                });

                ctrl.productSelections.data.filter = _.filter(_.get(ctrl.productSelections, ['data', 'filter']) || [], function(ps) {
                  if (!ps.deleteMe) {
                    ps.options = _.filter(ps.options, function(opt) {
                      return !opt.deleteMe;
                    });
                  }
                  return !ps.deleteMe;
                });
              }

              let previousProductSelections;
              NetworkData.get({filterByValue: 'productSelection', filterBy: 'type'}, function(page) {
                if (page.results.length) {
                  const entry = page.results[0];
                  try {
                    entry.data.filter = JSON.parse(entry.data.filter);
                    ctrl.productSelections = new NetworkData(_.cloneDeep(entry));
                    cleanData();
                    previousProductSelections = _.cloneDeep(ctrl.productSelections);
                  } catch {
                    $log.warn("Found a problem saving");
                  }
                }
                ctrl.isSetUp = true;
              });

              function getEmptyEntry() {
                return {
                  key: '',
                  editable: true,
                  options: [{
                    value: '',
                    products: []
                  }]
                };
              }

              ctrl.edit = function() {
                ctrl.editsMade = true;
              };

              ctrl.setUpOptions = function() {
                ctrl.editsMade = true;
                if (!_.get(ctrl.productSelections, ['data', 'filter'])) {
                  ctrl.productSelections = new NetworkData({
                    id: Uuid.generate(),
                    name: 'Product Selection',
                    description: 'Product selection options',
                    type: 'productSelection',
                    data: {
                      filter: [getEmptyEntry()]
                    }
                  });
                  return;
                }
                ctrl.productSelections.data.filter.push(getEmptyEntry());
              };

              ctrl.deleteOption = function(index) {
                ctrl.editsMade = true;
                _.pullAt(ctrl.productSelections.data.filter, index);
              };

              ctrl.addValue = function(index) {
                ctrl.editsMade = true;
                ctrl.productSelections.data.filter[index].options.push({value: '', products: []});
              };

              ctrl.removeValue = function(parentIndex, optionIndex) {
                ctrl.editsMade = true;
                _.pullAt(ctrl.productSelections.data.filter[parentIndex].options, optionIndex);
              };

              ctrl.saveProductSelections = function() {
                ctrl.editsMade = false;
                ctrl.productSelectionPass = false;
                ctrl.productSelectionError = false;
                cleanData();
                ctrl.productSelections.savePartial().then(function() {
                  ctrl.productSelectionPass = true;
                  previousProductSelections = _.cloneDeep(ctrl.productSelections);
                }).catch(function() {
                  ctrl.productSelectionError = true;
                });
              };

              ctrl.cancelProductSelections = function() {
                ctrl.productSelections = _.cloneDeep(previousProductSelections);
                ctrl.editsMade = false;
                ctrl.productSelectionPass = false;
                ctrl.productSelectionError = false;
              };

              ctrl.initialised = true;
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'ProductCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'ProductActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Product'));
  });

export default MODULE_NAME;