/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import ngRollbar from 'ng-rollbar';
import _ from 'lodash';

import BUILD_INFO from '../../gen-config/build-info';
import {LocalDebugInfo} from "../../components/session/local-debug-info";
import {Session} from "../../components/session/session";
import { adsBlocked } from "./ads-blocked";

const MODULE_NAME = "exception-override";

// weird $uibModal unhandled messages from standard actions
const ignoreMessages = ["transition superseded", "Possibly unhandled rejection: transition superseded", "Possibly unhandled rejection: backdrop click", "Possibly unhandled rejection: undefined", "Possibly unhandled rejection: escape key press"];
const ignoreStatusCodes = [403, 401, 422];
const fieldsForHiding = [['config.headers.Authorization'], ['headers.Authorization'], ['auth_token'], ['refresh_token'], ['config.params.auth_token'], ['config.params.refresh_token']];

function attemptObject(exception) {
  if (_.isString(exception)) {
    try {
      const text = exception.replace("Possibly unhandled rejection: ", "");
      return JSON.parse(text);
    } catch (e) {
      return exception;
    }
  }
  return exception;
}

function omitSensitiveFields(obj) {
  if (_.isObject(obj)) {
    _.forEach(fieldsForHiding, function(fields) {
      obj = _.omit(obj, fields);
    });
  }
  return obj;
}

angular.module(MODULE_NAME, [ngRollbar])

  .factory('$exceptionHandler', function($injector, $log, Rollbar) {
    let LocalNetworkProfile,
      localUserProfile,
      AuthService;

    return function(exception, cause) {
      LocalNetworkProfile = LocalNetworkProfile || $injector.get('LocalNetworkProfile');
      localUserProfile = localUserProfile || $injector.get('localUserProfile');
      AuthService = AuthService || $injector.get('AuthService');

      const networkId = LocalNetworkProfile.getNetworkId();
      const username = localUserProfile.get('username');
      const userId = localUserProfile.get('userId');
      const sessionUsername = Session.obtainData().username;

      const errorObj = {};
      if (_.isObject(exception) && exception.message && exception.stack) {
        // need to do this because _.cloneDeep doesn't copy the stuff!
        errorObj.message = exception.message;
        errorObj.stack = exception.stack;
      }
      let exceptionObject = _.isString(exception) ? attemptObject(exception) : errorObj.message ? errorObj : _.cloneDeep(exception);
      const statusCode = _.get(exceptionObject, ['status']);
      if (ignoreStatusCodes.indexOf(statusCode) > -1) {
        // don't need to report on a bunch of status codes.
        return;
      }
      if (statusCode === -1 && adsBlocked()) {
        // this failure is because of ad blockers. Can ignore.
        return;
      }
      if (statusCode === -1 && (!AuthService.isAuthenticated() || AuthService.getSession().isExpired())) {
        // happens when API is unreachable or when token refresh can't happen and edge cases like that
        return;
      }
      if (statusCode === -1) {
        Rollbar.error("-1 status info", AuthService.getSession());
      }
      let exc = _.cloneDeep(_.get(exceptionObject, ['data', 'apiText']) || exception.message || _.isObject(exceptionObject) ? exceptionObject : exception);
      exc = omitSensitiveFields(exc);
      if (ignoreMessages.indexOf(exc) > -1) {
        return;
      }
      exc = "(" + networkId + ")(" + userId + ") " + JSON.stringify(exc);
      if (username === sessionUsername && username === LocalDebugInfo.obtain().getInfo('username')) {
        exc += ' - (' + BUILD_INFO.gitSha.substring(0, 6) + ')(' + LocalDebugInfo.obtain().getInfo('signIn') + ')(' + LocalDebugInfo.obtain().getInfo('expired') + ")";
      } else {
        exc += ' - (' + sessionUsername + ')(' + BUILD_INFO.gitSha.substring(0, 6) + ')(' + LocalDebugInfo.obtain().getInfo('userId') + ')(' + LocalDebugInfo.obtain().getInfo('signIn') + ')(' + LocalDebugInfo.obtain().getInfo('expired') + ")";
      }
      $log.error(exception, cause);
      Rollbar.error(exc, cause);
    };
  });

export default MODULE_NAME;