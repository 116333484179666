import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './folder-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './folder-list.html';
import analysisTemplate from './folder-analysis.html';

import standardModule from '../common/standard-module';
import folderController from './folder-controller';
import folderAnalysis from './folder-analysis';
import folderActionsController from './folder-actions-controller';

const MODULE_NAME = 'folder-module';

angular.module(MODULE_NAME, [standardModule, folderController, folderAnalysis, folderActionsController])
  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('folders.folder', 'Folder', 'app.dat-seg', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'leftPanel@app.folders.folder': {
            template: editTemplate,
            controller: 'FolderCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.folders.folder': {
            template: analysisTemplate,
            controller: 'FolderAnalysisCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'FolderActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Folder'));
  });

export default MODULE_NAME;
