/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

import {ADV_FIELDS, PUB_FIELDS} from '../options/chart-options';

type FieldTypeData = {
  id: string;
};

type FieldType = Record<string, FieldTypeData>;

const FIELD_TYPE: FieldType = {
  NUMBER: {id: 'NUMBER'},
  PERCENTAGE: {id: 'PERCENTAGE'},
  MONEY: {id: 'MONEY'},
  STRING: {id: 'STRING'},
  NAME: {id: 'NAME'},
  ALL_OBJECTIVES: {id: 'ALL_OBJECTIVES'},
  DATE: {id: 'DATE'},
  VELOCITY: {id: 'VELOCITY'}
};

type Field = {
  id: string;
  type: string;
  transKey?: string;
  suffix?: string;
  numeric?: boolean;
};

type AllFields = Record<string, Field>;

export const ALL_FIELDS: AllFields = {
  name: {
    id: 'name',
    type: FIELD_TYPE.NAME.id
  },
  startDate: {
    id: 'startDate',
    type: FIELD_TYPE.DATE.id
  },
  endDate: {
    id: 'endDate',
    type: FIELD_TYPE.DATE.id
  },
  allObjectives: {
    id: 'allObjectives',
    type: FIELD_TYPE.ALL_OBJECTIVES.id,
    numeric: true
  },
  impressionsObjective: {
    id: 'impressionObjective',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  impressionDelivered: {
    id: 'impressionDelivered',
    transKey: 'impression',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  clickObjective: {
    id: 'clickObjective',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  clickDelivered: {
    id: 'clickDelivered',
    transKey: 'click',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  renderedImpressionObjective: {
    id: 'renderedImpressionObjective',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  renderedImpressionDelivered: {
    id: 'renderedImpressionDelivered',
    transKey: 'rendered',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  viewableImpressionObjective: {
    id: 'viewableImpressionObjective',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  viewableImpressionDelivered: {
    id: 'viewableImpressionDelivered',
    transKey: 'viewable',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  visibleImpressionObjective: {
    id: 'visibleImpressionObjective',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  visibleImpressionDelivered: {
    id: 'visibleImpressionDelivered',
    transKey: 'visible',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  value: {
    id: 'value',
    type: FIELD_TYPE.MONEY.id,
    numeric: true
  },
  valueDelivered: {
    id: 'valueDelivered',
    type: FIELD_TYPE.MONEY.id,
    numeric: true
  },
  ctr: {
    id: 'ctr',
    type: FIELD_TYPE.PERCENTAGE.id,
    numeric: true
  },
  viewability: {
    id: 'viewability',
    type: FIELD_TYPE.PERCENTAGE.id,
    numeric: true
  },
  numberOfCreatives: {
    id: 'numberOfCreatives',
    type: FIELD_TYPE.NUMBER.id,
    numeric: true
  },
  deliveryPercentage: {
    id: 'deliveryPercentage',
    type: FIELD_TYPE.NUMBER.id,
    suffix: '%',
    numeric: true
  },
  elapsedTimePercentage: {
    id: 'elapsedTimePercentage',
    type: FIELD_TYPE.NUMBER.id,
    suffix: '%',
    numeric: true
  },
  velocity: {
    id: 'velocity',
    type: FIELD_TYPE.VELOCITY.id
  }
};

export const WIDGET_CHART_DATE_OPTIONS: Array<string> = ['last6Hours', 'last12Hours', 'last24Hours', 'today', 'yesterday', 'last7Days', 'thisWeek', 'lastWeek', 'last30Days', 'thisMonth', 'lastMonth', 'last6Months', 'last12Months', 'thisYear', 'lastYear', 'custom'];

const defLineItemFields = _.clone(ALL_FIELDS);
const runLineItemFields = _.clone(defLineItemFields);
const theFields = [
  defLineItemFields.name.id, defLineItemFields.allObjectives.id, defLineItemFields.startDate.id, defLineItemFields.endDate.id,
  defLineItemFields.valueDelivered.id, defLineItemFields.impressionDelivered.id, defLineItemFields.clickDelivered.id, defLineItemFields.ctr.id
];
const runningFields = _.clone(theFields);
runningFields.push(defLineItemFields.deliveryPercentage.id);
runningFields.push(defLineItemFields.elapsedTimePercentage.id);
runningFields.push(defLineItemFields.velocity.id);

const widgetAdvFields = _.assign({}, ADV_FIELDS);
delete widgetAdvFields.customFields;

const defaultStatFields = [].concat(widgetAdvFields.impressions, widgetAdvFields.rendered, widgetAdvFields.visibles, widgetAdvFields.viewables, widgetAdvFields.clicks);

const advCompareFields = _.assign({}, widgetAdvFields);
delete advCompareFields.customFields;
delete advCompareFields.ctr;
delete advCompareFields.viewability;
delete advCompareFields.visibility;
delete advCompareFields.renderedRate;
delete advCompareFields.fee;
delete advCompareFields.techFee;
delete advCompareFields.creativeTagFee;
delete advCompareFields.impressionsPerUniqueUser;
delete advCompareFields.eCpm;
delete advCompareFields.vCpm;

const widgetPubFields = _.assign({}, PUB_FIELDS);
delete widgetPubFields.customFields;

type SystemDefined = {
  id: string;
  widget: string;
  thumbSrc: string;
  hasStartDate?: boolean | false;
  hasEndDate?: boolean | false;
  widgetParams?: any | undefined;
  advFields?: any | undefined;
  pubFields?: any | undefined;
  allFields?: AllFields | undefined;
  statFields?: any | undefined;
  isCollection?: boolean | undefined;
  isChart?: boolean | undefined;
  isIFrame?: boolean | undefined;
  isHtmlInsertion?: boolean | undefined;
  starredIds?: boolean | undefined;
  labelled?: boolean | undefined;
  objectType?: string | undefined;
  objectType2?: string | undefined;
  permissions?: Array<string> | undefined;
  tags: Array<string>;
  hasChartDates?: boolean | false;
  fieldComparison?: any | undefined;
  fieldComparisonMulti?: any | undefined;
  excludeDefaults?: any | undefined;
  selFieldsConfig?: any | undefined;
  selFieldsConfig2?: any | undefined;
  selFieldsConfig3?: any | undefined;
  selFieldsConfig4?: any | undefined;
  selFieldsConfig5?: any | undefined;
  selFieldsConfig6?: any | undefined;
  selFieldsConfig7?: any | undefined;
  maxStatsObj?: number | undefined;
};

type WidgetTypeInfo = {
  name: string;
  sizeX: number;
  sizeY: number;
  desc: string;
  rows?: number | undefined;
  html?: string;
  url?: string;
  totalsType?: string;
  excludeValues?: any | undefined;
  startDate?: any | undefined;
  endDate?: any | undefined;
  fields?: Array<string> | undefined;
  labels?: Array<string> | [];
  selField?: string | undefined;
  selField2?: string | undefined;
  selField3?: string | undefined;
  selField4?: string | undefined;
  selField5?: string | undefined;
  selField6?: string | undefined;
  selField7?: string | undefined;
  dateOption?: string | undefined;
  dateOptionStartDate?: string | undefined,
  dateOptionEndDate?: string | undefined,
  objIds?: Array<string> | undefined;
  objectType?: string;
  height?: number | undefined;
  systemDefined: SystemDefined;
};

type WidgetType = Record<string, WidgetTypeInfo>;

export const WIDGET_TYPE: WidgetType = {
  advBarChart: {
    name: 'Bar Chart',
    sizeX: 3,
    sizeY: 2,
    desc: 'Compare stats across objects via a bar chart.',
    selField: widgetAdvFields.impressions.id,
    selField2: widgetAdvFields.rendered.id,
    selField3: widgetAdvFields.visibles.id,
    selField4: widgetAdvFields.viewables.id,
    selField5: widgetAdvFields.uniqueUsers.id,
    selField6: '',
    selField7: '',
    dateOptionStartDate: '',
    dateOptionEndDate: '',
    dateOption: 'last7Days',
    objectType: 'LineItem',
    objIds: [],
    height: 340,
    systemDefined: {
      id: 'advBarChart',
      widget: 'adnBarChartWidget',
      thumbSrc: 'https://regalia.adnuntius.com/ui/bar-chart.png',
      advFields: widgetAdvFields,
      pubFields: widgetPubFields,
      isCollection: true,
      hasChartDates: true,
      selFieldsConfig: {max: 1, mandatory: true},
      selFieldsConfig2: {max: 1, mandatory: false},
      selFieldsConfig3: {max: 1, mandatory: false},
      selFieldsConfig4: {max: 1, mandatory: false},
      selFieldsConfig5: {max: 1, mandatory: false},
      selFieldsConfig6: {max: 1, mandatory: false},
      selFieldsConfig7: {max: 1, mandatory: false},
      maxStatsObj: 5,
      isChart: true,
      tags: ['Stats']
    }
  },
  advTreeMap: {
    name: 'Tree Map',
    sizeX: 3,
    sizeY: 2,
    desc: 'Compare stats across objects via a tree map.',
    selField: widgetAdvFields.impressions.id,
    selField2: widgetAdvFields.rendered.id,
    dateOptionStartDate: '',
    dateOptionEndDate: '',
    dateOption: 'last7Days',
    objectType: 'LineItem',
    objIds: [],
    height: 340,
    systemDefined: {
      id: 'advTreeMap',
      widget: 'adnTreeMapWidget',
      thumbSrc: 'https://regalia.adnuntius.com/ui/tree-map.png',
      advFields: widgetAdvFields,
      pubFields: widgetPubFields,
      hasChartDates: true,
      isCollection: true,
      selFieldsConfig: {max: 1, mandatory: true},
      selFieldsConfig2: {max: 1, mandatory: false},
      maxStatsObj: 5,
      isChart: true,
      tags: ['Stats']
    }
  },
  advMultiObjStats: {
    name: 'Line Chart',
    sizeX: 3,
    sizeY: 2,
    desc: 'Compare stats across time',
    selField: widgetAdvFields.impressions.id,
    objectType: 'LineItem',
    objIds: [],
    dateOptionStartDate: '',
    dateOptionEndDate: '',
    dateOption: 'last7Days',
    height: 340,
    totalsType: 'HIDE',
    systemDefined: {
      id: 'advMultiObjStats',
      widget: 'adnStatsWidget',
      thumbSrc: 'https://regalia.adnuntius.com/ui/line-chart.png',
      advFields: widgetAdvFields,
      pubFields: widgetPubFields,
      hasChartDates: true,
      isCollection: true,
      selFieldsConfig: {max: 1, mandatory: true},
      maxStatsObj: 5,
      isChart: true,
      tags: ['Stats']
    }
  },
  labelledLineItems: {
    name: 'Labelled Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    desc: "Find all your labelled line items throughout the app.",
    fields: runningFields,
    labels: [],
    systemDefined: {
      id: 'labelledLineItems',
      widget: 'adnLineItemList',
      labelled: true,
      widgetParams: {where: 'executionState'},
      allFields: runLineItemFields,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      tags: ['Line Item'],
    }
  },
  runningLineItems: {
    name: 'Running Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    desc: "Find all your running line items throughout the app.",
    excludeValues: {
      endDate: false
    },
    labels: [],
    fields: runningFields,
    systemDefined: {
      id: 'runningLineItems',
      widget: 'adnLineItemList',
      widgetParams: {filterBy: 'executionState', filterByValue: 'RUNNING'},
      allFields: runLineItemFields,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      labelled: true,
      tags: ['Line Item'],
      excludeDefaults: {
        endDate: {
          text: 'Exclude line items missing an end date',
          value: false
        }
      }
    }
  },
  endedLineItems: {
    name: 'Ended Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    fields: theFields,
    labels: [],
    desc: "Find all the line items that have ended throughout the app.",
    systemDefined: {
      id: 'endedLineItems',
      widget: 'adnLineItemList',
      widgetParams: {filterBy: 'executionState', filterByValue: 'ENDED'},
      labelled: true,
      allFields: defLineItemFields,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      tags: ['Line Item']
    }
  },
  pausedLineItems: {
    name: 'Paused Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    fields: theFields,
    labels: [],
    desc: "Find all your paused line items throughout the app.",
    systemDefined: {
      id: 'pausedLineItems',
      widget: 'adnLineItemList',
      labelled: true,
      widgetParams: {filterBy: 'userState', filterByValue: 'PAUSED'},
      allFields: defLineItemFields,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      tags: ['Line Item']
    }
  },
  reservedLineItems: {
    name: 'Reserved Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    fields: theFields,
    labels: [],
    desc: "Find all your reserved line items throughout the app.",
    systemDefined: {
      id: 'reservedLineItems',
      widget: 'adnLineItemList',
      labelled: true,
      widgetParams: {filterBy: 'userState', filterByValue: 'RESERVED'},
      allFields: defLineItemFields,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      tags: ['Line Item']
    }
  },
  undeliverableLineItems: {
    name: 'Undeliverable Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    fields: theFields,
    labels: [],
    desc: "Find all your undeliverable line items.",
    systemDefined: {
      id: 'undeliverableLineItems',
      widget: 'adnLineItemList',
      widgetParams: {filterBy: 'executionState', filterByValue: 'NOT_READY'},
      hasStartDate: true,
      hasEndDate: true,
      labelled: true,
      allFields: defLineItemFields,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      tags: ['Line Item']
    },
  },
  advertiserEarnings: {
    name: 'Advertiser Earnings',
    desc: 'View advertiser stats across earnings accounts',
    fields: defaultStatFields,
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    startDate: '',
    endDate: '',
    systemDefined: {
      id: 'advertiserEarnings',
      widget: 'adnAdvertiserEarningsList',
      objectType: 'Advertiser',
      objectType2: 'EarningsAccount',
      hasStartDate: true,
      hasEndDate: true,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      statFields: widgetAdvFields,
      advFields: advCompareFields,
      fieldComparison: true,
      fieldComparisonMulti: true,
      permissions: ['MANAGE_BUSINESS', 'AX_ADVERTISER_ADMIN'],
      tags: ['Advertiser']
    }
  },
  orders: {
    name: 'Orders',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    desc: 'Show all orders for your network and their associated line items.',
    systemDefined: {
      id: 'orders',
      widget: 'adnOrderList',
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      tags: ['Line Item', 'Order']
    }
  },
  starredLineItems: {
    name: 'Starred Line Items',
    sizeX: 6,
    sizeY: 2,
    rows: 10,
    desc: "Show all the line items you have starred.",
    fields: theFields,
    systemDefined: {
      id: 'starredLineItems',
      widget: 'adnLineItemList',
      starredIds: true,
      thumbSrc: 'https://regalia.adnuntius.com/ui/tabular-data.png',
      allFields: defLineItemFields,
      tags: ['Line Item', 'Starred']
    }
  },
  htmlInsertion: {
    name: 'HTML',
    sizeX: 6,
    sizeY: 3,
    height: 550,
    desc: 'Add your own HTML code into your dashboard.',
    html: '<h1>Example Heading</h1>\n<p>Example content</p>',
    systemDefined: {
      id: 'htmlInsertion',
      widget: 'adnHtmlInsertion',
      thumbSrc: 'https://regalia.adnuntius.com/ui/portal.png',
      tags: ['Custom'],
      isIFrame: true,
      isHtmlInsertion: true
    }
  },
  externalIframe: {
    name: 'External Page Loader',
    sizeX: 6,
    sizeY: 3,
    height: 550,
    desc: 'Include an external page into your dashboard.',
    url: '',
    systemDefined: {
      id: 'externalIframe',
      widget: 'adnWidgetIframe',
      thumbSrc: 'https://regalia.adnuntius.com/ui/portal.png',
      tags: ['Custom'],
      isIFrame: true
    }
  }
};
