/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import auth from '../../../components/auth/auth';
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = 'cdn-upload-controller';

angular.module(MODULE_NAME, [standardFormModule, auth])

  .controller('CdnUploadCtrl', function($stateParams, model, modelComms) {
    const ctrl = this;
    adnFormHelper.setUpForm(ctrl, model, {modelComms: modelComms});

    ctrl.isNew = $stateParams.isNew;

    ctrl.eventHook = {
      onUpdate: function(asset) {
        ctrl.isNew = false;
        _.merge(ctrl.model, asset);
        ctrl.saveDirect(asset);
      }
    };
  });

export default MODULE_NAME;