 /**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';
import httpAuthInterceptor from 'angular-http-auth';
import dynamicLocale from 'angular-dynamic-locale';
import md5 from 'angular-md5';

import {AUTH_EVENT} from "../../components/auth/auth-constants";

import BUILD_INFO from '../../gen-config/build-info';
import auth from '../../components/auth/auth';
import resources from '../../components/api/resources/resources';
import {LocalDebugInfo} from '../../components/session/local-debug-info';
import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import authHelper from '../../components/util/auth-helper';

import template from './sign-in.html';
import modalTemplate from './modal-dialog.html';
import {EmailHelper} from "../../components/util/email";
import {ApiConfig} from "../../components/api/api";
import {Uuid} from "../../components/util/uuid";
import { getRegisteredUrl } from "../config/registered-urls";
import {HOME_STATE} from "../home/home-module";

const SIGN_IN_ROUTER_STATE = "sign-in";

const MODULE_NAME = "sign-in";

const BACKGROUNDS = {standard: ['melbourne', 'oslo', 'mtcook'], masquerade: ['nz']};

const VIEWS = {
  SIGN_IN: {
    id: 'SIGN_IN',
    title: 'Sign in',
    username: true,
    gravatar: true,
    password: true,
    resetMessage: false,
    masquerade: false,
    forgotPassword: true,
    diffAccount: true,
    buttonText: 'Sign in',
    submitMethod: 'signIn'
  },
  RESET_PASSWORD: {
    id: 'RESET_PASSWORD',
    title: 'Reset Your Password',
    username: true,
    usernameAlways: true,
    gravatar: false,
    password: false,
    resetMessage: true,
    masquerade: false,
    forgotPassword: false,
    diffAccount: false,
    buttonText: 'Send Password Reset Email',
    submitMethod: 'resetPassword'
  },
  PASSWORD_HAS_RESET: {
    id: 'PASSWORD_HAS_RESET',
    title: 'Reset Your Password',
    username: false,
    usernameAlways: false,
    gravatar: false,
    resetMessage: false,
    hasResetMessage: true,
    masquerade: false,
    buttonText: false,
    submitMethod: 'null'
  },
  TWO_FACTOR_AUTH: {
    id: 'TWO_FACTOR_AUTH',
    title: 'Enter Two-Factor Authentication Code',
    username: false,
    usernameAlways: false,
    gravatar: false,
    resetMessage: false,
    hasResetMessage: false,
    masquerade: false,
    buttonText: 'Sign in',
    twoFactorAuth: true,
    submitMethod: 'signIn2fa'
  },
  PASSWORD_OVERLOAD: {
    id: 'PASSWORD_OVERLOAD',
    title: 'Reset Your Password',
    username: false,
    usernameAlways: false,
    gravatar: false,
    resetMessage: false,
    hasOverloadMessage: true,
    masquerade: false,
    buttonText: false,
    submitMethod: 'null'
  },
  PASSWORD_UPDATE: {
    id: 'PASSWORD_UPDATE',
    title: 'Update Your Password',
    password: true,
    confirmPassword: true,
    passwordHint: true,
    buttonText: 'Update Password',
    submitMethod: 'updatePassword'
  }
};

VIEWS.MASQUERADE = angular.copy(VIEWS.SIGN_IN);
VIEWS.MASQUERADE.masquerade = true;

function checkForReload($window, $timeout) {
  let makeReload = $window.CURRENT_BUILD_INFO && $window.CURRENT_BUILD_INFO.gitSha !== BUILD_INFO.gitSha && moment($window.CURRENT_BUILD_INFO.buildTime).isAfter(BUILD_INFO.buildTime);
  if (makeReload) {
    $timeout(function() {
        $window.location.reload(true);
    }, 125);
  }
}

angular.module(MODULE_NAME, [
  uiRouter,
  uiBootstrap,
  httpAuthInterceptor,
  dynamicLocale,
  md5,
  auth,
  resources,
  localUserPermissions,
  localNetworkProfile,
  authHelper
])

  .config(function($stateProvider) {
    $stateProvider.state(SIGN_IN_ROUTER_STATE, {
      url: '/' + SIGN_IN_ROUTER_STATE,
      data: {public: true, view: VIEWS.SIGN_IN},
      params: {
        explicitlySignedOut: null,
        dest: null
      },
      resolve: {
        $uibModalInstance: /*@ngInject*/ function($state, $window, $timeout, $location) {
          return {
            close: function() {
              if ($state.params.dest) {
                $location.path($state.params.dest);
              } else {
                $state.go('app.home').then(function() {
                  checkForReload($window, $timeout);
                });
              }
            }
          };
        }
      },
      template: template,
      controllerAs: 'ctrl',
      controller: 'SignInCtrl'
    }).state('sign-in.masquerade', {
      url: '/masquerade',
      data: {public: true, view: VIEWS.MASQUERADE}
    }).state('sign-in.password-reset', {
      url: '/password-reset',
      data: {public: true, view: VIEWS.RESET_PASSWORD}
    });
  })

  .controller('SignInCtrl', function($scope, $timeout, adnAuthHelper, $translate, $sce, $document, $window, $rootScope, $location, $uibModalInstance, $state, AuthService, localUserProfile, LocalNetworkProfile, LocalUserPermissions) {
    const ctrl = this;
    ctrl.profile = localUserProfile.get();

    ctrl.view = _.get($state, 'current.data.view') || VIEWS.SIGN_IN;

    const regUrl = getRegisteredUrl($window);
    const theBgs = _.isArray(_.get(regUrl, 'bgs')) ? regUrl.bgs : BACKGROUNDS[ctrl.masquerade ? 'masquerade' : 'standard'];
    ctrl.background = theBgs[Math.floor(Math.random() * theBgs.length)];

    const debugInfo = LocalDebugInfo.obtain().getInfo();
    let masqDetails = {};
    if (debugInfo.lastMasqDate && moment(debugInfo.lastMasqDate).add(60, 'd').isAfter()) {
      ctrl.showMasqLink = true;
      if (debugInfo.masqLast) {
        masqDetails = _.pick(debugInfo, ['lastMasqueraderUsername', 'lastMasqUsername', 'masqLast']);
        // need to check href because VIEWS.SIGN_IN is the default here
        if (ctrl.view.id === 'SIGN_IN' && ($window.location.href.indexOf(SIGN_IN_ROUTER_STATE) > -1 || $state.params.explicitlySignedOut)) {
          $state.go('sign-in.masquerade');
        }
      }
    }

    if (regUrl) {
      const frontPageTranslationKey = regUrl.id + '.frontPage';
      ctrl.frontPageContent = $translate.instant(frontPageTranslationKey);
      if (frontPageTranslationKey === ctrl.frontPageContent) {
        ctrl.frontPageContent = "";
      }
      ctrl.frontPageContent = $sce.trustAsHtml(ctrl.frontPageContent);

      if (regUrl.favicon) {
        const favicon = $document[0].getElementById("favicon");
        favicon.href = regUrl.favicon.href;
      }
    }

    ctrl.credentials = {
      username: masqDetails.lastMasqueraderUsername ? masqDetails.lastMasqueraderUsername : ctrl.profile ? ctrl.profile.username : '',
      masqUsername: masqDetails.lastMasqueraderUsername ? masqDetails.lastMasqUsername : "",
      masqLast: masqDetails.masqLast,
      password: ''
    };

    ctrl.setForm = function(uiForm) {
      ctrl.signInForm = uiForm;
    };

    ctrl.releaseId = BUILD_INFO.gitSha.substring(0, 10);

    ctrl.error = null;

    const passwordUpdateCheck = function(theState) {
      if (theState.name !== 'sign-in.password-reset') {
        return;
      }
      const token = _.get($location.search(), 'token');
      if (!token) {
        return;
      }
      if (!_.isString(token) || token.length < 20) {
        ctrl.error = {text: "It looks like you clicked on an invalid or expired password reset link. Please try resetting your password again."};
        return;
      }
      ctrl.token = token;
      ctrl.view = VIEWS.PASSWORD_UPDATE;
    };
    passwordUpdateCheck($state.current);

    $scope.$on('$stateChangeSuccess', function(event, toState) {
      ctrl.error = null;
      ctrl.view = _.get(toState, 'data.view') || VIEWS.SIGN_IN;
      passwordUpdateCheck(toState);
    });

    const script = $document[0].createElement('script');
    script.setAttribute('src', 'current-build-info.js?cb=' + Uuid.generate());

    const bodyElement = angular.element($document[0]).find("body")[0];
    bodyElement.append(script);

    if (_.startsWith($location.host(), 'localhost') && !_.startsWith(ApiConfig.obtain().getId(), 'localhost')) {
      ctrl.env = ApiConfig.obtain().getId().split("_")[0].toUpperCase();
    }

    const doTheLogin = function(credentials, session, masqueradeAttempt) {
      adnAuthHelper.doAuth(ctrl.profile, credentials, masqueradeAttempt).then(function () {
        ctrl.isSigningIn = false;
        $uibModalInstance.close(session);
      });
    };

    ctrl.updatePassword = function(credentials, $event) {
      $event.preventDefault();

      if (!credentials.password || credentials.password.length < 8) {
        ctrl.error = {text: "Your password needs to be at least 8 characters long"};
        return;
      }

      if (credentials.password !== credentials.confirmPassword) {
        ctrl.error = {text: "The passwords you entered do not match. Ensure you've entered the same password both times."};
        return;
      }

      ctrl.isSigningIn = true;
      ctrl.error = null;
      AuthService.updatePassword(credentials.password, ctrl.token).then(function(session) {
        ctrl.isSigningIn = false;
        credentials.username = session.username;
        doTheLogin(credentials, session);
      }, function(err) {
        ctrl.isSigningIn = false;

        if (err.status === 401) {
          ctrl.view = VIEWS.RESET_PASSWORD;
          ctrl.error = {text: 'Your reset password link has expired. Enter your email address to generate another link.'};
          return;
        }

        const hasInvalidPassword = _.find(err.data.errors, function(e) {
          return e.error.code === 'validation.error.invalid.password';
        });
        if (err.status === 400 && hasInvalidPassword) {
          ctrl.error = {text: 'The supplied password is too easily guessed and should be something less common.'};
          return;
        }
        ctrl.error = err.data;
      });
    };

    ctrl.resetPassword = function(credentials, $event) {
      $event.preventDefault();

      if (!credentials.username || !EmailHelper.isEmail(credentials.username)) {
        ctrl.error = {text: "Your account's email address must be entered before its password can be reset."};
        return;
      }

      ctrl.isSigningIn = true;
      ctrl.error = null;
      AuthService.resetPassword(credentials.username).then(function() {
        ctrl.isSigningIn = false;
        ctrl.view = VIEWS.PASSWORD_HAS_RESET;

      }, function(err) {
        ctrl.isSigningIn = false;
        if (err.status === 429 || err.status === -1) {
          ctrl.view = VIEWS.PASSWORD_OVERLOAD;
          return;
        }
        ctrl.error = err.data;
      });
    };

    ctrl.needs2fa = {
      'broker1@adnuntius.com': true,
      'adnuntius@adnuntius.com': true,
      'oda@salgsfabrikken.no': true,
      'dirk@adnuntius.com': true
    };

    ctrl.cancel2fa = function() {
      ctrl.view = VIEWS.SIGN_IN;
    };

    ctrl.signIn2fa = function(credentials, $event) {
      $event.preventDefault();
      if (!credentials.twoFactorAuthCode || !credentials.twoFactorAuthCode.length) {
        ctrl.error = {text: "Supply a two-factor authentication code to sign in."};
        return;
      }

      ctrl.isSigningIn = true;
      ctrl.signInForm.$setValidity('finishedAuth');
      ctrl.error = null;

      AuthService.twoFactorAuth(credentials.username, credentials.twoFactorAuthCode).then(function(session) {
        ctrl.signInForm.$setValidity('finishedAuth', true);
        // load some user details for local profile storage
        doTheLogin(credentials, session, null);
      }, function(err) {
        ctrl.signInForm.$setValidity('finishedAuth', true);
        ctrl.isSigningIn = false;

        if (err.status === 400 && err.data.error === "account_locked") {
          ctrl.view = VIEWS.PASSWORD_HAS_RESET;
          ctrl.error = {text: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password and unlock your account.'};
          return;
        }

        $rootScope.$broadcast(AUTH_EVENT.loginFailed);
        ctrl.error = err.data;

        if (ctrl.error.code === 'error.auth.failed') {
          ctrl.error.code = 'error.auth.failed.2fa';
        }
      });
    };

    ctrl.signIn = function(credentials, $event, isMasq) {
      $event.preventDefault();

      if (!credentials.password || !credentials.password.length) {
        ctrl.error = {text: "Supply a password to sign in."};
        return;
      }

      if (!credentials.username || !EmailHelper.isEmail(credentials.username)) {
        ctrl.error = {text: "We need your account's email address before we can sign you in."};
        return;
      }

      const masqueradeAttempt = ctrl.view.masquerade || isMasq;

      if (masqueradeAttempt && credentials.masqUsername && (ctrl.needs2fa[credentials.masqUsername]) && !credentials.twoFactorAuthCode) {
        ctrl.error = {text: "Supply a 2FA code to masquerade as " + credentials.masqUsername};
        return;
      }

      ctrl.isSigningIn = true;
      ctrl.signInForm.$setValidity('finishedAuth');
      ctrl.error = null;
      AuthService.signIn(credentials.username, credentials.password, masqueradeAttempt ? credentials.masqUsername : "", credentials.twoFactorAuthCode).then(function(session) {
        ctrl.signInForm.$setValidity('finishedAuth', true);
        if (session.scope === 'TWO_FACTOR_AUTH') {
          ctrl.isSigningIn = false;
          ctrl.view = VIEWS.TWO_FACTOR_AUTH;
          $rootScope.$broadcast(AUTH_EVENT.login2faRequired);
        } else {
          // load some user details for local profile storage
          doTheLogin(credentials, session, masqueradeAttempt);
        }
      }, function(err) {
        ctrl.signInForm.$setValidity('finishedAuth', true);
        ctrl.isSigningIn = false;

        if (err.status === 400 && err.data.error === "account_locked") {
          ctrl.view = VIEWS.PASSWORD_HAS_RESET;
          ctrl.error = {text: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password and unlock your account.'};
          return;
        }

        $rootScope.$broadcast(AUTH_EVENT.loginFailed);
        ctrl.error = err.data;
      });
    };

    ctrl.forgotPasswordAndClearModal = function() {
      $uibModalInstance.close(false);
      $state.go('sign-in.password-reset', {}, {reload: true, inherit: false});
    };

    ctrl.goSignIn = function () {
      $uibModalInstance.close(false);
      $state.go('sign-in', {}, {reload: true, inherit: false});
    };

    ctrl.changeUser = function() {
      ctrl.profile = null;
      ctrl.credentials.username = null;
      ctrl.error = null;
      localUserProfile.destroy();
      LocalUserPermissions.destroy();
      LocalNetworkProfile.destroy();
    };

    $timeout(function() {
      $window.adn = $window.adn || {};
      $window.adn.calls = $window.adn.calls || [];
      $window.adn.calls.push(function() {
        $window.adn.request({
          env: 'production',
          adUnits: [{
            targetId: 'adnuntius-ad',
            auId: '000000000009a55e'
          }]
        });
      });
    }, 20);
  })

  .run(function($rootScope, $q, $state, $uibModal, $window, $timeout, $location, authService) {
    let modal = null;
    $rootScope.$on(AUTH_EVENT.notAuthenticated, function(event, toState, toParams) {
      if (modal) {
        // only open one modal
        return;
      }

      LocalDebugInfo.obtain().setInfo('expired', new Date());

      if (_.get($state, ['current', 'name']) === "" || $state.includes(HOME_STATE) || $state.includes(SIGN_IN_ROUTER_STATE) || $window.location.href.indexOf(SIGN_IN_ROUTER_STATE) > 0 || $window.location.href.indexOf("test-style") > 0) {
        $state.go('sign-in', {dest: $location.path()}, {inherit: false});
        return;
      }

      if (_.get($state, ['current', 'data', 'public'])) {
        // if a public page, don't do the modal thing
        return;
      }

      modal = $uibModal.open({
        template: modalTemplate,
        controller: 'SignInCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        keyboard: false,
        windowClass: ''
      });

      modal.result.then(function(session) {
        if (!session) {
          authService.loginCancelled();
          return;
        }
        authService.loginConfirmed(session);
        const allPromises = [];
        if (toState) {
          allPromises.push($state.go(toState, toParams));
        }
        $q.all(allPromises).then(function() {
          checkForReload($window, $timeout);
        });
      }).finally(function() {
        modal = null;
      });
    });
  });

export default MODULE_NAME;