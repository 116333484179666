import 'core-js/es/reflect';
import 'core-js/stable/reflect';
import 'core-js/features/reflect';

import 'zone.js';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {setAngularJSGlobal} from "@angular/upgrade/static";
import * as angular from 'angular';
import { AppModule } from './app.module';

setAngularJSGlobal(angular);
platformBrowserDynamic().bootstrapModule(AppModule);