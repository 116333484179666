/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import resources from '../../components/api/resources/resources';
import {getCountryEntry} from "../common/directives/country-select/countries";

const MODULE_NAME = 'search-coordinator';

angular.module(MODULE_NAME, [resources])

  .factory('searchCoordinator', function(searchResource, localUserProfile) {
    let props = {
      resolved: false,
      aggregations: [],
      searchResults: {
        items: []
      }
    };

    props.sync = function(params, searchMeta) {
      props.resolved = false;
      searchMeta = searchMeta || {};
      const searchParams = _.merge({}, params, searchMeta);
      searchMeta.resolved = false;
      searchParams.page = searchMeta.currentPage - 1 || 0;
      return searchResource.query(searchParams).then(function(data) {
        angular.copy(_.filter(data.aggregations, function(aggregation, name) {
          aggregation.name = name;

          if (aggregation.name === 'country') {
            _.forEach(aggregation.buckets, function(bucket) {
              bucket.name = (getCountryEntry(bucket.name) || {}).name || bucket.name;
            });
          }

          if (localUserProfile.get().app === 'DATA') {
            _.forEach(aggregation.buckets, function(bucket) {
              bucket.name = bucket.name === 'UserSegment' ? 'Segment' : bucket.name;
            });
          }
          return !_.isEmpty(aggregation.buckets);
        }), props.aggregations);

        let results = {
          items: data.searchResults,
          totalHits: data.totalHits,
          pageStart: data.pageStart,
          pageEnd: data.pageEnd
        };

        searchMeta.resolved = true;

        props.resolved = true;
        angular.copy(results, props.searchResults);
        return props;
      });
    };

    return props;
  });

export default MODULE_NAME;