/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import standardAnalysisModule from '../common/standard-analysis-module';
import {adnFormHelper} from "../common/controller/form-helper";
import _ from "lodash";
import refDataUploadDirective from '../admin/reference-data/reference-data-upload-directive';
import {Uuid} from "../../components/util/uuid";

const MODULE_NAME = "mapping-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  localNetworkProfile,
  refDataUploadDirective,
  localUserPermissions
])

  .controller('MappingCtrl', function(model, FieldMapping, VisitorProfileFields) {
    const ctrl = this;

    VisitorProfileFields.get().then(function(fields) {
      ctrl.fields = _.map(fields, function(f) {
        return {name: f.name, displayName: _.lowerCase(f.name)};
      });
    });

    function checkMappingTypes() {
      ctrl.hasCategoryMapping = _.find(ctrl.model.vmMappings, function(mapping) {
        return mapping.field === 'CATEGORY';
      });
      ctrl.hasKeywordMapping = _.find(ctrl.model.vmMappings, function(mapping) {
        return mapping.field === 'KEYWORD';
      });
    }

    const vmMap = function() {
      if (!ctrl.model) {
        return;
      }
      ctrl.model.vmMappings = _.map(_.get(ctrl.model, 'mappings') || [], function(mapping, key) {
        const transforms = _.map(mapping.transformations, function(val, key) {
          return {
            key: key,
            value: val
          };
        });
        return {
          field: key,
          aliases: mapping.aliases.join(", "),
          transformations: transforms.length > 0 ? transforms : [{key: '', value: ''}]
        };
      });
      checkMappingTypes();
    };

    ctrl.isLoading = true;
    function setUpForm() {
      adnFormHelper.setUpForm(ctrl, model, {
        afterSave: function(resource) {
          ctrl.model.mappings = resource.mappings;
          vmMap();
        },
        afterInit: function() {
          vmMap();
          ctrl.isLoading = false;
        }
      });
      // ctrl.localForm is set in the HTML
      ctrl.setForm(ctrl.localForm);
    }

    FieldMapping.query().$promise.then(function(data) {
      if (_.get(data, 'results.length')) {
        model = _.head(data.results);
        setUpForm();
        return;
      }
      FieldMapping.create({
        id: Uuid.generate(),
        name: 'Default mapping name'
      }).savePartial().then(function(persistedMapping) {
        model = persistedMapping;
        setUpForm();
      });
    });

    const newEntry = function(type) {
      return {
        field: type || '',
        aliases: '',
        transformations: [{key: '', value: ''}],
        edited: true
      };
    };

    ctrl.uploadHook = function() {
      ctrl.isLoading = true;
      FieldMapping.query().$promise.then(function(data) {
        ctrl.saveDirect(_.head(data.results));
        ctrl.isLoading = false;
      });
    };

    function sanitiseVm() {
      const mappings = {};
      _.forEach(ctrl.model.vmMappings, function(vmMap) {
        if (!vmMap.field) {
          return;
        }
        const transformations = {};
        _.forEach(vmMap.transformations, function(t) {
          if (_.trim(_.get(t, 'key')).length > 0 && _.trim(_.get(t, 'value')).length > 0) {
            transformations[t.key] = t.value;
          }
        });
        mappings[vmMap.field] = {
          name: vmMap.deleted ? "" : vmMap.name,
          aliases: !vmMap.deleted && _.trim(vmMap.aliases).length > 0 ? _.map(vmMap.aliases.split(","), function(val) {
            return _.trim(val);
          }) : [],
          transformations: vmMap.deleted ? {} : transformations
        };
      });
      return mappings;
    }


    ctrl.headers = ['Field', 'Alias', 'Transformations'];
    ctrl.columnOrders = ['field', 'alias', 'transformations'];

    ctrl.getRows = function() {
      const data = sanitiseVm();

      const rows = [];
      _.forEach(data, function(val, key) {
        if (key === 'KEYWORD') {
          _.forEach(val.transformations, function(tVal, tKey) {
            rows.push({field: "KEYWORD", alias: "", transformations: tKey + "=" + tVal});
          });
        } else if (key === 'CATEGORY') {
          _.forEach(val.transformations, function(tVal, tKey) {
            rows.push({field: "CATEGORY", alias: "", transformations: tKey + "=" + tVal});
          });
        } else {
          const mapping = {field: key, alias: val.aliases.join(";")};
          mapping.transformations = _.map(val.transformations, function(tVal, tKey) {
            return tKey + "=" + tVal;
          }).join(";");
          rows.push(mapping);
        }
      });

      return rows;
    };

    ctrl.getExampleRows = function() {
      const rows = [];
      rows.push({
        field: 'company',
        alias: 'co;com;business',
        transformations: 'General Motors=GM;Alphabet=Google;AMA=Amazon'
      });
      rows.push({
        field: 'city',
        alias: 'cy;town;village',
        transformations: 'Los Angeles=LA;New York=NY;San Francisco=SF'
      });
      rows.push({
        field: 'KEYWORD',
        alias: '',
        transformations: 'golf=sport'
      });
      rows.push({
        field: 'KEYWORD',
        alias: '',
        transformations: 'tennis=sport'
      });
      rows.push({
        field: 'CATEGORY',
        alias: '',
        transformations: 'pizza=food'
      });
      rows.push({
        field: 'CATEGORY',
        alias: '',
        transformations: 'curry=food'
      });
      return rows;
    };

    ctrl.addCriteria = function(parentIndex) {
      ctrl.model.vmMappings[parentIndex] = ctrl.model.vmMappings[parentIndex] || newEntry();
      ctrl.model.vmMappings[parentIndex].transformations.push({key: '', value: ''});
      ctrl.editsMade();
    };
    ctrl.eventHook = {};

    ctrl.removeCriteria = function(parentIndex, index) {
      _.pullAt(ctrl.model.vmMappings[parentIndex].transformations, index);
      ctrl.eventHook.removeControlHook('transform[' + parentIndex + '-' + index + ']');
      ctrl.editsMade();
    };

    ctrl.toggleEditMapping = function(index) {
      _.forEach(ctrl.model.vmMappings, function(m, i) {
        m.edited = i === index ? !m.edited : false;
      });
      ctrl.editsMade();
    };

    ctrl.removeMapping = function(mapping) {
      ctrl.editsMade();
      mapping.edited = false;
      mapping.deleted = true;
      checkMappingTypes();
    };

    ctrl.addMapping = function(field) {
      ctrl.editsMade();
      ctrl.model.vmMappings = ctrl.model.vmMappings || [];
      ctrl.model.vmMappings.unshift(newEntry(field));
      checkMappingTypes();
    };

    ctrl.deleteMapping = function(mapping) {
      ctrl.editsMade();
      mapping.deleted = true;
    };

    ctrl.onBeforeSubmit = function() {
      ctrl.model.mappings = sanitiseVm();
    };

  });

export default MODULE_NAME;