/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Inject} from '@angular/core';
import {Resource} from "./resource";

export class TierResource extends Resource {
  constructor(@Inject('adnResource') adnResource) {
    super(adnResource,  'tiers');
  }
}

