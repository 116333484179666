/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './ruleset-edit.html';
import listTemplate from './ruleset-list.html';

import standardModule from '../common/standard-module';
import siteController from './ruleset-controller';
import _ from "lodash";

const MODULE_NAME = 'ruleset-module';

angular.module(MODULE_NAME, [standardModule, siteController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('rulesets.ruleset', 'Ruleset', null, 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(localUserProfile) {
          return {
            func: function(ctrl) {
              ctrl.fields = ['name', 'description', 'type', 'floorPrice', {label: 'advertiserExceptions', param: ''}];
              let username = localUserProfile.get("username");
              if (_.includes(["adnuntius@adnuntius.com", "broker1@adnuntius.com"], username)) {
                ctrl.fields.push('team');
              }
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'RulesetCtrl',
            controllerAs: 'resource'
          }
        }

      }, 'Ruleset', []));
  });

export default MODULE_NAME;