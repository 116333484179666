/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './form-control-validation.html';

const MODULE_NAME = 'form-control-validation-directive';

angular.module(MODULE_NAME, [])

  .directive('adnFormControlValidation', function() {
    return {
      restrict: 'A',
      scope: {
        parameters: "=adnFormControlValidation"
      },
      template: template,
      transclude: true,
      controller: function($scope) {
        $scope.formControls = [];

        this.setFormControl = function(theControl) {
          const pattern = _.get(theControl, ['$$attr', 'pattern']);
          if (pattern === "[0-9]{0,20}") {
            theControl.keyOverride = "patternInteger";
          }
          if (pattern === "[0-9.]{0,20}") {
            theControl.keyOverride = "patternDecimal";
          }
          $scope.formControls.push(theControl);
          return this;
        };
      }
    };
  });

export default MODULE_NAME;
