/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import iabSearchSelect from '../common/controller/iab-multi-select-component';
import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";

const MODULE_NAME = 'first-party-audience-controller';

angular.module(MODULE_NAME, [standardFormModule, iabSearchSelect])

  .controller('FirstPartyAudienceCtrl', function(model, modelComms, EarningsAccount) {
    const ctrl = this;

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInit: function() {
      }, afterSave: function() {
      }, afterInitAfterSave: function() {
      }
    });

    EarningsAccount.query({minimal: true}, function(page) {
      ctrl.earningsAccounts = page.results;

      if (ctrl.model.earningsAccount && ctrl.model.earningsAccount.id) {
        ctrl.model.earningsAccount = _.find(ctrl.earningsAccounts, ['id', ctrl.model.earningsAccount.id]);
      }
    });

    ctrl.onBeforeSubmit = function() {
      if (_.get(ctrl.model, ['cpm', 'amount']) === null) {
        ctrl.model.cpm = null;
      }
    };
  });

export default MODULE_NAME;