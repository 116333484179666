/**
 * Copyright © 2024 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

export class DefaultEmailTranslationsResource {
  private readonly uri: string = ApiConfig.obtain().getVersionedUri('emailtranslationdefaults');

  defaultEmailTranslations: any;

  constructor(@Inject('$http') private $http: any, @Inject('$q') private $q: any) {
  }

  get() {
    return this.defaultEmailTranslations ? this.$q.resolve(this.defaultEmailTranslations) : this.$http.get(this.uri, {
      params: {context: null}
    }).then((info) => {
      this.defaultEmailTranslations = info.data.results;
      return this.defaultEmailTranslations;
    });
  }
}
