import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UpgradeModule} from '@angular/upgrade/static';
import {BaseAppModule} from "./app.module.ajs";
import {ADN_TRANSLATIONS} from "./common/translations";
import {FormsModule} from "@angular/forms";

import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {LineItemSmoothingLabelComponent} from "./line-item/smoothing-label-component";
import {LineItemDiagnosticLabelComponent} from "./line-item/diagnostic-label-component";
import {LineItemSourceLabelComponent} from "./line-item/line-item-source-component";
import {LineItemStatusLabelComponent} from "./line-item/status-component";
import {FirstPartyAudiencePublishStateLabelComponent} from "./first-party-audience/publish-state-component";
import {LineItemStatusExplanationLabelComponent} from "./line-item/status-explanation-component";
import {PaymentStatusLabelComponent} from "./payment/payment-status-component";
import {EnvBannerComponent} from "./common/directives/banners/env-banner-component";
import {CreativeUserStatesComponent} from "./common/directives/creative-user-states";
import {UndoDeleteComponent} from "./common/directives/html/undo-delete-component";
import {CountrySelectComponent} from "./common/directives/country-select/country-select";
import {ReportStatusComponent} from "./report/report-status-component";
import {FormSubmissionMessage} from "./common/directives/form-controls/form-submission-message";
import { chartOptionsUtilProvider, localNetworkProfileProvider, localUserPermissionsProvider, httpServiceProvider, interpolateServiceProvider, localeProvider, localUserProfileProvider, logServiceProvider, qServiceProvider, resourceProvider, translateServiceProvider } from "./ajs-upgraded-providers";
import {InfoIconComponent} from "./common/directives/html/info-component";
import {PopoverModule} from "ngx-bootstrap/popover";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {ValidationInfoComponent} from "./line-item/validation-info-component";
import {LineItemExecutionLabelComponent} from "./line-item/execution-component";
import {AdStatusLabelComponent} from "./ad-unit/ad-status-component";
import {AdvertiserStatusLabelComponent} from "./advertiser/advertiser-status-component";
import {KeywordFrequencyComponent} from "./targeting/targeting/keyword-frequency-component";
import {CouponValidityComponent} from "./admin/coupon/coupon-validity-component";
import {CouponStatusComponent} from "./admin/coupon/coupon-status-component";
import {CopyrightStatusComponent} from "./line-item/copyright-status-component";
import {CreativeStatusLabelComponent} from "./line-item/creative-status-component";
import {PerfTotalComponent} from "./common/directives/perf-total-component";
import {StatPipe} from "./common/filters/stat.pipe";
import {CurrencyPipe, DecimalPipe, KeyValuePipe, PercentPipe} from "@angular/common";
import {OgTranslatePipe} from "./common/filters/og-translate.pipe";
import {SafeHtmlPipe} from "./common/filters/safe-html.pipe";
import {AdTagComponent} from "./ad-unit/ad-tag-component";
import {LocalUserProfile} from "../components/session/local-user-profile";
import {MessageDismisserComponent} from "./common/directives/form-controls/message-dismisser.component";
import {AssetRenderComponent} from "./common/directives/html/asset-render-component";
import {ResourcesModule} from "../components/api/resources/resources-module";
import {ValidationWarningsComponent} from "./common/directives/error/validation-warnings-component";
import {SimpleValidationMessagesComponent} from "./common/directives/error/simple-validation-messages-component";
import {SimpleErrorMessagesComponent} from "./common/directives/error/simple-error-messages-component";
import {LayoutComponentSummaryComponent} from "./common/layout-component-summary-component";
import {TranscodeConfigComponent} from "./common/directives/transcode-config-component";
import {GuardTypePipe} from "./common/filters/guard-type.pipe";
import {AssetTranscodeComponent} from "./common/directives/html/asset-transcode-component";
import {MimeTypeKeyPipe} from "./common/filters/mime-type-key.pipe";

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
    TranslateModule.forRoot(),
    FormsModule,
    PopoverModule.forRoot(),
    ButtonsModule.forRoot(),
    ResourcesModule
  ],
  declarations: [
    LineItemSmoothingLabelComponent,
    LineItemDiagnosticLabelComponent,
    FirstPartyAudiencePublishStateLabelComponent,
    LineItemStatusLabelComponent,
    LineItemStatusExplanationLabelComponent,
    AdStatusLabelComponent,
    AdvertiserStatusLabelComponent,
    PaymentStatusLabelComponent,
    LineItemSourceLabelComponent,
    LineItemExecutionLabelComponent,
    KeywordFrequencyComponent,
    CouponValidityComponent,
    CreativeUserStatesComponent,
    CouponStatusComponent,
    CreativeStatusLabelComponent,
    CopyrightStatusComponent,
    LayoutComponentSummaryComponent,
    ValidationWarningsComponent,
    SimpleValidationMessagesComponent,
    SimpleErrorMessagesComponent,
    EnvBannerComponent,
    UndoDeleteComponent,
    InfoIconComponent,
    CountrySelectComponent,
    ReportStatusComponent,
    ValidationInfoComponent,
    PerfTotalComponent,
    AdTagComponent,
    AssetRenderComponent,
    MessageDismisserComponent,
    StatPipe,
    OgTranslatePipe,
    SafeHtmlPipe,
    FormSubmissionMessage,
    TranscodeConfigComponent,
    GuardTypePipe,
    AssetTranscodeComponent,
    MimeTypeKeyPipe
  ],
  entryComponents: [
    LineItemSmoothingLabelComponent,
    LineItemDiagnosticLabelComponent,
    FirstPartyAudiencePublishStateLabelComponent,
    LineItemStatusLabelComponent,
    LineItemStatusExplanationLabelComponent,
    PaymentStatusLabelComponent,
    LineItemSourceLabelComponent,
    LineItemExecutionLabelComponent,
    AdStatusLabelComponent,
    AdvertiserStatusLabelComponent,
    KeywordFrequencyComponent,
    CouponValidityComponent,
    CouponStatusComponent,
    CopyrightStatusComponent,
    CreativeStatusLabelComponent,
    LayoutComponentSummaryComponent,
    SimpleValidationMessagesComponent,
    SimpleErrorMessagesComponent,
    ValidationWarningsComponent,
    EnvBannerComponent,
    InfoIconComponent,
    CreativeUserStatesComponent,
    AssetRenderComponent,
    UndoDeleteComponent,
    PerfTotalComponent,
    CountrySelectComponent,
    ReportStatusComponent,
    ValidationInfoComponent,
    MessageDismisserComponent,
    AdTagComponent,
    FormSubmissionMessage,
    TranscodeConfigComponent,
    AssetTranscodeComponent
  ],
  providers: [
    LocalUserProfile,
    localUserProfileProvider,
    httpServiceProvider,
    qServiceProvider,
    logServiceProvider,
    interpolateServiceProvider,
    chartOptionsUtilProvider,
    localNetworkProfileProvider,
    localUserPermissionsProvider,
    translateServiceProvider,
    localeProvider,
    resourceProvider,
    PercentPipe,
    DecimalPipe,
    CurrencyPipe,
    OgTranslatePipe,
    SafeHtmlPipe,
    StatPipe,
    KeyValuePipe,
    GuardTypePipe,
    MimeTypeKeyPipe
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule, private readonly translate: TranslateService) {
  }

  ngDoBootstrap(): void {
    this.translate.setDefaultLang("en");
    this.translate.setTranslation("en", ADN_TRANSLATIONS);
    this.upgrade.bootstrap(document.documentElement, [BaseAppModule]);
  }
}