/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import listTemplate from './ax-review-list.html';

import standardModule from '../common/standard-module';
import targetingModule from '../targeting/targeting-module';
import _ from "lodash";
import {reviewBulkAction} from "./review-commons";
import {campaignCommons} from "../campaign/campaign-commons";

const MODULE_NAME = 'axReview-module';

angular.module(MODULE_NAME, [standardModule, targetingModule])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('ax-reviews.ax-review', 'LineItemReview', 'app.adv', 'app', listTemplate);

    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        bulk: function() {
          return {
            reject: reviewBulkAction,
            approve: function(ctrl, $resources, resources) {
              const LineItem = resources.LineItem;

              return function() {
                const bulkSaveLineItems = _.map(ctrl.bulkSelection, function(val) {
                  return {id: val.lineItem.id, userState: 'APPROVED'};
                });
                ctrl.miscBulkAction(null, ctrl, bulkSaveLineItems, LineItem);
                ctrl.deselectAll();
              };
            }
          };
        },
        listConfig: function() {
          return {pageSize: 5, moreParams: {excludeIfMissing: 'axProductId', filterBy: 'userState', filterByValue: 'SUBMITTED'}};
        },
        extension: /*@ngInject*/ function(LocalNetworkProfile) {
          return {
            func: function(ctrl) {
              campaignCommons(ctrl).setUpComponents(['items']);
              ctrl.hasCreativeCopyright = LocalNetworkProfile.isCreativeCopyrightStatus();
            }
          };
        }
      },
      views: {
        'context@app': {
          template: null,
          controller: null
        }
      }
    }, "LineItemReview", [{type: 'Advertiser', func: 'isNotSelfService'}, {type: 'Order', func: 'isNotSelfService'}]));
  });

export default MODULE_NAME;