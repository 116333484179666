/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './advertiser-earnings-list.html';

import standardFormModule from '../../common/standard-form-module';
import lineItemListSummaryDir from '../../line-item/line-item-list-summary-directive';

const MODULE_NAME = "advertiser-earnings-directive";

angular.module(MODULE_NAME, [standardFormModule, lineItemListSummaryDir])

  .directive('adnAdvertiserEarningsList', function(statsResource, ChartOptionsUtil) {
    return {
      restrict: 'A',
      scope: {},
      template: template,
      bindToController: {
        widgetType: '<adnAdvertiserEarningsList'
      },
      controllerAs: 'widget',
      controller: function() {
        const ctrl = this;
        ctrl.$onInit = function() {
          if (!_.get(ctrl.widgetType, ['obj', 'id'])) {
            return;
          }
          const chartOptions = ChartOptionsUtil.getOptions("ADVERTISER");
          ctrl.tableFields = _.filter(chartOptions.totalsFields, function(field) {
            return ctrl.widgetType.fields.indexOf(field.id) > -1;
          });
          ctrl.obj = ctrl.widgetType.obj;
          ctrl.fieldComparisons = ctrl.widgetType.fieldComparisons;
          ctrl.childParam = 'earningsAccount';
          const params = {};
          params.aggregateTo = "ADVERTISER";
          params.groupBy = "EARNINGS_ACCOUNT";
          params.includeAll = false;
          params.advertiserId = ctrl.obj.id;
          if (_.isArray(ctrl.widgetType.objMulti) && ctrl.widgetType.objMulti.length > 0) {
            params.earningsAccountId = _.join(_.map(ctrl.widgetType.objMulti, function(om) {
              return om.id;
            }), ";");
          }
          params.includeChunkRange = true;
          params.startDate = ctrl.widgetType.startDate;
          params.endDate = ctrl.widgetType.endDate;

          ctrl.isDone = false;
          ctrl.hasError = false;
          statsResource.get(params, function(data) {
            ctrl.rows = data.chunks;
            ctrl.totals = data.totals;
            ctrl.isDone = true;
          }, function() {
            ctrl.hasError = true;
            ctrl.isDone = true;
          });
        };
      }
    };
  });

export default MODULE_NAME;