/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Inject, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {LINE_ITEM_EXECUTION_STATE} from "../../components/api/resources/resources-constants";
import {interpolateWarning} from '../common/directives/error/interpolate-warnings-filter';


import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'line-item-execution-label',
  template: `
    <span class="unbrokenText"><span *ngIf="!executionStateEndedReason"
          class="label {{labelClass[executionState]}}">{{ "lineItem.executionState." + executionState | translate }}</span>
    <span *ngIf="executionStateEndedReason && (deliveryPercentage || deliveryPercentage === 0)"
          class="label {{deliveryHealthLabel(deliveryPercentage)}}">{{ "lineItem.executionStateEndedReason." + executionStateEndedReason | translate }}: {{ deliveryPercentage }}%</span>
    <span *ngIf="executionStateEndedReason && !deliveryPercentage && deliveryPercentage !== 0"
          class="label {{labelClass[executionState]}}">{{ "lineItem.executionStateEndedReason." + executionStateEndedReason | translate }}</span>
    <info-icon
      *ngIf="warningsOutput && warnings.length > 0" [text]="warningsOutput" placement="bottom"></info-icon></span>
  `
})
export class LineItemExecutionLabelComponent implements OnInit {
  labelClass: any;
  deliveryHealthLabel: any;
  translation: string;
  warningsOutput: string;
  @Input() executionState: string;
  @Input() executionStateEndedReason: string;
  @Input() deliveryPercentage: number;
  @Input() objectives: any;
  @Input() warnings: any;
  @Input() showInfo: boolean;

  constructor(@Inject('$interpolate') private $interpolate: any, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass[LINE_ITEM_EXECUTION_STATE.notReady] = 'label-danger';
    this.labelClass[LINE_ITEM_EXECUTION_STATE.ready] = 'label-warning';
    this.labelClass[LINE_ITEM_EXECUTION_STATE.pausedOrReserved] = 'label-warning';
    this.labelClass[LINE_ITEM_EXECUTION_STATE.running] = 'label-success';
    this.labelClass[LINE_ITEM_EXECUTION_STATE.ended] = 'label-info';

    this.deliveryHealthLabel = function (deliveryPercentage) {
      if (deliveryPercentage < 60) {
        return 'label-danger';
      } else if (deliveryPercentage < 95) {
        return 'label-warning';
      } else {
        return 'label-info';
      }
    };

    if (this.showInfo) {
      this.warningsOutput = "<ul>";
      _.forEach(this.warnings, (warning) => {
        this.warningsOutput += "<li>" + interpolateWarning(this.$interpolate, warning) + "</li>";
      });
      this.warningsOutput += "</ul>";
    }
  }
}
