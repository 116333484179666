/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './user-edit.html';
import copyTemplate from '../../common/copy-actions.html';
import listTemplate from './user-list.html';

import adminModule from '../admin-module';
import userController from './user-controller';
import userActionsController from './user-actions-controller';
import {PERMISSIONS} from "../../../components/auth/auth-constants";

const MODULE_NAME = 'user-module';

angular.module(MODULE_NAME, [adminModule, userController, userActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('users.user', 'User', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      data: {
        app: ['ADVERTISING', 'DATA']
      },
      resolve: {
        orderByParam: function() {
          return 'username';
        },
        readOnly: /*@ngInject*/ function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageSystem.apiKey)
            && !LocalUserPermissions.hasDataNetworkPermission(PERMISSIONS.dataAdmin.apiKey)
            && !LocalUserPermissions.hasNetwork(PERMISSIONS.axPublisherAdmin.apiKey)
            && !LocalUserPermissions.hasNetwork(PERMISSIONS.axAdvertiserAdmin.apiKey)
            && !LocalUserPermissions.hasNetwork(PERMISSIONS.selfServiceUserAdmin.apiKey);
        },
        extension: /*@ngInject*/ function(localUserProfile, LocalNetworkProfile, LocalUserPermissions) {
          return {
            func: function(ctrl) {
              ctrl.currentUser = localUserProfile.get('username');

              const networkId = LocalNetworkProfile.getNetworkId();
              _.forEach(ctrl.items, function(item) {
                item.inNetwork = LocalUserPermissions.userPartOf(item, networkId);
                item.obfuscated = item.username.indexOf("@") < 0 && item.username.indexOf("*") > 0;
              });
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        url: '/user/:id?username',
        data: {
          app: ['ADVERTISING', 'DATA']
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'UserCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'UserActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'User'));
  });

export default MODULE_NAME;