import angular from 'angular';

import editTemplate from './segment-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './segment-list.html';

import standardModule from '../common/standard-module';
import segmentController from './segment-controller';
import segmentActionsController from './segment-actions-controller';

const MODULE_NAME = 'segment-module';

angular.module(MODULE_NAME, [standardModule, segmentController, segmentActionsController])
  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('segments.segment', 'Segment', 'app.dat-seg', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'SegmentCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'SegmentActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Segment'));
  });

export default MODULE_NAME;
