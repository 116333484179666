/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../common/constants/object-type";

import editTemplate from './workspace-edit.html';
import viewTemplate from './workspace-view.html';
import listActionsTemplate from '../common/list-default-actions.html';
import defaultTitleTemplate from '../base/default-title-template.html';
import defaultItemTitleTemplate from '../base/default-item-title.html';
import resourceSidebarActionsTemplate from '../base/resource-sidebar-actions.html';
import listTemplate from './workspace-list.html';

import standardModule from '../common/standard-module';
import workspaceController from './workspace-controller';
import workspaceListController from './workspace-list';
import lineItemListDirective from './widget/line-item-list-directive';
import externalIframeDirective from './widget/external-iframe-directive';
import statsWidgetDirective from './widget/stats-widget-directive';
import treeMapWidgetDirective from './widget/tree-map-widget-directive';
import barChartWidgetDirective from './widget/bar-chart-widget-directive';
import orderListDirective from './widget/order-list-directive';
import advertiserEarningsDirective from './widget/advertiser-earnings-directive';
import htmlInsertionDirective from './widget/html-insertion-directive';
import {ModelComms} from "../common/controller/model-comms";

const MODULE_NAME = 'workspace-module';

angular.module(MODULE_NAME, [standardModule, externalIframeDirective, htmlInsertionDirective, workspaceController, workspaceListController, barChartWidgetDirective, lineItemListDirective, statsWidgetDirective, treeMapWidgetDirective, orderListDirective, advertiserEarningsDirective])

  .config(function($stateProvider) {
    let titleObj = {
      template: defaultItemTitleTemplate,
      controllerAs: 'title',
      controller: /*@ngInject*/ function(workspace, modelComms) {
        const ctrl = this;
        ctrl.objectType = OBJECT_TYPE.Workspace;
        ctrl.itemName = workspace.name || 'New';

        modelComms.addSubscriber(function(data, type) {
          if (type) {
            return;
          }
          ctrl.itemName = data.name || 'New';
        });
      }
    };

    $stateProvider.state('app.workspaces', {
      url: '/workspace',
      parent: 'app.wor',
      resolve: {
        ResourceFactory: function($injector) {
          return $injector.get('Workspace');
        },
      },
      views: {
        "content@app": {
          controller: 'WorkspaceListCtrl',
          controllerAs: 'list',
          template: listTemplate
        },
        "context@app": {
          template: listActionsTemplate
        },
        'title@app': {
          template: defaultTitleTemplate
        }
      },
      data: {
        title: 'Dashboards'
      }
    }).state('app.workspaces.new', {
      url: '/new',
      resolve: {
        workspace: /*@ngInject*/ function(Workspace) {
          return new Workspace();
        },
        modelComms: /*@ngInject*/ function() {
          return new ModelComms();
        }
      },
      views: {
        "content@app": {
          controller: 'WorkspaceCtrl',
          controllerAs: 'resource',
          template: editTemplate
        },
        "context@app": {},
        'title@app': titleObj
      },
      data: {
        editable: true,
        title: 'New Dashboard'
      }
    }).state('app.workspaces.workspace', {
      url: '/:id',
      resolve: {
        workspace: /*@ngInject*/ function($stateParams, Workspace) {
          return Workspace.get({id: $stateParams.id}).$promise;
        },
        modelComms: /*@ngInject*/ function() {
          return new ModelComms();
        }
      },
      views: {
        "content@app": {
          controller: 'WorkspaceCtrl',
          controllerAs: 'resource',
          template: viewTemplate
        },
        'context@app': {
          template: resourceSidebarActionsTemplate,
          controllerAs: 'ctrl',
          controller: /*@ngInject*/ function(workspace, LocalUserPermissions) {
            const ctrl = this;
            ctrl.readOnly = !LocalUserPermissions.hasNetworkPermission("MANAGE_NETWORK_DASHBOARDS") && workspace.type !== 'PERSONAL';
          }
        },
        'title@app': titleObj
      },
      data: {
        editable: false,
        title: '{{workspace.name}}'
      }
    }).state('app.workspaces.workspace.edit', {
      url: '/edit',
      views: {
        "content@app": {
          controller: 'WorkspaceCtrl',
          controllerAs: 'resource',
          template: editTemplate
        },
        'context@app': {},
        'title@app': titleObj
      },
      data: {
        editable: true,
        title: 'Dashboard'
      }
    });
  });

export default MODULE_NAME;