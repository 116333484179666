/**
 * Copyright © 2024 Adnuntius AS.
 */
import {TranslateService} from '@ngx-translate/core';
import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'line-item-diagnostic-label',
  template: `<span class="label {{labelClass}}">{{ info }}</span>`
})
export class LineItemDiagnosticLabelComponent implements OnInit {
  info: string;
  labelClass: string;
  @Input() delivered: number;
  @Input() required: number;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    const deliveredAmount = _.get(this.delivered, 'amount') || this.delivered;
    const requiredAmount = _.get(this.required, 'amount') || this.required;
    if (deliveredAmount > 0.95 * requiredAmount) {
      this.info = "Healthy";
      this.labelClass = "label-default";
    } else {
      this.info = "Under Delivering";
      this.labelClass = "label-warning";
    }
  }
}
