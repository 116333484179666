/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './layout-include-copy-modal.html';

import {Uuid} from "../../../components/util/uuid";

import standardActionsModule from '../../common/standard-actions-module';

const MODULE_NAME = "layout-include-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule
])
  .controller('LayoutIncludeActionsCtrl', function($q, toastr, $state, $stateParams, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        template: template,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.layoutInclude = data;
              modalCtrl.layoutInclude.name = data.name + " (copy)";
              modalCtrl.layoutInclude.reference = data.reference + "_copy";
              modalCtrl.layoutInclude.description = data.description;
            }
          });

          modalCtrl.destination = true;

          modalCtrl.copy = function() {
            modalCtrl.layoutInclude.id = Uuid.generate();
            modalCtrl.layoutInclude.$save().then(function(savedLayoutInclude) {
              modalInstance.close({layoutInclude: savedLayoutInclude, destination: modalCtrl.destination});
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        if (result.destination) {
          $q.all(result.allPromises).then(function() {
            $state.go('app.layout-includes.layout-include', {id: result.layoutInclude.id}, {inherit: false});
          });
        } else {
          toastr.success("A copy of this layout include was successfully created.", "Layout Include Copied");
        }
      });
    };
  });

export default MODULE_NAME;