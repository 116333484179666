export const zendeskPrefill = function($window, $interval, localUserProfile, LocalNetworkProfile) {
  let zendeskTimer;
  var zendeskUserSetup = function() {
    if (!$window.zE) {
      return;
    }

    const networkName = LocalNetworkProfile.get('networkName');
    const userName = localUserProfile.get("displayName") || localUserProfile.get("name");
    const name = userName ? userName + (networkName ? " (" + networkName + ")" : "") : "";
    const email = localUserProfile.get("username");
    $window.zE('webWidget', 'identify', {
      name: name,
      email: email,
      organization: LocalNetworkProfile.getNetworkId()
    });
    $window.zE('webWidget', 'prefill', {
      name: {value: name},
      email: {value: email}
    });
    $interval.cancel(zendeskTimer);
  };
  zendeskTimer = $interval(zendeskUserSetup, 500, 20);
};