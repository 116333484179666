import {Pipe, PipeTransform} from "@angular/core";
import {MediaTypeHelper} from "../../../components/util/media-type-helper";


@Pipe({
  name: 'mimeTypeKey'
})
export class MimeTypeKeyPipe implements PipeTransform {
  transform(mimeType: string): string {
      const mediaType = MediaTypeHelper.getMediaType(mimeType);
      return mediaType ? 'media.' + mediaType.id : '';
  }
}