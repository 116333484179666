/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './reference-data-edit.html';
import titleTemplate from '../../base/default-title-template.html';

import adminModule from '../admin-module';
import referenceDataCtrl from './reference-data-controller';

const MODULE_NAME = 'reference-data-module';

angular.module(MODULE_NAME, [adminModule, referenceDataCtrl])

  .config(function($stateProvider) {
    $stateProvider.state('app.reference-data', {
      url: '/reference-data',
      parent: 'app.adm',
      resolve: {
        ResourceFactory: function(Network) {
          return Network;
        },
        network: function(LocalNetworkProfile, Network) {
          return Network.get({id: LocalNetworkProfile.getNetworkId()}).$promise;
        },
        networkProfile: function(LocalNetworkProfile, NetworkProfile) {
          return NetworkProfile.get({id: LocalNetworkProfile.getNetworkId()}).$promise;
        }
      },
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'ReferenceDataCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Reference Data"
      }
    });
  });

export default MODULE_NAME;