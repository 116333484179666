/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as angular from 'angular';
import { OBJECT_TYPE } from "../common/constants/object-type";

import listTitleTemplate from '../base/default-title-template.html';

import listTemplate from '../payment/payment-list.html';
import * as _ from 'lodash';

import resourceListController from '../common/controller/resource-list-controller';
import standardModule from '../common/standard-module';
import {Downloader} from "../../components/util/downloader";

const MODULE_NAME = 'payment-module';

angular.module(MODULE_NAME, [
  standardModule,
  resourceListController
])

  .config(function($stateProvider) {
    $stateProvider.state('app.payments', {
      url: '/payments',
      parent: 'app.rep',
      resolve: {
        ResourceFactory: function(Payment) {
          return Payment;
        },
        ChildResourceFactory: function() {
        },
        listConfig: function() {
        },
        orderByParam: function() {
          return 'DESCcreateTime';
        },
        objectType: function() {
          return OBJECT_TYPE.Payment;
        },
        sectionParam: function() {
        },
        extension: /*@ngInject*/ function($document, $timeout, $filter) {
          return {
            func: function (ctrl) {
              const baseTitle = "payments";
              ctrl.csvTableTitle = baseTitle + ".csv";

              ctrl.headingFields = ['Payment Time', 'Payment Service', 'Status', 'Status Date', 'Budgeted Amount', 'Billed Amount', 'Refunded Amount', 'Receipt URL', 'Coupon', 'Coupon ID', 'Coupon Amount', 'Line Item', 'Line Item ID'];
              ctrl.columns = ['createTime', 'provider', 'status', 'statusTimestamp', 'amount', 'billedAmount', 'refundedAmount', 'receiptUrl', 'appliedCouponName', 'appliedCouponId', 'couponDiscountAmount', 'lineItemName', 'lineItemId'];
              const adjustmentColumns = {
                appliedCouponId: ['appliedCoupon', 'id'],
                appliedCouponName: ['appliedCoupon', 'name'],
                lineItemName: ['lineItem', 'name'],
                lineItemId: ['lineItem', 'id']
              };

              ctrl.getRows = function () {
                return _.map(_.cloneDeep(ctrl.items), function (item) {
                  _.forEach(adjustmentColumns, function (val, key) {
                    item[key] = _.get(item, val);
                  });
                  _.forEach(ctrl.columns, function (c) {
                    item[c] = $filter('csvOutput')(item[c], c);
                  });
                  return item;
                });
              };

              ctrl.saveXls = function () {
                new Downloader().saveXls2(baseTitle + ".xls", ctrl.headingFields, ctrl.columns, ctrl.getRows(), []);
              };
            }
          };
        },
        bulk: function() {
        },
        listExtension: function() {
        }
      },
      views: {
        'content@app': {
          controller: 'ResourceListCtrl',
          controllerAs: 'list',
          template: listTemplate
        },
        "context@app": {
          controller: /*@ngInject*/ function(readOnly) {
            this.readOnly = readOnly;
          },
          template: null
        },
        'title@app': {
          template: listTitleTemplate
        }
      },
      data: {
        title: "{{'payment.plural' | translate}}"
      }
    });
  });

export default MODULE_NAME;