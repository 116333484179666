/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import highchartsNg from 'highcharts-ng';

import {CHART_COLORS} from '../options/chart-colors';
import chartOptionsDirective from '../directives/chart-options/chart-options-directive';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';
import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';

import {Downloader} from "../../../components/util/downloader";

import template from './ext-dem-performance.html';
import {ADN_CHART_COUNT_TYPES, ADN_CHART_Y_AXIS_OPTIONS} from "../options/chart-options";

const MODULE_NAME = "ext-dem-performance-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  chartOptionsDirective,
  resources,
  promiseSpinnerComponent,
  localNetworkProfile
])

  .directive('adnExtDemPerformanceChart', function($locale, $translate, $log, localUserProfile, LocalNetworkProfile, ChartOptionsUtil, UserProfile, statsResource) {
    return {
      restrict: 'A',
      scope: {
        obj: '<adnExtDemPerformanceChart',
        chartType: '@adnChartType',
        absChunkMin: '@',
        biddersHook: '<?',
        statsData: '<?',
        isCollection: '@',
        bidder: '@'
      },
      template: template,
      link: {
        // using preLink to fill out chart config BEFORE the highchart directive is compiled
        pre: function preLink(scope) {

          scope.isCollection = _.isArray(scope.obj);

          let chartOptions = ChartOptionsUtil.getOptions(scope.chartType),
            localUser = localUserProfile.get(),
            profile = localUser.profile,
            profileStartDate = _.get(profile[chartOptions.perfChartOptions], 'startDate'),
            profileEndDate = _.get(profile[chartOptions.perfChartOptions], 'endDate'),
            profileCountType = ADN_CHART_COUNT_TYPES[_.get(profile[chartOptions.perfChartOptions], 'countTypeId')],
            profileYAxisOption = ADN_CHART_Y_AXIS_OPTIONS[_.get(profile[chartOptions.perfChartOptions], 'yAxisOptionId')],
            profileSelFieldIds = _.get(profile[chartOptions.perfChartOptions], 'selFieldIds'),
            profileSelFieldId = _.get(profile[chartOptions.perfChartOptions], 'selFieldId'),
            getSelFields = function() {
              if (scope.isCollection) {
                return _.map(scope.obj, function(c) {
                  return {
                    data: [],
                    id: c.bidder,
                    name: c.bidder,
                    yAxis: 0
                  };
                });
              }
              let map = _.map(_.filter(angular.copy(chartOptions.fields), function(value) {
                return scope.selFieldIds.indexOf(value.id) > -1 && value.id !== 'customFields';
              }), _.iteratee());
              if (scope.yAxisOption === ADN_CHART_Y_AXIS_OPTIONS.shared) {
                _.forEach(map, function(c) {
                  c.yAxis = 0;
                });
              }
              return map;
            };

          scope.dateOption = ChartOptionsUtil.getDateOptions()[_.get(profile[chartOptions.perfChartOptions], 'dateOptionId')];
          if (!scope.dateOption && (!profileStartDate || !profileEndDate)) {
            scope.dateOption = ChartOptionsUtil.getDateOptions().last12Hours;
          }
          scope.startDate = scope.dateOption ? scope.dateOption.startDate() : moment(profileStartDate);
          scope.endDate = scope.dateOption ? scope.dateOption.endDate() : moment(profileEndDate);
          scope.countType = profileCountType ? profileCountType : ADN_CHART_COUNT_TYPES.singular;
          scope.yAxisOption = profileYAxisOption ? profileYAxisOption : ADN_CHART_Y_AXIS_OPTIONS.multiple;
          scope.selFieldIds = profileSelFieldIds ? profileSelFieldIds : _.map(chartOptions.fields, 'id')[0];
          scope.selFieldId = profileSelFieldId ? profileSelFieldId : _.map(chartOptions.fields, 'id')[0];

          scope.changeHook = {
            chartChangeHandlers: [],
            getTotals: () => {
              return scope.totals;
            }
          };

          let timezone = LocalNetworkProfile.get('timeZone');
          let networkTimezoneOffset = moment.tz(timezone).utcOffset();

          let advStatsSeries = getSelFields(),
            createChartConfig = function(type, series, yAxisData, height) {
              return {
                chart: {
                  type: type,
                  zoomType: 'x',
                  showAxes: true,
                  height: height
                },
                plotOptions: {
                  series: {
                    marker: {
                      enabled: false
                    },
                    turboThreshold: 2000
                  }
                },
                tooltip: {
                  shared: true
                },
                xAxis: {
                  type: 'datetime',
                  title: {
                    enabled: false,
                    text: 'Date and Time'
                  }
                },
                yAxis: yAxisData,
                series: series,
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                accessibility: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            };

          let getYAxes = function(extras) {
            if (scope.isCollection) {
              return {
                title: {text: null},
                min: 0,
                floor: 0,
                opposite: false,
                id: 0
              };
            }
            let standardFields = _.filter(_.map(chartOptions.fields, function(yAxis) {
              let theColor = CHART_COLORS[yAxis.yAxis];
              return {
                title: {text: null},
                min: 0,
                opposite: false,
                allowDecimals: scope.yAxisOption === ADN_CHART_Y_AXIS_OPTIONS.shared ? true : !!yAxis.allowDecimals,
                id: yAxis.yAxis,
                labels: {style: {color: theColor}}
              };
            }), function(f) {
              return f.id > -1;
            });
            _.forEach(extras, function(f) {
              let theColor = CHART_COLORS[CHART_COLORS.length - 1];
              standardFields.push({
                title: {text: null},
                min: 0,
                opposite: false,
                allowDecimals: false,
                name: f,
                labels: {style: {color: theColor}},
                id: standardFields.length
              });
            });
            return standardFields;
          };
          scope.statsChart = createChartConfig('line', advStatsSeries, getYAxes(), 300);

          let chunkYValue = function(yval) {
            if (_.has(yval, 'amount')) {
              return yval.amount;
            } else if (_.has(yval, 'count')) {
              return yval.count;
            }
            return yval;
          };

          let getCollDataPoint = function(theChunks, fieldId, bidderId) {
            let objParam = "creative";
            if (scope.chartType === 'LINE_ITEM') {
              objParam = "lineItem";
            } else if (scope.chartType === 'AD_UNIT') {
              objParam = "adUnit";
            }

            if (scope.chartType === 'EXTERNAL_DEMAND_SOURCE') {
              return _.reduce(_.map(_.filter(theChunks, function(c) {
                return bidderId ? c.bidder === bidderId : true;
              }), function(m) {
                return m[fieldId];
              }), function(sum, n) {
                return sum + chunkYValue(n);
              }, 0);
            }

            return _.get(_.find(theChunks, function(c) {
              return c[objParam].id === fieldId;
            }), scope.selFieldId) || 0;
          };

          let startStatsGet = function() {
            let chunkSize = ChartOptionsUtil.getChunkSize(scope.startDate, scope.endDate, scope.absChunkMin);

            let statsParams = {
              id: scope.isCollection ? null : scope.obj.id,
              idKey: chartOptions.idKey,
              startDate: scope.startDate,
              endDate: scope.endDate,
              groupBy: chunkSize.key
            };
            if (scope.isCollection) {
              let field = _.find(chartOptions.fields, ['id', scope.selFieldId]);
              _.forEach(scope.statsChart.series, function(series) {
                series.isPercentage = field.isPercentage;
                series.allowDecimals = field.allowDecimals;
                series.tooltip = field.tooltip;
              });
            }

            let statsHandling = function(stats) {

              Highcharts.setOptions({
                global: {timezoneOffset: networkTimezoneOffset * -1},
                lang: {
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  months: $locale.DATETIME_FORMATS.MONTH,
                  weekdays: $locale.DATETIME_FORMATS.DAY,
                  thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
                  decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
                }
              });
              scope.error = false;

              let allSeries = scope.statsChart.series;

              const allBidders = _.uniq(_.flatten(_.map(stats.chunks, function(chunk) {
                return _.uniq(_.map(chunk.chunks, function(subChunk) {
                  return subChunk.bidder;
                }));
              })));


              if (!scope.isCollection) {
                if (scope.biddersHook && _.isFunction(scope.biddersHook.onBiddersFound) && !scope.biddersHook.biddersRegistered) {
                  scope.biddersHook.onBiddersFound(allBidders, stats);
                }
                if (scope.haveStats && scope.biddersHook && _.isFunction(scope.biddersHook.onSelections)) {
                  scope.biddersHook.onSelections(stats, scope.dateOption, scope.startDate, scope.endDate, scope.countType, scope.yAxisOption, scope.selFieldIds, scope.selFieldId);
                }
              }

              const allTotals = {};
              let keyedChunks = _.keyBy(stats.chunks, 'chunkStart');
              _.forEach(scope.isCollection ? allSeries : chartOptions.fields, function(lineOnChart, loopCounter) {
                const series = scope.isCollection ? lineOnChart : _.find(allSeries, function(aSeries) {
                  return aSeries.id === lineOnChart.id;
                });

                if (series) {
                  if (scope.isCollection || scope.yAxisOption === ADN_CHART_Y_AXIS_OPTIONS.shared) {
                    series.yAxis = 0;
                  } else {
                    series.yAxis = _.get(chartOptions.fields[series.id], 'yAxis') || series.yAxis;
                  }

                  const yAxisFieldId = _.get(chartOptions.fields[series.id], 'yAxis');
                  const yAxisFieldColor = _.get(scope.statsChart.yAxis[yAxisFieldId], 'labels.style.color');
                  series.color = scope.isCollection ? CHART_COLORS[loopCounter % CHART_COLORS.length] : yAxisFieldColor || CHART_COLORS[CHART_COLORS.length - 1];
                }

                let runningTotal = 0;
                let maxTotal = -1;
                let data = _.map(stats.chunkRange, function(statsDate) {
                  let dataPoint = null;
                  if (keyedChunks[statsDate]) {
                    const dataReference = lineOnChart.toTheMax && !scope.bidder ? [keyedChunks[statsDate].totals] : keyedChunks[statsDate].chunks;
                    dataPoint = scope.isCollection ? getCollDataPoint(dataReference, scope.selFieldId, series.id) : getCollDataPoint(dataReference, lineOnChart.id, scope.bidder);
                  }
                  let value = dataPoint || 0;
                  if (lineOnChart.isPercentage) {
                    value = value * 100;
                    runningTotal = value;
                  } else if (lineOnChart.toTheMax) {
                    runningTotal = value;
                    maxTotal = maxTotal > runningTotal ? maxTotal : runningTotal;
                  } else {
                    runningTotal += value;
                  }
                  return {
                    x: moment(statsDate).valueOf(),
                    y: scope.countType === ADN_CHART_COUNT_TYPES.cumulative && !lineOnChart.singularOnly ? runningTotal : value
                  };
                });
                allTotals[lineOnChart.id] = maxTotal > -1 ? maxTotal : runningTotal;
                if (series) {
                  series.data = data;
                }
              });
              if (scope.bidder) {
                scope.totals = allTotals;
              } else {
                scope.totals = stats.totals || stats;
              }

              _.forEach(scope.changeHook.chartChangeHandlers, function(f) {
                f(scope.totals);
              });

              scope.haveStats = true;
              scope.noData = !scope.statsChart.series.length;
              scope.isPending = false;
            };
            if (scope.statsData) {
              statsHandling(scope.statsData);
            } else {
              statsResource.get(statsParams, statsHandling, function(data) {
                $log.warn(data);
                if (data.status !== -1) {
                  scope.error = true;
                }
                scope.isPending = false;
              }, scope);
            }
          };
          startStatsGet();

          if (scope.biddersHook) {
            scope.biddersHook.reboot = function(currentStatsData, dateOption, startDate, endDate, countType, yAxisOption, selFieldIds, selFieldId) {
              scope.statsData = currentStatsData;
              scope.dateOption = dateOption;
              scope.startDate = startDate;
              scope.endDate = endDate;
              scope.countType = countType;
              scope.selFieldIds = selFieldIds;
              scope.selFieldId = selFieldId;
              scope.yAxisOption = yAxisOption;
              scope.statsChart.series = getSelFields();

              startStatsGet();
            };
          }

          scope.changeCallback = function(dateOptionId, startDate, endDate, countTypeId, statTypeId, yAxisOptionId, selFieldIds, selFieldId) {
            scope.dateOption = ChartOptionsUtil.getDateOptions()[dateOptionId];
            scope.startDate = moment(startDate);
            scope.endDate = moment(endDate);
            scope.countType = ADN_CHART_COUNT_TYPES[countTypeId];
            scope.selFieldIds = selFieldIds;
            scope.selFieldId = selFieldId;
            scope.yAxisOption = ADN_CHART_Y_AXIS_OPTIONS[yAxisOptionId];
            scope.statsChart.series = getSelFields();

            startStatsGet();

            profile[chartOptions.perfChartOptions] = {
              dateOptionId: _.get(scope.dateOption, 'id'),
              startDate: scope.startDate.toDate(),
              endDate: scope.endDate.toDate(),
              yAxisOptionId: scope.yAxisOption.id,
              countTypeId: scope.countType.id,
              selFieldIds: scope.selFieldIds,
              selFieldId: scope.selFieldId
            };

            UserProfile.save({
              id: localUser.userId,
              profile: _.pick(profile, chartOptions.perfChartOptions)
            }, function(apiUser) {
              localUser = localUserProfile.safeSaveProfile(chartOptions.perfChartOptions, apiUser.profile[chartOptions.perfChartOptions]);
              profile = localUser.profile;
            });
          };

          scope.save = function() {

            let allDataFields = getSelFields();
            let headingFields = _.map(allDataFields, 'name');
            let fields = _.map(allDataFields, 'id');

            let rows = [];
            let refData = angular.copy(scope.statsChart.series[0].data);
            let dataFields = scope.statsChart.series;
            for (let i = 0; i < refData.length; i++) {
              let dateData = {};
              for (let j = 0; j < dataFields.length; j++) {
                if (j === 0) {
                  dateData.date = moment(dataFields[j].data[i].x).toString();
                }
                dateData[dataFields[j].id] = dataFields[j].data[i].y;
              }
              rows.push(dateData);
            }

            headingFields.unshift("Date");
            fields.unshift("date");

            let date = scope.dateOption ? $translate.instant('chart.dateOption.' + scope.dateOption.id) : "custom dates";
            let defaultName = (scope.isCollection ? _.map(scope.obj, ["name"]).join("-") : scope.obj.name + " " + date + ".xls").replace(/ /g, "-");
            new Downloader().saveXls2(defaultName, headingFields, fields, rows, []);
          };
        },
        post: function postLink(scope, element) {
          element.addClass('chart');
        }
      }
    };
  });

export default MODULE_NAME;