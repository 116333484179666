/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import localNetworkProfile from '../../components/session/local-network-profile';

import template from './creative-set-list-component.html';
import {getListChildren} from "../common/controller/resource-list-controller";
import {OBJECT_TYPE} from "../common/constants/object-type";
import {creativeSetBulkAction} from "./creative-set-commons";

const MODULE_NAME = "creative-set-list-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile])

  .component('adnCreativeSetList', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      list: '<',
      readOnly: '<readonly'
    },
    controller: function($templateCache, adnListHelper, $filter, $translate, $uibModal, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter, Creative) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.list.canExpand = true;

        if (!ctrl.list.getChildren) {
          getListChildren(ctrl.list, adnListHelper, OBJECT_TYPE.CreativeSet, Creative);
        }

        if (!ctrl.list.bulkAction) {
          ctrl.list.bulkAction = creativeSetBulkAction(ctrl.list, {$uibModal: $uibModal, $filter: $filter, $translate: $translate}, {}, {LocalNetworkProfile: LocalNetworkProfile, LocalUserPermissions: LocalUserPermissions, adnTimeShifter: adnTimeShifter});
        }
      };
    }
  });

export default MODULE_NAME;