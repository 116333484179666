/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';

const MODULE_NAME = "translation-filter";

angular.module(MODULE_NAME, [translate])

  .filter('adnTranslate', function($translate, $interpolate) {
    return function(message, code, params) {
      const translation = $translate.instant(code, params);
      return !translation || translation === code ? $interpolate(message)(params) : translation;
    };
  });

export default MODULE_NAME;