/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import standardAnalysisModule from '../common/standard-analysis-module';
import {triggerController} from "./trigger-util";

import triggerForm from "../trigger/trigger-form.html";

const MODULE_NAME = "trigger-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent
])

  .controller('TriggerCtrl', function($stateParams, $q, $templateCache, adnTimeShifter, Folder, VisitorProfileFields, searchResource, model, modelComms, locationResource) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    $templateCache.put("triggerForm", triggerForm);

    triggerController(ctrl, model, modelComms, $q, adnTimeShifter, Folder, searchResource, VisitorProfileFields, locationResource);
  });

export default MODULE_NAME;
