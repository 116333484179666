/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './external-demand-source-edit.html';
import deleteTemplate from '../../common/delete-actions.html';
import listTemplate from './external-demand-source-list.html';

import adminModule from '../admin-module';
import externalDemandSourceController from './external-demand-source-controller';
import externalDemandSourceAnalysisController from './external-demand-source-analysis-controller';
import twoPanelTemplate from "../../common/two-panel.html";
import analysisTemplate from "./external-demand-source-analysis.html";

const MODULE_NAME = 'external-demand-source-module';

angular.module(MODULE_NAME, [adminModule, externalDemandSourceController, externalDemandSourceAnalysisController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('external-demand-sources.external-demand-source', 'ExternalDemandSource', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          },
          'leftPanel@app.external-demand-sources.external-demand-source': {
            template: editTemplate,
            controller: 'ExternalDemandSourceCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.external-demand-sources.external-demand-source': {
            template: analysisTemplate,
            controller: 'ExternalDemandSourceAnalysisCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'ExternalDemandSource'));
  });

export default MODULE_NAME;