/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';

import template from './custom-select.html';

import resources from '../../../components/api/resources/resources';

const MODULE_NAME = 'custom-select';

angular.module(MODULE_NAME, [
  resources,
  uiSelect,
  uiBootstrap,
  uiTranslate
])

  .component('adnCustomSelect', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      searchFunction: '&',
      model: '<',
      searchObject: '@',
      param: '@',
      allowClear: '@',
      required: '@'
    },
    controller: function($timeout) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.required = ctrl.required === 'true' || ctrl.required === true;
        ctrl.allowClear = ctrl.allowClear === 'true' || ctrl.allowClear === true;

        let prevSearchText = null;
        let searchTimeout;
        ctrl.search = function(searchElement) {
          if (prevSearchText === searchElement.search) {
            return;
          }
          $timeout.cancel(searchTimeout);
          searchTimeout = $timeout(function() {
              prevSearchText = searchElement.search;
              ctrl.searchFunction().apply(null, [searchElement.search])
                .then(function(page) {
                  ctrl.dataResults = page.results;
                });
          }, 350);
        };

        ctrl.ready = true;
      };
    }
  });

export default MODULE_NAME;