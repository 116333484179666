/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';

const MODULE_NAME = 'earnings-account-analysis';

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('EarningsAccountAnalysisCtrl', function($stateParams, model, Site, adnListHelper, LocalUserPermissions, modelComms) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.isNew = $stateParams.isNew;
    adnListHelper.setUpBasicList(ctrl, Site, 'fullSitesForEarningsAccount', {moreParams: {earningsAccount: model.id}});

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true;

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;