/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input} from '@angular/core';
import {
  isDashOutputConfig, isHlsOutputConfig, isMpeg4OutputConfig, isQuicktimeOutputConfig
} from "./transcode-config";
import type {TranscodeConfig} from "./transcode-config";

@Component({
  selector: 'transcode-config',
  template: `
    <div class="tab-content">
      <p>Trancoding is <span class="label" [ngClass]="{'label-success': transcodeConfig.enabled, 'label-warning': !transcodeConfig.enabled}">{{transcodeConfig.enabled ? 'Enabled' : 'Disabled'}}</span></p>
      <h4>Transcode Settings</h4>
      <div *ngFor="let output of transcodeConfig.outputs | keyvalue">
        <div class="row">
          <label class="col-md-4 control-label">Output Name</label>
          <div class="form-control-static">{{output.key}}</div>
        </div>
        <div class="row">
          <label class="col-md-4 control-label">Output Type</label>
          <div class="form-control-static">{{output.value.type|uppercase}}</div>
        </div>
        <div class="row">
          <label class="col-md-4 control-label">Audio normalisation</label>
          <div class="form-control-static">{{output.value.audioNormalisation ? 'Enabled' : 'Disabled'}}</div>
        </div>
        <div *ngIf="output.value | guardType: isDashOutputConfig as dashOutputConfig">
          <div class="row" *ngFor="let streamConfig of dashOutputConfig.dashStreamConfigs; let i = index">
            <label class="col-md-4 control-label">Stream {{i}} max bitrate</label>
            <div class="form-control-static">{{streamConfig.maxBitrate}} bits/sec</div>
          </div>
        </div>
        <div *ngIf="output.value | guardType: isHlsOutputConfig as hlsOutputConfig">
          <div class="row" *ngFor="let streamConfig of hlsOutputConfig.hlsStreamConfigs; let i = index">
            <label class="col-md-4 control-label">Stream {{i}} max bitrate</label>
            <div class="form-control-static">{{streamConfig.maxBitrate}} bits/sec</div>
          </div>
        </div>
        <div *ngIf="output.value | guardType: isMpeg4OutputConfig as mpeg4OutputConfig">
          <div class="row">
            <label class="col-md-4 control-label">Max bitrate</label>
            <div class="form-control-static">{{mpeg4OutputConfig.maxBitrate}} bits/sec</div>
          </div>
          <div class="row">
            <label class="col-md-4 control-label">Height</label>
            <div class="form-control-static">{{mpeg4OutputConfig.height}} pixels</div>
          </div>
        </div>
        <div *ngIf="output.value | guardType: isQuicktimeOutputConfig as quicktimeOutputConfig">
          <div class="row">
            <label class="col-md-4 control-label">Max bitrate</label>
            <div class="form-control-static">{{quicktimeOutputConfig.maxBitrate}} bits/sec</div>
          </div>
          <div class="row">
            <label class="col-md-4 control-label">Height</label>
            <div class="form-control-static">{{quicktimeOutputConfig.height}} pixels</div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  `
})
export class TranscodeConfigComponent {
  @Input() transcodeConfig!: TranscodeConfig;

  // type guards used in template
  isDashOutputConfig = isDashOutputConfig;
  isHlsOutputConfig = isHlsOutputConfig;
  isMpeg4OutputConfig = isMpeg4OutputConfig;
  isQuicktimeOutputConfig = isQuicktimeOutputConfig;
}


