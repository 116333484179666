/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';

import resources from '../../../components/api/resources/resources';

const MODULE_NAME = 'object-membership-controller';

angular.module(MODULE_NAME, [uiBootstrap, resources])

  .controller('ObjectMembershipCtrl', function(theResource, $uibModalInstance, adnModalLoading, translation, obj, objects, matchedObjects) {
    const ctrl = this;

    ctrl.translation = translation;
    ctrl.obj = angular.copy(obj);
    ctrl.objects = angular.copy(objects);
    ctrl.matchedObjects = _.map(matchedObjects, "id");

    ctrl.selectObjects = function() {
      ctrl.matchedObjects = _.map(ctrl.objects, 'id');
    };

    ctrl.deselectObjects = function() {
      ctrl.matchedObjects = [];
    };

    ctrl.saveAndClose = function() {
      ctrl.disabled = true;
      adnModalLoading.applyModalLoading();

      _.forEach(ctrl.objects, function(object) {
        const wantsToBeInTeam = _.indexOf(ctrl.matchedObjects, object.id) > -1;
        const hasTeam = _.find(object.teams, function(team) {
          return team.id === obj.id;
        });
        if (wantsToBeInTeam && !hasTeam) {
          object.teams.push(obj);
          object.hasChanged = true;
        }
        if (!wantsToBeInTeam && hasTeam) {
          object.teams = _.filter(object.teams, function(team) {
            return team.id !== obj.id;
          });
          object.hasChanged = true;
        }
      });
      const changedObjects = _.map(_.filter(ctrl.objects, function(object) {
        return object.hasChanged;
      }), function(object) {
        return _.pick(object, 'id', 'teams');
      });
      theResource.bulkSave(changedObjects).$promise.then(function() {
        ctrl.disabled = false;
        $uibModalInstance.close();
        adnModalLoading.removeModalLoading();
      }, function(errors) {
        ctrl.disabled = false;
        ctrl.validationErrors = errors.data.errors;
        adnModalLoading.removeModalLoading();
      });
    };
  });

export default MODULE_NAME;