/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import standardAnalysisModule from '../common/standard-analysis-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {triggerConditionFormatter, triggerController} from "../trigger/trigger-util";

import template from './segment-trigger-modal.html';
import triggerForm from "../trigger/trigger-form.html";

import {TTL} from "./select-field-constants";

const MODULE_NAME = "segment-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent
])

  .controller('SegmentCtrl', function(Trigger, $templateCache, $q, VisitorProfileFields, LocalNetworkProfile, $uibModal, $translate, $stateParams, adnTimeShifter, Folder, searchResource, adnListHelper, model, modelComms) {
    const ctrl = this;

    $templateCache.put("triggerForm", triggerForm);

    ctrl.TTL = TTL;

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        ctrl.model.dataSource = ctrl.model.dataSource || "ADNUNTIUS";

        if (!ctrl.model.timeToLive) {
          ctrl.model.ttlUnit = "Days";
          ctrl.model.ttlSpec = false;
        } else {
          const inDays = moment.duration(ctrl.model.timeToLive).asDays();
          const inHours = moment.duration(ctrl.model.timeToLive).asHours();
          const inMinutes = moment.duration(ctrl.model.timeToLive).asMinutes();
          ctrl.model.ttlSpec = true;

          if (inDays % 1 === 0) {
            ctrl.model.ttlNumber = inDays;
            ctrl.model.ttlUnit = 'Days';
          } else if (inHours % 1 === 0) {
            ctrl.model.ttlNumber = inHours;
            ctrl.model.ttlUnit = 'Hours';
          } else {
            ctrl.model.ttlNumber = inMinutes;
            ctrl.model.ttlUnit = 'Minutes';
          }
        }
      }});

    ctrl.isNew = $stateParams.isNew;

    if (ctrl.isNew) {
      ctrl.model.global = true;
    }

    ctrl.segSearch = function(searchElement, postFix) {
      searchResource.query({"q": searchElement.search, "types": 'UserSegment', objectState: 'ACTIVE', dataSource: 'ADNUNTIUS'}).then(function(data) {
        ctrl["segmentResults" + (postFix || "")] = data.searchResults;
      });
    };

    ctrl.triggerFormatter = function(entry) {
      return triggerConditionFormatter($translate, entry);
    };

    ctrl.model.folders = _.map(ctrl.model.folders, function(f) {
      return f.id;
    });

    ctrl.selFolder = _.get(ctrl.model, ['folderMembers', 0]);

    ctrl.hasAdvertising = LocalNetworkProfile.isAdvertisingPlatform();

    adnListHelper.setUpBasicList(ctrl, Trigger, 'fullTriggersForSegment', {
      moreParams: {segment: ctrl.model.id},
      pageSize: 101
    });

    ctrl.model.allFolders = null;
    Folder.query().$promise.then(function(page) {
      ctrl.model.allFolders = page.results;
    });

    ctrl.doTrigger = function(entry) {
      let modalInstance = $uibModal.open({
        size: 'lg',
        template: template,
        controllerAs: 'resource',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;
          triggerController(modalCtrl, entry || new Trigger({addUserSegments: [{id: ctrl.model.id, name: ctrl.model.name}]}), null, $q, adnTimeShifter, Folder, searchResource, VisitorProfileFields);
        }
      });
      modalInstance.closed.then(function() {
        ctrl.makeQuery();
      });
    };

    ctrl.onBeforeSubmit = function() {
      ctrl.model.timeToLive = ctrl.model.ttlSpec ? "P" + (ctrl.model.ttlUnit !== 'Days' ? 'T' : '') + ctrl.model.ttlNumber + ctrl.model.ttlUnit.charAt(0) : null;
      if (ctrl.model.cpm) {
        if (!_.isFinite(ctrl.model.cpm.amount)) {
          ctrl.model.cpm = null;
        }
      }
    };
  });

export default MODULE_NAME;