import angular from 'angular';
import _ from "lodash";
import uiRouter from '@uirouter/angularjs';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import toaster from 'angular-toastr';
import ngAnimate from 'angular-animate';
import checklistModel from 'checklist-model';
import dynamicLocale from 'angular-dynamic-locale';
import ngHttpAuth from 'angular-http-auth';
import ngRollbar from 'ng-rollbar';
import 'ng-tags-input';
import 'angular-gridster';

import "ui-select/src/common.css";
import "textangularjs/src/textAngular.css";
import "ng-tags-input/build/ng-tags-input.css";
import "ng-tags-input/build/ng-tags-input.bootstrap.css";
import "angular-gridster/dist/angular-gridster.css";
import "angular-object-diff/dist/angular-object-diff.css";
import "angular-toastr/dist/angular-toastr.css";
import '../style/app.scss';

import {ADN_TRANSLATIONS} from './common/translations';
import {AUTH_EVENT} from "../components/auth/auth-constants";
import {LocaleHelper} from "../components/util/locale-helper";
import {ADN_GRIDSTER_CONFIG} from "./config/gridster-config";

import chartOptionsUtil from './common/options/chart-options-util';
import authProvider from '../components/api/auth-provider';
import errors from './errors/error-module';
import exceptionHandler from './errors/exception-handler';
import stateLoader from './common/directives/html/state-loading-class-directive';
import adnAuthService from '../components/auth/auth';
import adnAuthHelper from '../components/util/auth-helper';
import standardFilters from './common/filters/standard-filters';
import resources from '../components/api/resources/resources';
import signIn from './sign-in/sign-in';
import home from './home/home-module';
import orderModule from './order/order-module';
import folioModule from './folio/folio-module';
import advertiserModule from './advertiser/advertiser-module';
import lineItemModule from './line-item/line-item-module';
import creativeSetModule from './creative-set/creative-set-module';
import lineItemTemplateModule from './line-item/line-item-template-module';
import campaignModule from './campaign/campaign-module';
import reviewModule from './review/review-module';
import axReviewModule from './review/ax-review-module';
import axLineItemModule from './campaign/ax-line-item-module';
import firstPartyAudienceModule from './first-party-audience/first-party-audience-module';
import earningsAccountModule from './earnings-account/earnings-account-module';
import fieldsModule from './fields/fields-module';
import creativeModule from './creative/creative-module';
import libraryCreativeModule from './library-creative/library-creative-module';
import siteModule from './site/site-module';
import rulesetModule from './ruleset/ruleset-module';
import siteGroupModule from './site-group/site-group-module';
import adUnitModule from './ad-unit/ad-unit-module';
import externalAdUnitModule from './external-ad-unit/external-ad-unit-module';
import reportModule from './report/report-module';
import dataQueryModule from './data-queries/data-queries-module';
import reportSchedulesModule from './report-schedule/report-schedule-module';
import reportTemplatesModule from './report-template/report-templates-module';
import reportTranslationModule from './report-translation/report-translation-module';
import creativeRendered from './creative/ad/creative-rendered-comp';
import triggerModule from './trigger/trigger-module';
import segmentModule from './segment/segment-module';
import folderModule from './folder/folder-module';
import blocklistModule from './blocklist/blocklist-module';
import queryModule from './report/query/query-module';
import teamModule from './admin/team/team-module';
import teamGroupModule from './admin/team-group/team-group-module';
import roleModule from './admin/role/role-module';
import couponModule from './admin/coupon/coupon-module';
import paymentModule from './payment/payment-module';
import cdnUploadModule from './admin/cdn-upload/cdn-upload-module';
import layoutModule from './admin/layout/layout-module';
import dataExportModule from './admin/data-export/data-export-module';
import layoutIncludeModule from './admin/layout-include/layout-include-module';
import layoutExampleModule from './admin/layout-example/layout-example-module';
import externalDemandSourceModule from './admin/external-demand-source/external-demand-source-module';
import tierModule from './admin/tier/tier-module';
import mappingModule from './mapping/mapping-module';
import adTagModule from './ad-tag/ad-tag-module';
import apiKeysModule from './admin/api-key/api-key-module';
import contextServiceConfModule from './admin/context-service-configuration/context-service-configuration-module';
import emailTranslationModule from './admin/email-translation/email-translation-module';
import invoiceTranslationModule from './invoice-translation/invoice-translation-module';
import refDataModule from './admin/reference-data/reference-data-module';
import notificationPrefModule from './admin/notification-preferences/notification-preferences-module';
import userModule from './admin/user/user-module';
import marketplaceProductModule from './admin/marketplace-product/marketplace-product-module';
import productModule from './admin/product/product-module';
import userProfileModule from './admin/user-profile/user-profile-module';
import customEventModule from './admin/custom-event/custom-event-module';
import networkModule from './admin/network/network-module';
import formModule from './form/form-module';
import searchModule from './search/search-module';
import workspaceModule from './workspace/workspace-module';
import reachAnalysisModule from './reach-analysis/reach-analysis-module';
import bookingCalendarModule from './booking-calendar/booking-calendar-module';
import mauiModule from './maui/maui-module';
import permsModule from './perms/perms-module';
import userProfileThemeDir from './common/directives/html/user-profile-theme-directive';
import adnTranslate from './common/filters/translation-filter';
import adnFieldTranslate from './common/filters/field-translation-filter';
import {DowngradedComponentsModule} from "./downgraded-components-module";
import {zendeskPrefill} from "./sign-in/zendesk-prefill";
import {ApiConfig} from "../components/api/api";
import {LocalStorage} from "../components/session/local-storage";
import {downgradeInjectable} from "@angular/upgrade/static";
import {BurnRatesResource} from "../components/api/resources/resource/stats-resources/burn-rates-resource";
import {TimezonesResource} from "../components/api/resources/resource/timezones";
import {ReportTranslationDefaultsResource} from "../components/api/resources/resource/report-translation-defaults";
import {CurrenciesResource} from "../components/api/resources/resource/currencies";
import {AuditResource} from "../components/api/resources/resource/audit";
import {DataViewResource} from "../components/api/resources/resource/data-view";
import {DevicesResource} from "../components/api/resources/resource/devices";
import {VersionHistoryResource} from "../components/api/resources/resource/version-history";
import {StatsResource} from "../components/api/resources/resource/stats-resources/stats-resource";
import {LocalUserProfile} from "../components/session/local-user-profile";
import {VisitorProfileResource} from "../components/api/resources/resource/visitor-profile";
import {LocationResource} from "../components/api/resources/resource/location";
import {SearchResource} from "../components/api/resources/resource/search";
import {ExternalSearchResource} from "../components/api/resources/resource/external-search";
import {KeyValueSearchResource} from "../components/api/resources/resource/key-value-search";
import {KeywordProfileSearchResource} from "../components/api/resources/resource/keyword-profile-search";
import {KeywordSearchResource} from "../components/api/resources/resource/keyword-search";
import {CategorySearchResource} from "../components/api/resources/resource/categories";
import {LivePreviewResource} from "../components/api/resources/resource/live-preview";
import {TierResource} from "../components/api/resources/resource/tier";
import {DiagRequestResource} from "../components/api/resources/resource/diag-request";
import {ReachEstimateResource} from "../components/api/resources/resource/reach-estimate";
import {AuthenticatedUserResource} from "../components/api/resources/resource/authenticated-user";
import {DefaultEmailTranslationsResource} from "../components/api/resources/resource/default-email-translations";
import {DefaultInvoiceTranslationsResource} from "../components/api/resources/resource/default-invoice-translations";
import {CrawlerResource} from "../components/api/resources/resource/crawler";
import {TrafficHistogramResource} from "../components/api/resources/resource/traffic-histogram";
import {TargetingEmbeddingSearchResource} from "../components/api/resources/resource/targeting-embedding-search";
import {ArticleSearchResource} from "../components/api/resources/resource/article-search";

export const BaseAppModule = angular
  .module("app", [
    authProvider,
    uiBootstrap,
    translate,
    checklistModel,
    ngRollbar,
    ngAnimate,
    toaster,
    uiRouter,
    adnAuthService,
    apiKeysModule,
    stateLoader,
    firstPartyAudienceModule,
    dynamicLocale,
    ngHttpAuth,
    resources,
    creativeRendered,
    standardFilters,
    errors,
    exceptionHandler,
    userProfileThemeDir,
    emailTranslationModule,
    invoiceTranslationModule,
    home,
    blocklistModule,
    signIn,
    dataExportModule,
    orderModule,
    folioModule,
    advertiserModule,
    earningsAccountModule,
    paymentModule,
    fieldsModule,
    lineItemModule,
    lineItemTemplateModule,
    creativeSetModule,
    campaignModule,
    reviewModule,
    axReviewModule,
    axLineItemModule,
    creativeModule,
    adnAuthHelper,
    libraryCreativeModule,
    siteModule,
    rulesetModule,
    adTagModule,
    cdnUploadModule,
    siteGroupModule,
    adUnitModule,
    externalAdUnitModule,
    mappingModule,
    contextServiceConfModule,
    notificationPrefModule,
    queryModule,
    userProfileModule,
    refDataModule,
    chartOptionsUtil,
    tierModule,
    userModule,
    couponModule,
    productModule,
    marketplaceProductModule,
    reportModule,
    dataQueryModule,
    reportSchedulesModule,
    reportTemplatesModule,
    reportTranslationModule,
    roleModule,
    'ngTagsInput',
    'gridster',
    teamModule,
    teamGroupModule,
    layoutModule,
    triggerModule,
    segmentModule,
    folderModule,
    layoutIncludeModule,
    layoutExampleModule,
    networkModule,
    DowngradedComponentsModule,
    externalDemandSourceModule,
    formModule,
    searchModule,
    reachAnalysisModule,
    bookingCalendarModule,
    mauiModule,
    permsModule,
    workspaceModule,
    adnTranslate,
    adnFieldTranslate,
    customEventModule
  ])
  .config(function ($httpProvider, $urlRouterProvider, $locationProvider, RollbarProvider, tmhDynamicLocaleProvider) {
    $httpProvider.useApplyAsync(true);
    $httpProvider.defaults.headers.post = {
      'Content-Type': 'text/plain'
    };

    $urlRouterProvider.otherwise(function ($injector, $location) {
      let AuthService = $injector.get('AuthService');
      if (AuthService.isAuthenticated() && !AuthService.getSession().isExpired()) {
        return $location.path() === '/' || !$location.path() ? '/home' : '/404';
      }
      if ($location.path() === '/sign-in/masquerade') {
        return $location.path();
      }
      return '/home';
    });

    $locationProvider.html5Mode(true);

    const apiId = ApiConfig.obtain().getId();
    const localStoragePrefix = 'adn' + (apiId ? '.' + apiId : '');
    LocalStorage.obtain().setPrefix(localStoragePrefix);
    tmhDynamicLocaleProvider.localeLocationPattern('./angular-i18n/angular-locale_{{locale}}.js');

    RollbarProvider.init({
      accessToken: "61a9e73004694142a31daa80928bde94",
      captureUncaught: true,
      enabled: true,
      payload: {
        environment: apiId.replace(/_adn/g, '')
      }
    });
  })
  // less important config gets done here -- it's an arbitrary distinction, I know, but it's just easier than having a super-large parameter list
  .config(function ($translateProvider, $uibModalProvider, uibDatepickerConfig, toastrConfig, tagsInputConfigProvider, gridsterConfig) {

    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.translations('en', ADN_TRANSLATIONS);
    $translateProvider.preferredLanguage('en')
      .fallbackLanguage('en');

    $uibModalProvider.options.windowClass = 'adnDialog';

    angular.extend(toastrConfig, {
      allowHtml: true,
      timeOut: 10000,
      preventOpenDuplicates: true
    });

    tagsInputConfigProvider.setDefaults(
      'tagsInput', {
        replaceSpacesWithDashes: false
      }
    );

    angular.merge(gridsterConfig, ADN_GRIDSTER_CONFIG.WORKSPACES);

    uibDatepickerConfig.shortYearCutoff = 99;
  })

  // for clarity, authService comes from the angular-http-auth bower component. AuthService is ours
  .run(function ($rootScope, $document, $timeout, $interval, $state, $window, $log, adnAuthHelper, LocalNetworkProfile, LocalUserPermissions, $locale, $location, Rollbar, authService, AuthService, localUserProfile, tmhDynamicLocale) {

    const profile = localUserProfile.getProfile();
    const isLocalhost = $location.host() === 'localhost';

    if (!isLocalhost) {
      const script = $document[0].createElement('script');
      script.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=1594a37a-f3ad-4284-ba8c-db944ee8a3e6');
      script.id = 'ze-snippet';
      $document[0].getElementsByTagName("body")[0].appendChild(script);
    } else {
      $window.zE = function () {
        // zendesk is out of control when in doing dev -- just killing it here now.
      };
    }

    let zendeskTimer;
    const zendeskSetup = function () {
      if (!$window.zE) {
        return;
      }
      $window.zE('webWidget', 'setLocale', 'en');

      if (profile && Object.prototype.hasOwnProperty.call(profile, "adnPrefs")) {
        const showSupportWidget = _.get(profile, 'adnPrefs.showSupportWidget');
        $window.zE('webWidget', showSupportWidget !== false ? 'show' : 'hide');
      }
      $interval.cancel(zendeskTimer);
    };
    zendeskTimer = $interval(zendeskSetup, 500, 20);

    Rollbar.configure({
      enabled: !isLocalhost,
      scrubFields: ['refresh_token', 'access_token', 'auth_token']
    });

    // attempt to refresh an expired session if it exists
    if (AuthService.isAuthenticated() && AuthService.getSession().isExpired()) {
      AuthService.refresh().catch(function () {
        // destroy the old session if it cannot be refreshed
        AuthService.signOut();
      });
    }

    zendeskPrefill($window, $interval, localUserProfile, LocalNetworkProfile);

    const userLocale = localUserProfile.get('locale');
    if (!!userLocale && LocaleHelper.toAngularFormat(userLocale) !== $locale.id) {
      tmhDynamicLocale.set(LocaleHelper.toAngularFormat(userLocale));
    }

    // catch events triggered by API 401 response errors
    $rootScope.$on('event:auth-loginRequired', function () {
      // attempt a token refresh
      AuthService.refresh().then(function (session) {
        authService.loginConfirmed(session);
      }, function () {
        $rootScope.$broadcast(AUTH_EVENT.notAuthenticated);
      });
    });

    $rootScope.$on('$locationChangeStart', function (event, current, previous) {
      function deriveTokens(url) {
        if (!_.isString(url) || url.length < 5 || url.indexOf("?") < 5 || url.indexOf("&") < 5) {
          return;
        }
        const kvs = url.split("?")[1].split("&");
        const dataObj = {};
        _.forEach(kvs, function (str) {
          if (str.indexOf("=") < 1) {
            return;
          }
          const splitParts = str.split("=");
          dataObj[splitParts[0]] = splitParts[1];
        });
        if (dataObj.accessToken && dataObj.username && dataObj.refreshToken && dataObj.expiresIn) {
          return dataObj;
        }
      }

      if (current === previous || current.indexOf("hardstop") > -1 || previous.indexOf("hardstop") > -1) {
        return;
      }

      let tokens = deriveTokens(current);
      if (!tokens) {
        tokens = deriveTokens(previous);
      }
      if (tokens) {
        AuthService.switchUi(
          tokens.username,
          tokens.masqueraderUsername,
          tokens.accessToken,
          tokens.refreshToken,
          tokens.expiresIn
        );
        event.preventDefault();

        const isMasquerading = tokens.username !== tokens.masqueraderUsername && tokens.masqueraderUsername;
        adnAuthHelper.doAuth({}, {username: tokens.username, masqUsername: isMasquerading ? tokens.masqueraderUsername : ""}, isMasquerading, tokens.context).then(function() {
          $location.search("hardstop", "hardstop");
          $timeout(function () {
            $window.location.reload();
          }, 100);
        });
      }
    });
    $rootScope.$on('$locationChangeSuccess', function () {
      $location.search("username", null);
      $location.search("masqueraderUsername", null);
      $location.search("accessToken", null);
      $location.search("refreshToken", null);
      $location.search("expiresIn", null);
      $location.search("hardstop", null);
      $location.search("context", null);
    });
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
      if (!((toState.data && toState.data.public) || AuthService.isAuthenticated())) {
        event.preventDefault();
        $rootScope.$broadcast(AUTH_EVENT.notAuthenticated, toState, toParams);
      }
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      $state.previousState = fromState;
      $state.previousParams = fromParams;

      const appsForPage = _.get(toState.data, 'app') || $state.get(toState.parent) || [];
      const appsForPageArray = _.isArray(appsForPage) ? appsForPage : [appsForPage];
      const currentApp = localUserProfile.getCurrentApp();
      const allApps = LocalUserPermissions.getApps();

      if (appsForPageArray.length === 0) {
        // all apps can view this page.
        return;
      }

      if (_.intersection(allApps, appsForPageArray).length === 0) {
        $state.go("app.errors.404", null, {location: false, inherit: false});
      } else if (appsForPageArray.indexOf(currentApp) < 0) {
        const appsToGo = _.filter(appsForPageArray, function (app) {
          return app !== currentApp;
        });
        $state.go("app.errors.wrong-app", {appsToGo: appsToGo}, {location: false, inherit: false});
      }
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $log.warn(error);
    });
  })
  .factory('localUserProfile', downgradeInjectable(LocalUserProfile))
  .factory('burnRatesResource', downgradeInjectable(BurnRatesResource))
  .factory('locationResource', downgradeInjectable(LocationResource))
  .factory('searchResource', downgradeInjectable(SearchResource))
  .factory('externalSearchResource', downgradeInjectable(ExternalSearchResource))
  .factory('keyValueSearchResource', downgradeInjectable(KeyValueSearchResource))
  .factory('keywordSearchResource', downgradeInjectable(KeywordSearchResource))
  .factory('keywordProfileSearchResource', downgradeInjectable(KeywordProfileSearchResource))
  .factory('targetingEmbeddingSearchResource', downgradeInjectable(TargetingEmbeddingSearchResource))
  .factory('articleSearchResource', downgradeInjectable(ArticleSearchResource))
  .factory('timezonesResource', downgradeInjectable(TimezonesResource))
  .factory('reportTranslationDefaultsResource', downgradeInjectable(ReportTranslationDefaultsResource))
  .factory('currenciesResource', downgradeInjectable(CurrenciesResource))
  .factory('auditResource', downgradeInjectable(AuditResource))
  .factory('visitorProfileResource', downgradeInjectable(VisitorProfileResource))
  .factory('dataViewResource', downgradeInjectable(DataViewResource))
  .factory('devicesResource', downgradeInjectable(DevicesResource))
  .factory('authenticatedUserResource', downgradeInjectable(AuthenticatedUserResource))
  .factory('versionHistoryResource', downgradeInjectable(VersionHistoryResource))
  .factory('statsResource', downgradeInjectable(StatsResource))
  .factory('diagRequestResource', downgradeInjectable(DiagRequestResource))
  .factory('livePreviewResource', downgradeInjectable(LivePreviewResource))
  .factory('categorySearchResource', downgradeInjectable(CategorySearchResource))
  .factory('reachEstimateResource', downgradeInjectable(ReachEstimateResource))
  .factory('defaultEmailTranslationsResource', downgradeInjectable(DefaultEmailTranslationsResource))
  .factory('defaultInvoiceTranslationsResource', downgradeInjectable(DefaultInvoiceTranslationsResource))
  .factory('tierResource', downgradeInjectable(TierResource))
  .factory('crawlerResource', downgradeInjectable(CrawlerResource))
  .factory('trafficHistogramResource', downgradeInjectable(TrafficHistogramResource))
  .component('app', {
    template: '<div ui-view></div>'
  }).name;
