/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Inject, Input} from '@angular/core';
import {interpolateWarning} from "./interpolate-warnings-filter";

@Component({
  selector: 'simple-error-messages',
  template: `
    <div *ngIf="(errors && errors.length)" class="alert alert-danger">
      <p>Your submission had a validation error. Adjust your input according to the details below.</p>
      <ul>
        <li *ngFor="let error of errors">{{interpolateWarning(error.error)}}</li>
      </ul>
    </div>
  `
})
export class SimpleErrorMessagesComponent {
  @Input() errors: any;

  constructor(@Inject('$interpolate') private $interpolate) {
  }

  interpolateWarning(warning) {
    return interpolateWarning(this.$interpolate, warning);
  }
}
