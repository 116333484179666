export const PERCENTAGE_FIELDS = {
  ctr: {
    decPlaces: 3
  },
  matchRate: {
    decPlaces: 1
  },
  visibility: {
    decPlaces: 1
  },
  viewability: {
    decPlaces: 1
  },
  overlapPercent: {
    decPlaces: 0
  },
  burnRate: {
    decPlaces: 0
  },
  renderedRate: {
    decPlaces: 1
  }
};

export const RATIO_FIELDS = {
  impressionsPerUniqueUser: {
    decPlaces: 2
  }
};
