/**
 * Copyright © 2024 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

export class AuditResource {
  constructor(@Inject('$http') private $http: any) {
  }

  get(domainObject: any, pCtrl: any, startDate: any, endDate: any, chartMode: boolean) {
    const versionedUri = ApiConfig.obtain().getVersionedUri(['audit', domainObject.id]);
    const ctrl = pCtrl || {};
    ctrl.pageSize = ctrl.pageSize || 25;
    ctrl.currentPage = ctrl.currentPage || 1;
    ctrl.pageQuery = ctrl.pageQuery || {};
    ctrl.pageQuery.$resolved = false;

    const params = {
      pageSize: ctrl.pageSize,
      page: ctrl.currentPage
    };
    if (startDate) {
      params['startDate'] = startDate;
    }
    if (endDate) {
      params['endDate'] = endDate;
    }
    if (chartMode) {
      params['queryOption'] = 'SINCE_RUNNING_DATE';
      params['auditType'] = 'DIRECT_DELIVERY_IMPACT';
    }

    return this.$http.get(versionedUri, {params: params}).then(function (page) {
      ctrl.totalCount = page.data.totalCount;
      ctrl.items = page.data.results;
      ctrl.pageQuery.$resolved = true;
      return page.data;
    });
  }
}
