import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';

import template from './welcome.html';
import templateData from './welcome-data.html';

import workspaceRegistry from '../../components/workspace-registry';
import localUserPermissions from '../../components/session/local-user-permissions';

const MODULE_NAME = 'home';

export const HOME_STATE = 'app.home';

angular.module(MODULE_NAME, [uiRouter, workspaceRegistry, localUserPermissions])
  .config(function($stateProvider) {

    $stateProvider.state(HOME_STATE, {
      url: '/home',
      resolve: {
        defaultWorkspace: function($state, $timeout, WorkspaceRegistry, localUserProfile) {
          const app = localUserProfile.getCurrentApp();
          if (app === 'DATA') {
            return;
          }
          return WorkspaceRegistry.getPromise().then(function(workspaces) {
            return _.head(workspaces);
          }).catch(function() {
            // this can be triggered when ad blocker is installed. Keep here to ensure things proceed properly in this case.
          });
        }
      },
      onEnter: function($state, defaultWorkspace, LocalUserPermissions, localUserProfile) {
        return $state.transition.then(function() {
          const options = {location: false};
          const anyPerms = LocalUserPermissions.getAnyVisibility();
          const app = localUserProfile.getCurrentApp();
          if (app === 'DATA') {
            return $state.go('app.welcome-data', null, options);
          }
          if (anyPerms.MANAGE_ADVERTISING) {
            return defaultWorkspace ?
              $state.go('app.workspaces.workspace', {id: defaultWorkspace.id}, options) :
              $state.go('app.welcome', null, options);
          }
          return $state.go('app.welcome', null, options);
        });
      }
    }).state('app.welcome', {
      url: '/welcome',
      views: {
        content: {
          template: template
        }
      },
      data: {
        title: 'Welcome to Adnuntius'
      }
    }).state('app.welcome-data', {
      url: '/welcome-data',
      views: {
        content: {
          template: templateData
        }
      }
    });
  });

export default MODULE_NAME;
