export const FIELD_TYPE_OPERATIONS = {
  Behaviour: {
    greaterThan: {
      id: 'greaterThan',
      opText: 'more than',
      operation: '>'
    },
    greaterThanEqual: {
      id: 'greaterThanEqual',
      opText: 'more than or equal to',
      operation: '>='
    },
    equals: {
      id: 'equals',
      opText: 'equal to',
      operation: "="
    },
    notEquals: {
      id: 'notEquals',
      opText: 'not equal to',
      operation: "!="
    },
    lessThan: {
      id: 'lessThan',
      opText: "less than",
      operation: "<"
    },
    lessThanEqual: {
      id: 'lessThanEqual',
      opText: "less than or equal to",
      operation: "<="
    }
  },
  Text: {
    equals: {
      id: 'equals',
      opText: 'equals',
      operation: '='
    },
    notEquals: {
      id: 'notEquals',
      opText: 'doesn\'t equal',
      operation: '!='
    },
    isOneOf: {
      id: 'isOneOf',
      opText: 'is one of',
      operation: 'in',
      help: 'Can supply a comma-delimited list of values'
    },
    isNotAnyOf: {
      id: 'isNotAnyOf',
      opText: 'is not any of',
      operation: '!in',
      help: 'Can supply a comma-delimited list of values'
    }
  },
  Date: {
    lessThan: {
      id: 'lessThan',
      opText: 'less than',
      operation: '<',
      controlType: 'MovingFeast'
    },
    lessThanEqual: {
      id: 'lessThanEqual',
      opText: 'less than or equal to',
      operation: '<=',
      controlType: 'MovingFeast'
    },
    greaterThan: {
      id: 'greaterThan',
      opText: 'more than',
      operation: '>',
      controlType: 'MovingFeast'
    },
    greaterThanEqual: {
      id: 'greaterThanEqual',
      opText: 'more than or equal to',
      operation: '>=',
      controlType: 'MovingFeast'
    },
    beforeDate: {
      id: 'beforeDate',
      opText: 'before specific date',
      operation: ">",
      controlType: 'Date'
    },
    afterDate: {
      id: 'afterDate',
      opText: 'after specific date',
      operation: ">",
      controlType: 'Date'
    }
  },
  Integer: {
    greaterThan: {
      id: 'greaterThan',
      opText: 'more than',
      operation: '>'
    },
    greaterThanEqual: {
      id: 'greaterThanEqual',
      opText: 'more than or equal to',
      operation: '>='
    },
    equals: {
      id: 'equals',
      opText: 'equal to',
      operation: "="
    },
    notEquals: {
      id: 'notEquals',
      opText: 'not equal to',
      operation: "!="
    },
    lessThan: {
      id: 'lessThan',
      opText: "less than",
      operation: "<"
    },
    lessThanEqual: {
      id: 'lessThanEqual',
      opText: "less than or equal to",
      operation: "<="
    }
  },
  Timestamp: {
    lessThan: {
      id: 'lessThan',
      opText: 'less than',
      operation: '<',
      controlType: 'MovingFeast'
    },
    lessThanEqual: {
      id: 'lessThanEqual',
      opText: 'less than or equal to',
      operation: '<=',
      controlType: 'MovingFeast'
    },
    greaterThan: {
      id: 'greaterThan',
      opText: 'more than',
      operation: '>',
      controlType: 'MovingFeast'
    },
    greaterThanEqual: {
      id: 'greaterThanEqual',
      opText: 'more than or equal to',
      operation: '>=',
      controlType: 'MovingFeast'
    },
    beforeDate: {
      id: 'beforeDate',
      opText: 'before specific date and time',
      operation: "<",
      controlType: 'DateAndTime'
    },
    afterDate: {
      id: 'afterDate',
      opText: 'after specific date and time',
      operation: ">",
      controlType: 'DateAndTime'
    }
  }
};

export const VALID_DURATION_UNITS = ["minutes", "days", "weeks", "months"];

export const BEHAVIOUR_TIMES = ['TODAY', 'YESTERDAY', 'THIS_WEEK', 'LAST_7_DAYS', 'LAST_WEEK', 'LAST_14_DAYS', 'THIS_MONTH', 'LAST_30_DAYS'];

export const BEHAVIOUR_TYPES = ['PAGE_VIEW', 'KEYWORD', 'CATEGORY', 'DEVICE_OS', 'DEVICE_TYPE', 'COUNTRY', 'REGION', 'CITY', 'CLICKED_CATEGORY', 'CONVERTED_CATEGORY'];

export const DEVICE_TYPES = ['CONSOLE', 'DESKTOP', 'READER', 'MOBILE', 'OTHERS', 'TABLET', 'TV'];

export const DEVICE_OS_TYPES = ['ANDROID', 'APPLE', 'OTHERS', 'WINDOWS'];