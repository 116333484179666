/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiCodemirror from 'angular-ui-codemirror';
import textAngular from 'textangularjs';

import {MEDIA_TYPE, MediaTypeHelper} from "../../../components/util/media-type-helper";
import {BASIC_LAYOUT_BUNCH} from "../layout-example/examples/basic-layouts";

import standardFormModule from '../../common/standard-form-module';
import listHelper from '../../common/controller/list-helper';
import {adnFormHelper} from "../../common/controller/form-helper";
import {LAYOUT_BUNCH} from "../layout-example/examples/other-layouts";

const MODULE_NAME = 'layout-controller';

angular.module(MODULE_NAME, [
  uiCodemirror,
  textAngular,
  standardFormModule,
  listHelper
])

  .controller('LayoutCtrl', function(LayoutInclude, Creative, LocalUserPermissions, Team, LocalNetworkProfile, localUserProfile, adnListHelper, $stateParams, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;
    const defaultLayouts = {
      STANDARD: BASIC_LAYOUT_BUNCH.standard,
      HTML: BASIC_LAYOUT_BUNCH.html,
      THIRD_PARTY: BASIC_LAYOUT_BUNCH.thirdParty,
      VAST: BASIC_LAYOUT_BUNCH.vastUpload,
      VAST_TEXT: BASIC_LAYOUT_BUNCH.vastExternalHosted,
      NATIVE: LAYOUT_BUNCH.nativeBasic
    };
    let prevLayoutType = 'STANDARD';

    ctrl.toolbarButtons = [
      ['h1', 'h2', 'h3', 'p'],
      ['bold', 'italics', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
      ['insertImage', 'insertLink', 'html']
    ];

    ctrl.isSuiPlatform = LocalNetworkProfile.isSuiPlatform();

    ctrl.canUpdateLayout = LocalUserPermissions.hasNetwork('MANAGE_LAYOUTS') || LocalUserPermissions.hasNetwork('AX_PUBLISHER_ADMIN');

    const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
    ctrl.showAdnuntiusApproved = LocalNetworkProfile.isExperimental() && isSpecialUser;
    ctrl.showSpecialType = LocalNetworkProfile.isPureMarketplacePlatform() && isSpecialUser;

    Team.query({minimal: true, filterBy: 'type', filterByValue: 'AX_PUBLISHER'}).$promise.then(function(page) {
      ctrl.allPublisherTeams = page.results;
      ctrl.showOwnership = LocalUserPermissions.hasNetwork('MANAGE_LAYOUTS') && ctrl.allPublisherTeams.length > 0;
      ctrl.thumbnailSecondColumn = ctrl.ownership === 'SPECIFIC_TEAM' || ctrl.showOwnership;
    });

    ctrl.needsVerificationCheck = LocalNetworkProfile.isVerificationCheckNetwork(LocalUserPermissions.hasNetwork('MANAGE_SYSTEM'));

    ctrl.transcodeOutputs = LocalNetworkProfile.getTranscodeOutputs();

    const makeDefaultMandatory = function(ac) {
        if (!ac.required) {
          ac.required = 'MANDATORY';
        }
      },
      getLayoutIncludes = function() {
        let layoutIncludeIds = _.map(ctrl.model.layoutIncludes, 'id');
        if (layoutIncludeIds.length < 1) {
          ctrl.layoutIncludes = [];
          return;
        }
        LayoutInclude.query(layoutIncludeIds, function(data) {
          ctrl.layoutIncludes = _.map(data.results, function(li) {
            if (li.renderTemplate.length > 1000) {
              li.renderTemplateShort = li.renderTemplate.substring(0, 1200);
            }
            return li;
          });
        });
      },
      afterInitAfterSave = function() {
        ctrl.ownership = ctrl.model.ownerTeam || ctrl.model.teams.length > 0 || LocalUserPermissions.hasNetwork('AX_PUBLISHER_ADMIN') ? 'SPECIFIC_TEAM' : 'NETWORK';
        ctrl.thumbnailSecondColumn = ctrl.ownership === 'SPECIFIC_TEAM' || ctrl.showOwnership;
        ctrl.model.approvalStatus = ctrl.model.approvalStatus || 'DEFAULT';
      },
      afterInit = function() {
        ctrl.model.layoutType = ctrl.model.layoutType || 'STANDARD';
        ctrl.model.layoutComponents = ctrl.model.layoutComponents || [];
        ctrl.model.layoutType = ctrl.model.layoutType === 'VAST_2' ? 'VAST' : ctrl.model.layoutType;

        ctrl.restrictOn = true;

        ctrl.layouts = angular.copy(defaultLayouts);
        if (!ctrl.isNew) {
          ctrl.layouts[ctrl.model.layoutType] = _.pick(ctrl.model, ['renderTemplate', 'layoutComponents']);
        }
        prevLayoutType = ctrl.model.layoutType;
        _.forEach(ctrl.model.layoutComponents, function(c) {
          if (c.type === 'CHOICE' && _.isArray(c.options)) {
            c.optionsAsString = JSON.stringify(c.options);
          }
        });
        _.forEach(ctrl.model.layoutComponents, makeDefaultMandatory);
        getLayoutIncludes();
        ctrl.model.renderOption = ctrl.model.renderOption || null;
        ctrl.model.teams = _.map(ctrl.model.teams, 'id');
      },
      afterSave = function(savedModel) {
        if (!ctrl.query) {
          ctrl.makeQuery();
        }
        ctrl.model.layoutIncludes = savedModel.layoutIncludes;
        ctrl.model.passesSafeChecks = savedModel.passesSafeChecks;
        getLayoutIncludes();
        ctrl.model.renderOption = ctrl.model.renderOption || null;
        _.set(ctrl.model, 'teams', _.map(_.get(savedModel, 'teams') || [], 'id'));
      };
    adnFormHelper.setUpForm(ctrl, model, {afterSave: afterSave, afterInit: afterInit, afterInitAfterSave: afterInitAfterSave, modelComms: modelComms});

    const moreParams = {layout: model.id, excludeInvalid: ctrl.restrictOn};
    adnListHelper.setUpBasicList(ctrl, Creative, 'fullCreativesforLayout', {moreParams: moreParams, noInitQuery: true});
    if (!ctrl.isNew) {
      ctrl.makeQuery();
    }

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    Team.query({minimal: true, filterBy: 'type', filterByValue: 'AX_ADVERTISER'}).$promise.then(function(page) {
      ctrl.allTeams = page.results;
    });

    ctrl.restrictCondition = function() {
      ctrl.restrictOn = !ctrl.restrictOn;
      moreParams.excludeInvalid = ctrl.restrictOn;
      ctrl.setMoreParams(moreParams);
      ctrl.makeQuery();
    };

    modelComms.addSubscriber(function(data, type) {
      if (data.layoutComponents && !type) {
        ctrl.model = data;
        ctrl.layoutIncludes = [];
        ctrl.layouts[ctrl.model.layoutType].renderTemplate = data.renderTemplate;
        ctrl.layouts[ctrl.model.layoutType].layoutComponents = data.layoutComponents;
        ctrl.editsMade();
      }
    }, true);

    ctrl.showCreativeDetail = false;

    const assignMediaType = function(ac) {
      if (!ac.mediaType) {
        if (_.isArray(ac.mimeTypes) && ac.mimeTypes.length > 0) {
          let mediaType = MediaTypeHelper.getMediaType(ac.mimeTypes[0]);
          ac.mediaType = mediaType.image ? 'image' : mediaType.id;
          ac.mediaType = mediaType.video ? 'video' : ac.mediaType;
          ac.mediaType = mediaType.audio ? 'audio' : ac.mediaType;

          if (mediaType.other) {
            ac.mediaType = "other";
            ac.mediaTypeSel = mediaType;
          }
        } else {
          ac.mediaType = 'image';
        }
      }
    };

    _.forEach(ctrl.model.layoutComponents, function(ac) {
      if (ac.type === 'ASSET') {
        ac.maxFileSizeKb = ac.maxFileSizeBytes > 0 ? ac.maxFileSizeBytes / 1024 : 0;
        assignMediaType(ac);
      }
    });

    ctrl.otherMediaTypes = MediaTypeHelper.getOtherMediaTypes();

    ctrl.updateOtherMediaType = function(ac) {
      ac.mimeTypes = ac.mediaTypeSel ? MEDIA_TYPE[ac.mediaTypeSel.id].mimeTypes : [];
    };

    ctrl.updateMimeTypes = function(ac) {
      if (ac.mediaType === 'image') {
        ac.mimeTypes = MediaTypeHelper.getImageMimeTypes();
      } else if (ac.mediaType === 'video') {
        ac.mimeTypes = MediaTypeHelper.getVideoMimeTypes();
      } else if (ac.mediaType === 'audio') {
        ac.mimeTypes = MediaTypeHelper.getAudioMimeTypes();
      } else if (ac.mediaType === 'other') {
        ac.mimeTypes = [];
      } else {
        ac.mimeTypes = MEDIA_TYPE[ac.mediaType].mimeTypes;
      }

      let mediaTypes = ['image', 'flash', 'html', 'video', 'audio', 'other'];
      _.forEach(mediaTypes, function(mediaType) {
        ac.tag = ac.tag.replace(mediaType, ac.mediaType);
      });
    };

    ctrl.toggleEditAc = function(form, ac) {
      ac.edited = !ac.edited;
      assignMediaType(ac);
      ctrl.editsMade(form);
    };

    let generateUniqueTagAndName = function(base, constraintType, count) {
      count = _.isFinite(count) ? count : 1;
      let name = base + (count > 1 ? count : '');
      return _.findIndex(ctrl.layouts[ctrl.model.layoutType][constraintType], ['name', name]) < 0 ? {tag: name.toLowerCase().replace(' ', ''), name: name} : generateUniqueTagAndName(base, constraintType, count + 1);
    };

    ctrl.addAc = function(form) {
      ctrl.layouts[ctrl.model.layoutType].layoutComponents = ctrl.layouts[ctrl.model.layoutType].layoutComponents || [];

      let data = generateUniqueTagAndName('Image ', 'layoutComponents');
      ctrl.layouts[ctrl.model.layoutType].layoutComponents.push({
        tag: data.tag,
        name: data.name,
        mediaType: 'image',
        maxFileSizeBytes: 204800,
        maxFileSizeKb: 204800 / 1024,
        mimeTypes: MediaTypeHelper.getImageMimeTypes(),
        assets: [],
        type: 'ASSET',
        saveEarlyForPreview: true,
        required: 'MANDATORY'
      });
      ctrl.editsMade(form);
    };

    ctrl.sortUp = function(form, ac, sortDown) {
      let lcs = angular.copy(ctrl.layouts[ctrl.model.layoutType].layoutComponents);

      let count = 1;
      _.forEach(lcs, function(lc) {
        let changer = (lc.tag === ac.tag && lc.type === ac.type) ? -15 : 0;
        changer = changer * (sortDown ? -1 : 1);
        lc.order = count + changer;
        count += 10;
      });
      lcs = _.sortBy(lcs, 'order');
      ctrl.layouts[ctrl.model.layoutType].layoutComponents = _.map(lcs, function(lc) {
        delete lc.order;
        return lc;
      });
      ctrl.editsMade(form);
    };

    ctrl.sortDown = function(form, ac) {
      ctrl.sortUp(form, ac, true);
    };

    ctrl.addUrl = function(form) {
      ctrl.layouts[ctrl.model.layoutType].layoutComponents = ctrl.layouts[ctrl.model.layoutType].layoutComponents || [];

      let data = generateUniqueTagAndName('URL ', 'layoutComponents');
      ctrl.layouts[ctrl.model.layoutType].layoutComponents.push({
        type: 'URL',
        tag: data.tag,
        name: data.name,
        required: 'MANDATORY'
      });
      ctrl.editsMade(form);
    };

    ctrl.addTextAc = function(form) {
      ctrl.layouts[ctrl.model.layoutType].layoutComponents = ctrl.layouts[ctrl.model.layoutType].layoutComponents || [];

      let data = generateUniqueTagAndName('Text ', 'layoutComponents');
      ctrl.layouts[ctrl.model.layoutType].layoutComponents.push({
        tag: data.tag,
        name: data.name,
        minLength: 0,
        maxLength: 50,
        type: 'TEXT',
        required: 'MANDATORY',
        textType: 'TEXT'
      });
      ctrl.editsMade(form);
    };

    ctrl.import = function(form) {
      ctrl.layouts[ctrl.model.layoutType] = defaultLayouts[ctrl.model.layoutType];
      ctrl.editsMade(form);
    };

    ctrl.typeSwitch = function() {
      if (ctrl.isNew) {
        return;
      }
      const prevLayoutDetails = ctrl.layouts[prevLayoutType];
      ctrl.layouts[ctrl.model.layoutType] = angular.copy(prevLayoutDetails);
      prevLayoutType = ctrl.model.layoutType;
    };

    ctrl.addChoiceAc = function(form) {
      ctrl.layouts[ctrl.model.layoutType].layoutComponents = ctrl.layouts[ctrl.model.layoutType].layoutComponents || [];

      let data = generateUniqueTagAndName('Choice ', 'layoutComponents');
      let defaultOptions = ['default1', 'default2'];
      ctrl.layouts[ctrl.model.layoutType].layoutComponents.push({
        tag: data.tag,
        name: data.name,
        type: 'CHOICE',
        display: 'COMBO',
        options: defaultOptions,
        optionsAsString: JSON.stringify(defaultOptions),
        required: 'MANDATORY'
      });
      ctrl.editsMade(form);
    };

    ctrl.removeComponent = function(form, ac) {
      let param = 'layoutComponents';
      ctrl.layouts[ctrl.model.layoutType][param] = _.filter(ctrl.layouts[ctrl.model.layoutType][param], function(sAc) {
        return sAc.tag !== ac.tag;
      });
      ctrl.editsMade(form);
    };

    ctrl.beforeSubmit = function(form) {
      ctrl.model.renderTemplate = ctrl.layouts[ctrl.model.layoutType].renderTemplate;
      ctrl.model.layoutComponents = ctrl.layouts[ctrl.model.layoutType].layoutComponents;

      _.forEach(ctrl.model.layoutComponents, function(ac) {
        if (ac.type === 'ASSET') {
          if (ac.maxFileSizeKb) {
            ac.maxFileSizeBytes = ac.maxFileSizeKb * 1024;
          }
        }
        if (ac.type === 'TEXT' && ac.textType === 'CODE') {
          ac.maxLength = 0;
        }
        if (ac.type === 'CHOICE') {
          try {
            ac.options = JSON.parse(ac.optionsAsString);
          } catch (e) {
            form.clientErrors.push({code: 'choiceValidJavascript', text: 'The options for the tag ' + ac.tag + ' are not in a form that is valid JavaScript.'});
            return false;
          }
          if (!_.isArray(ac.options)) {
            form.clientErrors.push({code: 'choiceArray', text: 'The options for the tag ' + ac.tag + ' must be in a JavaScript array.'});
            return false;
          }
          _.forEach(ac.options, function(o) {
            if (!_.isString(o)) {
              form.clientErrors.push({code: 'choiceValidJavascriptString', text: 'The options for the tag ' + ac.tag + ' that are in the array must be each be a valid JavaScript string.'});
              return false;
            }
          });
        }
      });
      return false;
    };
  });

export default MODULE_NAME;