/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import standardFormModule from '../common/standard-form-module';
import standardAnalysisModule from '../common/standard-analysis-module';
import {adnFormHelper} from "../common/controller/form-helper";

const MODULE_NAME = "folder-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent
])

  .controller('FolderCtrl', function($stateParams, Team, model, modelComms) {
    const ctrl = this;
    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        ctrl.model.dataSource = ctrl.model.dataSource || "ADNUNTIUS";
        ctrl.model.extData = _.map(ctrl.model.data, function(value, key) {
          return {key: key, value: value};
        });
      },
      afterSave: function(data) {
        ctrl.teamsWarning = (_.get(ctrl.model, 'teams') || []).length !== (_.get(data, 'teams') || []).length;
        _.set(ctrl.model, 'teams', _.map(_.get(data, 'teams') || [], 'id'));
      }
    });
    ctrl.model.teams = _.map(ctrl.model.teams, 'id');

    ctrl.isNew = $stateParams.isNew;

    let teamRefresh = function() {
      Team.query(function(page) {
        ctrl.teams = _.map(page.results, function(sg) {
          let indexedFolders = _.keyBy(sg.folders, 'id');
          return _.assign({}, _.pick(sg, ['name', 'description', 'id', 'folders']), {indexedFolders: indexedFolders, isMatched: !!indexedFolders[model.id]});
        });

        ctrl.matchedTeams = _.filter(ctrl.teams, {isMatched: true});
      });
    };

    ctrl.addData = function() {
      ctrl.model.extData = ctrl.model.extData || [];
      ctrl.model.extData.push({key: "", value: ""});
      ctrl.editsMade();
    };
    ctrl.eventHook = {};

    ctrl.deleteData = function(index) {
      _.pullAt(ctrl.model.extData, index);
      ctrl.editsMade();
    };

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    ctrl.canUpdateFolder = true;
    if (ctrl.canUpdateFolder) {
      teamRefresh();
    }

    Team.query().$promise.then(function(page) {
      ctrl.allTeams = page.results;
    });

    ctrl.onBeforeSubmit = function() {
      ctrl.model.data = {};
      _.forEach(_.filter(ctrl.model.extData, function(datum) {
        return _.trim(datum.value).length > 0 && _.trim(datum.key).length > 0;
      }), function(datum) {
        ctrl.model.data[datum.key] = datum.value;
      });
    };
  });

export default MODULE_NAME;