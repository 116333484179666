/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import 'angular-gridster';

import {ADN_GRIDSTER_CONFIG} from "../config/gridster-config";
import {REPORT_OPTIONS} from "../common/report-translations";

import reportTemplateWidgetModal from './report-widget-modal.html';

import standardFormModule from '../common/standard-form-module';
import {ADN_REPORT_COMPONENTS, ADN_REPORT_TYPES} from '../report/report-types';
import {adnFormHelper} from "../common/controller/form-helper";
import {TOOLBAR_BUTTONS} from "../common/directives/notes/notes-constants.ts";

const MODULE_NAME = 'report-template-controller';

angular.module(MODULE_NAME, [
  'gridster',
  standardFormModule
])

  .controller('ReportTemplateCtrl', function($uibModal, model, reportTranslationDefaultsResource, LocalUserPermissions, ReportTranslation, Team, modelComms, $filter, LocalNetworkProfile) {
    const ctrl = this;
    let lastComponentId = 0;
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.showTeams = LocalNetworkProfile.isPureMarketplacePlatform();
    const teamParams = {minimal: true};
    if (ctrl.perms.AX_ADVERTISER_ADMIN === true) {
      teamParams.filterBy = 'type';
      teamParams.filterByValue = 'AX_ADVERTISER';
    }
    if (ctrl.perms.AX_PUBLISHER_ADMIN === true) {
      teamParams.filterBy = 'type';
      teamParams.filterByValue = 'AX_PUBLISHER';
    }
    Team.query(teamParams).$promise.then(function(page) {
      ctrl.teams = page.results || [];
    });

    const componentInit = function(c) {
        c.parameters.dateFormat = c.parameters.dateFormat || '';
        if (!Object.prototype.hasOwnProperty.call(c.parameters, 'dateTimeFormatLineItem')) {
          c.parameters.timeFormatLineItem = 'HOUR24';
          c.parameters.dateTimeFormatLineItem = '';
        } else {
          c.parameters.dateTimeFormatLineItem = c.parameters.dateTimeFormatLineItem || '';
          if (c.parameters.dateTimeFormatLineItem.indexOf("k") > 0) {
            c.parameters.timeFormatLineItem = 'HOUR24';
          } else if (c.parameters.dateTimeFormatLineItem.indexOf("h") > 0) {
            c.parameters.timeFormatLineItem = 'AMPM';
          } else if (c.parameters.dateTimeFormatLineItem.length > 1) {
            c.parameters.timeFormatLineItem = 'NONE';
          } else {
            c.parameters.timeFormatLineItem = 'HOUR24';
          }
        }
        const refComponent = ADN_REPORT_COMPONENTS[c.reportComponent.id];
        c.csv = _.get(refComponent, ['csv']) || false;
        c.translationOverrides = refComponent.translationOverrides;
      },
      init = function() {
        ctrl.model.components = ctrl.model.components || [];
        ctrl.model.parameters = ctrl.model.parameters || {};
        ctrl.model.reportFormat = ctrl.model.reportFormat || 'HTML';
        ctrl.logoSpecify = ctrl.logoSpecify || !!ctrl.model.logo;

        ctrl.model.parameters.translation = ctrl.model.parameters.translation || 'DEFAULT';

        if (!ctrl.translations) {
          reportTranslationDefaultsResource.get().then(function(transDefaults) {
            ctrl.translations = transDefaults;
            _.forEach(ctrl.translations.en, function(val, key) {
              ctrl.model.parameters[key] = ctrl.model.parameters[key] || $filter('adnFieldTranslate')('report.field.' + _.camelCase(key.replace("translation", "")), val);
            });
          });
        }

        _.forEach(ADN_REPORT_TYPES, function(rt) {
          ctrl[rt.id] = ctrl[rt.id] || {};
          ctrl[rt.id].components = ctrl[rt.id].components || [];
        });
        if (model.reportType && model.reportType === ctrl.model.reportType) {
          ctrl[ctrl.model.reportType].components = _.get(model, 'components') || [];
          _.forEach(ctrl[ctrl.model.reportType].components, function(c) {
            if (!_.isFinite(c.uniqueId)) {
              c.uniqueId = ++lastComponentId;
            }
          });
        }

        if (ctrl.model.reportType) {
          _.forEach(ctrl[ctrl.model.reportType].components, function(c) {
            // New addition of heading parameter needs to work with already saved report templates that don't have a heading saved as a parameter
            if (!c.parameters.heading) {
              c.parameters.heading = ADN_REPORT_COMPONENTS[c.reportComponent.id].parameters.heading;
            }
            if (Object.prototype.hasOwnProperty.call(ADN_REPORT_COMPONENTS[c.reportComponent.id].parameters, 'sharedYAxis') && !Object.prototype.hasOwnProperty.call(c.parameters, 'sharedYAxis')) {
              c.parameters.sharedYAxis = ADN_REPORT_COMPONENTS[c.reportComponent.id].parameters.sharedYAxis;
            }
            if (Object.prototype.hasOwnProperty.call(ADN_REPORT_COMPONENTS[c.reportComponent.id].parameters, 'height') && !Object.prototype.hasOwnProperty.call(c.parameters, 'height')) {
              c.parameters.height = ADN_REPORT_COMPONENTS[c.reportComponent.id].parameters.height;
            }
            componentInit(c);
          });
        }
      };
    adnFormHelper.setUpForm(ctrl, model, {
      afterInit: init, modelComms: modelComms, afterSave: function() {
        _.forEach(ctrl.model.components, function(c) {
          componentInit(c);
        });
      }
    });

    ReportTranslation.query().$promise.then(function(data) {
      ctrl.reportTranslations = data.results;
    });

    const reportOptions = _.find(REPORT_OPTIONS, function(ro) {
      return ro.id === LocalNetworkProfile.getNetworkId();
    }) || {};

    ctrl.availableLangs = {};
    _.forEach(reportOptions.langs || ['en'], function(lang) {
      ctrl.availableLangs[lang] = lang;
    });

    ctrl.updateTranslations = function(langKey) {
      _.forEach(ctrl.translations.en, function(val, key) {
        ctrl.model.parameters[key] = (ctrl.translations[langKey] || ctrl.translations.en)[key];
      });
      ctrl.editsMade();
    };

    LocalNetworkProfile.isSuiPlatform();
    ctrl.reportTypes = _.map(_.filter(ADN_REPORT_TYPES, function(rt) {
      return rt.id !== 'SELF_SERVICE' || LocalNetworkProfile.isSuiPlatform();
    }), function(rt) {
      return rt.id;
    });

    ctrl.toolbarButtons = TOOLBAR_BUTTONS;
    ctrl.dateFormats = {};
    ctrl.dateFormats.NONE = [
      {id: 0, format: '', example: 'Use locale defined in my user profile'},
      {id: 1, format: 'd/M/yyyy', example: '23/10/2018'},
      {id: 2, format: 'd-M-yyyy', example: '23-10-2018'},
      {id: 3, format: 'd.M.yyyy', example: '23.10.2018'},
      {id: 4, format: 'd MMM yyyy', example: '23 Oct 2018'},
      {id: 5, format: 'd-MMM-yyyy', example: '23-Oct-2018'},
      {id: 6, format: 'd.MMM.yyyy', example: '23.Oct.2018'},
      {id: 7, format: 'M/d/yyyy', example: '10/23/2018'},
      {id: 8, format: 'M-d-yyyy', example: '10-23-2018'},
      {id: 9, format: 'M.d.yyyy', example: '10.23.2018'},
      {id: 10, format: 'd MMM yyyy', example: '10 Oct 2018'},
      {id: 11, format: 'd-MMM-yyyy', example: '10-Oct-2018'},
      {id: 12, format: 'd.MMM.yyyy', example: '10.Oct.2018'},
      {id: 13, format: 'yyyy/M/d', example: '2018/10/23'},
      {id: 14, format: 'yyyy-M-d', example: '2018-10-23'},
      {id: 15, format: 'yyyy.M.d', example: '2018.10.23'},
      {id: 16, format: 'yyyy MMM d', example: '2018 Oct 10'},
      {id: 17, format: 'yyyy-MMM-d', example: '2018-Oct-10'}
    ];

    ctrl.dateFormats.HOUR24 = [];
    ctrl.dateFormats.AMPM = [];
    _.forEach(ctrl.dateFormats.NONE, function(entry) {
      const hour24Entry = {id: entry.id, format: entry.format, example: entry.example};
      const amPmEntry = {id: entry.id, format: entry.format, example: entry.example};
      if (entry.format.length > 0) {
        hour24Entry.format += " k:mm";
        hour24Entry.example += " 18:49";
        amPmEntry.format += " h:mm a";
        amPmEntry.example += " 6:49 PM";
      }
      ctrl.dateFormats.HOUR24.push(hour24Entry);
      ctrl.dateFormats.AMPM.push(amPmEntry);
    });

    ctrl.timeChange = function(component) {
      const allFormats = _.flattenDeep(ctrl.dateFormats.NONE.concat(ctrl.dateFormats.HOUR24).concat(ctrl.dateFormats.AMPM));
      const prevFormat = _.find(allFormats, function(df) {
        return df.format === component.parameters.dateTimeFormatLineItem;
      });
      const newFormat = _.find(ctrl.dateFormats[component.parameters.timeFormatLineItem], function(df) {
        return df.id === prevFormat.id;
      });
      component.parameters.dateTimeFormatLineItem = newFormat.format;
    };

    ctrl.widgets = ADN_REPORT_COMPONENTS;
    ctrl.gridsterOpts = _.merge(ADN_GRIDSTER_CONFIG.RESOURCES, {
      draggable: {
        stop: function() {
          ctrl.editsMade();
        }
      }
    });

    ctrl.addComponents = function() {
      $uibModal.open({
        template: reportTemplateWidgetModal,
        resolve: {
          components: function() {
            return _.filter(ADN_REPORT_TYPES[ctrl.model.reportType].components, function(c) {
              return ctrl.model.reportFormat !== 'CSV' || c.csv;
            });
          }
        },
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function(components) {
          const modalCtrl = this;
          modalCtrl.components = components;
          modalCtrl.selections = {};

          modalCtrl.toggleSelection = function(component) {
            if (modalCtrl.selections[component.id]) {
              delete modalCtrl.selections[component.id];
            } else {
              modalCtrl.selections[component.id] = component;
            }
          };
        },
        size: 'lg'
      })
        .result.then(function(components) {
        if (components) {
          _.forEach(components, function(c) {
            const pushedComponent = {
              reportComponent: {
                id: c.id
              },
              parameters: angular.copy(c.parameters),
              uniqueId: ++lastComponentId
            };
            componentInit(pushedComponent);
            ctrl[ctrl.model.reportType].components.push(pushedComponent);
          });
          ctrl.editsMade();
        }
      });
    };

    ctrl.removeComponent = function(component) {
      ctrl.editsMade();
      _.pull(ctrl[ctrl.model.reportType].components, component);
    };

    ctrl.onBeforeReportTemplateSubmit = function() {
      ctrl.model.components = _.get(ctrl, [ctrl.model.reportType, 'components']) || {};
      ctrl.model.components = _.sortBy(ctrl.model.components, 'row');

      if (!ctrl.logoSpecify) {
        ctrl.model.logo = "";
      }

      function doTranslation(c, paramApi, paramUi) {
        const paramToUse = _.get(c, ['translationOverrides', paramUi]) || paramUi;
        c.parameters[paramApi] = $filter('adnFieldTranslate')('report.field.' + paramToUse);
      }

      if (ctrl.model.reportTranslation) {
        const deleteTranslationsKeys = [];
        _.forEach(ctrl.model.components, function(c) {
          _.forEach(c.parameters, function(val, key) {
            if (_.startsWith(key, 'translation')) {
              deleteTranslationsKeys.push(key);
            }
          });
        });
        _.forEach(ctrl.model.parameters, function(val, key) {
          deleteTranslationsKeys.push(key);
        });
        _.forEach(deleteTranslationsKeys, function(dtk) {
          _.forEach(ctrl.model.components, function(c) {
            delete c.parameters[dtk];
          });
          delete ctrl.model.parameters[dtk];
        });
      } else {
        _.forEach(ctrl.model.components, function(c) {
          doTranslation(c, 'translationImpressions', 'impressions');
          doTranslation(c, 'translationRendered', 'rendered');
          doTranslation(c, 'translationRenderedRate', 'renderedRate');
          doTranslation(c, 'translationEarnings', 'earnings');
          doTranslation(c, 'translationRevenueShare', 'revenueShare');
        });

        ctrl.model.parameters = ctrl.model.parameters || {};
        if (ctrl.model.reportType === 'SELF_SERVICE') {
          ctrl.model.parameters.translationRendered = ctrl.model.parameters.translationRendered || "Impressions";

          _.forEach(ctrl.model.components, function(c) {
            c.parameters.translationRendered = ctrl.model.parameters.translationRendered;
          });
        }
        if (ctrl.model.reportType !== 'SELF_SERVICE') {
          _.forEach(_.get(ctrl.model, ['components', 0, 'parameters']), function(val, key) {
            ctrl.model.parameters[key] = val;
          });
        }
      }
    };
  });

export default MODULE_NAME;