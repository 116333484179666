/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

export class VersionHistoryResource {
  private readonly uri = ApiConfig.obtain().getVersionedInternalUri('versionhistory');

  constructor(@Inject('$http') private $http: any) {
  }

  get(id: string, type: string) {
    return this.$http.get(this.uri, {
      params: _.assign({}, {id: id, type: type, pageSize: 30})
    }).then(_.iteratee('data'));
  }
}
