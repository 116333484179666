/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './cdn-upload-edit.html';
import listTemplate from './cdn-upload-list.html';
import deleteTemplate from "../../common/delete-actions.html";

import adminModule from '../admin-module';
import cdnUploadController from './cdn-upload-controller';
import assetDirective from '../../creative/asset/asset-directive';

const MODULE_NAME = 'cdn-upload-module';

angular.module(MODULE_NAME, [adminModule, cdnUploadController, assetDirective])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('cdn-uploads.cdn-upload', 'CdnAsset', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        orderByParam: function() {
          return 'width';
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'CdnUploadCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          }
        }
      }, 'CdnUpload'));
  });

export default MODULE_NAME;