import {BASIC_LAYOUT_BUNCH} from "./basic-layouts";
import {LAYOUT_BUNCH} from './other-layouts';

export const ALL_LAYOUTS = [
  BASIC_LAYOUT_BUNCH.standard,
  LAYOUT_BUNCH.imageStreaming,
  LAYOUT_BUNCH.imageResponsive,
  LAYOUT_BUNCH.breakOut,
  LAYOUT_BUNCH.overlay,
  LAYOUT_BUNCH.native,
  LAYOUT_BUNCH.native2,
  LAYOUT_BUNCH.interstitial,
  LAYOUT_BUNCH.parallax,
  LAYOUT_BUNCH.responsiveScaled,
  LAYOUT_BUNCH.imageSelection,
  LAYOUT_BUNCH.imageRollover,
  LAYOUT_BUNCH.video,
  LAYOUT_BUNCH.audio,
  LAYOUT_BUNCH.googlePassback,
  BASIC_LAYOUT_BUNCH.html,
  BASIC_LAYOUT_BUNCH.thirdParty,
  LAYOUT_BUNCH.vastThirdParty,
  BASIC_LAYOUT_BUNCH.vastUpload,
  BASIC_LAYOUT_BUNCH.vastStreaming,
  BASIC_LAYOUT_BUNCH.vastVpaid,
  BASIC_LAYOUT_BUNCH.vastExternalHosted,
  BASIC_LAYOUT_BUNCH.vastAudioUpload
];
