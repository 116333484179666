import {Inject, Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'ogTranslate'})
export class OgTranslatePipe implements PipeTransform {
  constructor(@Inject('$translate') private $translate: any, @Inject('LocalNetworkProfile') private localNetworkProfile: any) {
  }

  transform(key): string {
    const networkTranslation = this.localNetworkProfile.getNetworkTranslations(key);
    if (networkTranslation) {
      return networkTranslation;
    }
    return this.$translate.instant(key);
  }
}