/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = 'form-control-wrapper-directive';

angular.module(MODULE_NAME, [])

  .directive('adnFormControlWrapper', function() {
    return {
      restrict: 'A',
      scope: true,
      require: ['adnFormControlWrapper', '?^^adnFormControlWrapper'],
      priority: 1,
      controller: function($scope, $element, $attrs) {
        let controls = [],
          errorClass = $attrs.errorClass || 'has-error',
          warningClass = 'has-warning',
          defaultClass = $attrs.defaultClass,
          ctrl = this;

        this.addControl = function(control) {
          controls.push(control);
          return this;
        };

        this.removeControl = function(name) {
          _.remove(controls, function(c) {
            return name === c.$name;
          });
        };

        $scope.$watch(function() {
          const hasError = _.some(controls, '$invalid') ? 1 : 0;
          const hasValidation = _.some(controls, 'validation') ? 10 : 0;
          return hasError + hasValidation;
        }, function(watchResult) {
          const hasError = watchResult % 10 === 1;
          const hasValidation = watchResult >= 10;

          $element.toggleClass(errorClass, hasError);
          if (defaultClass) {
            $element.toggleClass(defaultClass, !hasError);
          }
          ctrl.$invalid = hasError;

          $element.toggleClass(warningClass, hasValidation);
          if (defaultClass) {
            $element.toggleClass(defaultClass, !hasValidation);
          }
        });
      },
      link: function postLink(scope, element, attrs, wrappers) {
        if (wrappers[1]) {
          wrappers[1].addControl(wrappers[0]);
        }
      }
    };
  });

export default MODULE_NAME;