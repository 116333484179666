/**
 * Copyright © 2024 Adnuntius AS.
 */
import {TypeGuard} from "../filters/guard-type.pipe";

type DashOutputConfig = {
  type: "dash"
  audioNormalisation: boolean
  dashStreamConfigs: Array<{
    maxBitrate: number
  }>
};

type AppleHlsOutputConfig = {
  type: "hls"
  audioNormalisation: boolean
  hlsStreamConfigs: Array<{
    maxBitrate: number
  }>
};

type Mpeg4OutputConfig = {
  type: "mpeg4"
  audioNormalisation: boolean
  maxBitrate: number
  height: number
};

type QuicktimeOutputConfig = {
  type: "quicktime"
  audioNormalisation: boolean
  maxBitrate: number
  height: number
};

export type TranscodeOutputConfig = DashOutputConfig | AppleHlsOutputConfig | Mpeg4OutputConfig | QuicktimeOutputConfig;

export type TranscodeConfig = {
  enabled: boolean
  outputs: Map<string, TranscodeOutputConfig>
};

export const isDashOutputConfig: TypeGuard<TranscodeOutputConfig, DashOutputConfig> =
  (pet: TranscodeOutputConfig): pet is DashOutputConfig =>
    pet.type === 'dash';

export const isHlsOutputConfig: TypeGuard<TranscodeOutputConfig, AppleHlsOutputConfig> =
  (pet: TranscodeOutputConfig): pet is AppleHlsOutputConfig =>
    pet.type === 'hls';

export const isMpeg4OutputConfig: TypeGuard<TranscodeOutputConfig, Mpeg4OutputConfig> =
  (pet: TranscodeOutputConfig): pet is Mpeg4OutputConfig =>
    pet.type === 'mpeg4';

export const isQuicktimeOutputConfig: TypeGuard<TranscodeOutputConfig, QuicktimeOutputConfig> =
  (pet: TranscodeOutputConfig): pet is QuicktimeOutputConfig =>
    pet.type === 'quicktime';

