/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input, OnChanges, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'line-item-status-label',
  template: `<span class="label {{labelClass[adjustedState]}}">{{ "lineItem.userState." + adjustedState | translate }}</span>`
})
export class LineItemStatusLabelComponent implements OnInit, OnChanges {
  labelClass: Record<string, string>;
  @Input() userState: string;
  @Input() rejectedReasonsLength = 0;
  adjustedState: string;

  constructor(public translate: TranslateService) {
  }

  ngOnChanges() {
    this.updateValues();
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.updateValues();

    this.labelClass['INCOMPLETE'] = 'label-warning';
    this.labelClass['APPROVED'] = 'label-success';
    this.labelClass['PAUSED'] = 'label-warning';
    this.labelClass['PROPOSED'] = 'label-info';
    this.labelClass['REJECTED'] = 'label-danger';
    this.labelClass['SUBMITTED'] = 'label-primary';
    this.labelClass['RESERVED'] = 'label-default';
    this.labelClass['STOPPED'] = 'label-danger';
    this.labelClass['COMPLETED'] = 'label-success';
  }

  private updateValues() {
    this.adjustedState = this.userState;
    if (this.userState !== 'SUBMITTED' && this.rejectedReasonsLength) {
      this.adjustedState = 'REJECTED';
    }
  }
}