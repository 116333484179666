import _ from "lodash";

const BAD_WORD_LIST = ["fuck", "shit", "piss", "porn", "tits", "breast", "cock", "dick", "pussy", "cunt", "gambling", "gamble", "bet", "sex"];



function flattenUserSegments(object) {
  return _.flatten(_.map(_.get(object, ['targeting', 'userSegmentTargets']), function(us) {
    return us.userSegments;
  }));
}

export const campaignCommons = function(ctrl) {
  return {
    setUpComponents(params) {
      ctrl.maxHeight = 100;
      ctrl.maxWidth = 100;

      const collection = _.get(ctrl, params);
      if (!collection) {
        return;
      }
      _.forEach(_.isArray(collection) ? collection : [collection], function(item) {
        _.forEach(item.creatives, function(creative) {

          creative.creative.vm = creative.creative.vm || {};
          creative.creative.vm.userSegments = flattenUserSegments(creative.creative);

          const layout = _.get(creative, ['layout']);
          const assets = _.get(creative, ['assets']);
          creative.components = [];
          item.badWords = [];
          _.forEach(_.get(creative, ['creative', 'layoutParameters']), function(val, key) {
            const lc = _.find(layout.layoutComponents, function(lc) {
              return lc.tag === key;
            });
            let data = _.cloneDeep(val);
            if (lc.type === 'ASSET') {
              const asset = _.find(assets, function(a) {
                return a.id === val.id;
              });
              data.cdnId = asset.cdnId;
              data.width = asset.width;
              data.height = asset.height;
              const width = asset.width,
                height = asset.height,
                ratio = width > height ? ctrl.maxWidth / width : ctrl.maxHeight / height;
              data.style = "zoom:(" + ratio + ");-moz-transform: scale(" + ratio + "); -moz-transform-origin: 0 0; -o-transform: scale(" + ratio + "); -o-transform-origin: 0 0; -webkit-transform: scale(" + ratio + "); -webkit-transform-origin: 0 0;";
            } else if (lc.type === 'TEXT') {
              item.badWords = _.uniq(item.badWords.concat(_.filter(BAD_WORD_LIST, function(word) {
                return val.toLowerCase().indexOf(word) > -1;
              })));
            }
            creative.components.push({
              type: lc.type,
              key: lc.name || key,
              value: data
            });
          });
          item.badWords = item.badWords.join(', ');
        });
      });
    }
  };
};