/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './notification-preferences-edit.html';
import titleTemplate from '../../base/default-title-template.html';

import adminModule from '../admin-module';
import notificationsPrefsCtrl from './notification-preferences-controller';
import resources from '../../../components/api/resources/resources';

const MODULE_NAME = 'notification-prefs-module';

angular.module(MODULE_NAME, [adminModule, notificationsPrefsCtrl, resources])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('notification-preferences.notification-preferences', 'NotificationPreferences', 'app.adm', 'app/admin', null);
    $stateProvider.state('app.notification-preferences', _.assign(rh.item(), {
      resolve: {
        model: /*@ngInject*/ function($injector) {
          let NotifPrefs = $injector.get("NotificationPreferences");
          return NotifPrefs.get().$promise;
        }
      },
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'NotificationPreferencesCtrl',
          controllerAs: 'resource'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      url: '/notification-preferences',
      parent: 'app.adm'
    }));
  });

export default MODULE_NAME;