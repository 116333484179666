/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../../common/constants/object-type";

import queryTemplate from './query-edit.html';
import listTitleTemplate from '../../base/default-title-template.html';

import standardModule from '../../common/standard-module';
import queryHelper from '../../common/controller/query-helper';

const MODULE_NAME = 'query-module';

angular.module(MODULE_NAME, [standardModule, queryHelper])

  .config(function($stateProvider) {
    $stateProvider.state('app.queries', {
      url: '/queries',
      parent: 'app.rep',
      abstract: true,
      views: {
        'context@app': {},
        'title@app': {
          template: listTitleTemplate
        }
      }
    }).state('app.queries.advertiser', {
      url: '/advertiser',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.Advertiser);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Advertiser Queries"
      }
    }).state('app.queries.order', {
      url: '/order',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.Order, OBJECT_TYPE.Advertiser);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Order Queries"
      }
    }).state('app.queries.line-item', {
      url: '/line-item',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.LineItem, OBJECT_TYPE.Order, OBJECT_TYPE.Advertiser);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Line Item Queries"
      }
    }).state('app.queries.folio', {
      url: '/folio',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.Folio, OBJECT_TYPE.Advertiser);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Folio Queries"
      }
    }).state('app.queries.creative-set', {
      url: '/creative-set',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.CreativeSet, OBJECT_TYPE.Folio, OBJECT_TYPE.Advertiser);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Creative Set Queries"
      }
    }).state('app.queries.creative', {
      url: '/creative',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.Creative, OBJECT_TYPE.LineItem, OBJECT_TYPE.Order);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Creative Queries"
      }
    }).state('app.queries.earnings-account', {
      url: '/earnings-account',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.EarningsAccount);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Earnings Account Queries"
      }
    }).state('app.queries.site', {
      url: '/site',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.Site, OBJECT_TYPE.EarningsAccount);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Site Queries"
      }
    }).state('app.queries.ad-unit', {
      url: '/ad-unit',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.AdUnit, OBJECT_TYPE.Site, OBJECT_TYPE.EarningsAccount);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Ad Unit Queries"
      }
    });
  });

export default MODULE_NAME;