import angular from 'angular';

import editTemplate from './trigger-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './trigger-list.html';

import {triggerConditionFormatter} from "./trigger-util";

import standardModule from '../common/standard-module';
import triggerController from './trigger-controller';
import triggerActionsController from './trigger-actions-controller';

const MODULE_NAME = 'trigger-module';

angular.module(MODULE_NAME, [standardModule, triggerController, triggerActionsController])
  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('triggers.trigger', 'Trigger', 'app.dat-seg', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        listExtension: /*@ngInject*/ function($translate) {
          return {
            triggerFormat: function(entry) {
              return triggerConditionFormatter($translate, entry);
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'TriggerCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'TriggerActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Trigger'));
  });

export default MODULE_NAME;
