import _ from "lodash";
import bulkActionTemplate from "./creative-set-bulk-action.html";
import moment from "moment/moment";
import {lineItemCommons} from "../line-item/line-item-commons";

export const creativeSetListCommons = function(ctrl, afterFill, LocalNetworkProfile, adnTimeShifter) {
  _.forEach(ctrl.items, function(li) {
    if (li.startDate) {
      li.modStartDate = adnTimeShifter.timeShift(li.startDate);
    }
    if (li.endDate) {
      li.modEndDate = adnTimeShifter.timeShift(li.endDate);
    }
  });

  if (afterFill) {
    return;
  }

  ctrl.allFields = ['name', 'userState', {label: 'advertiser', param: 'Advertiser'}, 'startDate', 'endDate', 'createUser', 'updateTime'];

  const skipForDefaults = ['createUser', 'updateTime'];
  ctrl.defaultFields = _.filter(ctrl.allFields, function(f) {
    return skipForDefaults.indexOf(f.label || f.param || f) < 0;
  });
  ctrl.sel = {};
};

export const creativeSetBulkAction = function(ctrl, $resources, resources, localResources) {

  const $uibModal = $resources.$uibModal,
    $filter = $resources.$filter,
    $translate = $resources.$translate;

  const LocalUserPermissions = localResources.LocalUserPermissions,
    LocalNetworkProfile = localResources.LocalNetworkProfile,
    adnTimeShifter = localResources.adnTimeShifter;

  return function() {
    const modalInstance = $uibModal.open({
      template: bulkActionTemplate,
      controllerAs: 'modalCtrl',
      size: 'lg',
      controller: /*@ngInject*/ function() {
        const modalCtrl = this,
          commons = lineItemCommons(modalCtrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter, 'item');

        modalCtrl.item = {};
        modalCtrl.form = {};
        modalCtrl.editing = {};
        modalCtrl.item.name = "{currentName}";
        modalCtrl.item.objectives = {};
        modalCtrl.count = _.keys(ctrl.bulkSelection).length;

        let newEndDate = true;
        modalCtrl.endDateCallback = function() {
          if (newEndDate) {
            const defaultEndDayStart = moment(modalCtrl.item.modEndDate).startOf('day').toDate();
            // this condition is necessary in case the time is adjusted first before the date, in which case we want to keep the time.
            if (moment(defaultEndDayStart).isSame(modalCtrl.item.modEndDate)) {
              modalCtrl.item.modEndDate = moment(modalCtrl.item.modEndDate).endOf('day').toDate();
            }
          }
          newEndDate = false;
        };

        modalCtrl.copy = function() {

          const creativeSets = _.map(ctrl.bulkSelection, function(v, k) {
            const obj = {id: k};

            if (modalCtrl.editing.name) {
              obj.name = modalCtrl.item.name.replace("{currentName}", v.name);
            }
            if (modalCtrl.editing.userState && modalCtrl.item.userState) {
              obj.userState = modalCtrl.item.userState;
            }
            commons.modDates(modalCtrl.item.modStartDate, modalCtrl.item.modEndDate);
            if (modalCtrl.editing.startDate) {
              obj.startDate = modalCtrl.item.startDate;
            }
            if (modalCtrl.editing.endDate) {
              obj.endDate = modalCtrl.item.endDate;
            }
            if (modalCtrl.editing.tier && modalCtrl.item.tier) {
              obj.tier = modalCtrl.item.tier || null;
            }

            return obj;
          });

          ctrl.miscBulkAction(modalInstance, modalCtrl, creativeSets, null, {ignoreProduct: LocalNetworkProfile.getDefaults()['defaultIgnoreMarketplaceTargeting']});
        };
      }
    });
  };
};