/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'payment-status-label',
  template: `<span class="label {{labelClass[userState]}}">{{ "payment.state." + userState | translate }}</span>`
})
export class PaymentStatusLabelComponent implements OnInit {
  labelClass: Record<string, string>;
  @Input() userState: string;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass['CREATED'] = 'label-info';
    this.labelClass['NOT_AUTHORISED'] = 'label-danger';
    this.labelClass['AUTHORISED'] = 'label-info';
    this.labelClass['COMPLETED'] = 'label-success';
    this.labelClass['CANCELLED'] = 'label-info';
    this.labelClass['FAILED'] = 'label-danger';
  }
}