/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'info-icon',
  template: `
    <ng-template #popTemplate>
      <div [innerHtml]='text'></div>
    </ng-template>
    <button type="button" class="infoIconLink btn btn-link {{class}}" [outsideClick]="true" placement="{{placement}}" [popover]="popTemplate"><span class="fa fa-fw fa-info-circle"></span></button>`
})
export class InfoIconComponent {
  @Input() text: any;
  @Input() placement = "top";
  @Input() class: string;
}
