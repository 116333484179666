/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import { actionsHelper } from "../../common/controller/actions-helper";

import template from './role-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';

const MODULE_NAME = 'role-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('RoleActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.roles.role',
      lcase: 'role',
      ucase: 'Role'
    });
  });

export default MODULE_NAME;