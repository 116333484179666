/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Inject, Input, OnInit} from '@angular/core';
import {AdTagReplacers} from "./ad-tag-replacers";

@Component({
  selector: 'ad-tag',
  template: `
    <p [innerHTML]="tagDesc"></p>
    <textarea class="codeContainer form-control form-control-static" readonly="readonly">{{targetTag}}
{{completeInsertionTag}}</textarea>
    <p [innerHtml]="tagDesc2" *ngIf="!!tagDesc2"></p>
    <p><a href="https://admin.adnuntius.com/adn" target="_blank">Technical documentation on how to deliver ads is available</a> to anyone.</p>
    <hr>
    <p class="text-right small"><a (click)="detailsPref = !detailsPref">Breakdown of code snippet <span class="fa fa-chevron-down" [ngClass]="{'fa-rotate-90': !detailsPref}"></span></a></p>
    <div *ngIf="detailsPref">
      <h5>Defining the Ad's Position</h5>
      <p>The HTML code below defines where an ad will be shown on a web page. Place the code wherever you want the ad to appear.</p>
      <textarea class="codeContainer codeContainerSmall form-control form-control-static" readonly="readonly">{{targetTag}}</textarea>
      <h5>Loading the Adnuntius Script</h5>
      <p>The script below loads the adn.js file, which is what Adnuntius uses to interact with our ad server and render ads.</p>
      <textarea class="codeContainer codeContainerMedium form-control form-control-static" readonly="readonly">{{scriptLoad}}</textarea>
      <h5>Requesting an Ad from the Ad Server</h5>
      <p>The script below is what triggers the request to the ad server to produce an ad.</p>
      <textarea class="codeContainer codeContainerMedium form-control form-control-static" readonly="readonly">{{insertionTag}}</textarea>
    </div>
  `
})
export class AdTagComponent implements OnInit {
  scriptLoad: string;
  completeInsertionTag: string;
  tagDesc: string;
  tagDesc2: string;
  detailsPref: boolean;
  @Input() networkTagId: string;
  @Input() tagName: string;
  @Input() insertionTag: string;
  @Input() targetTag: string;
  @Input() whitelabelDomain: string;

  constructor(@Inject('$translate') private translate: any) {
  }

  ngOnInit(): void {
    this.scriptLoad = "\x3Cscript src=\"https://cdn.adnuntius.com/adn.js\" async>\x3C/script>";
    this.scriptLoad = AdTagReplacers.replace(this.scriptLoad, this.whitelabelDomain, this.networkTagId);
    this.insertionTag = AdTagReplacers.replace(this.insertionTag, this.whitelabelDomain, this.networkTagId);
    const scriptLoadPlus = this.scriptLoad + "\n\x3Cscript>";

    // want to put the file load into the script block of the insertion tag.
    this.insertionTag = this.insertionTag.replace("env: 'production', ", "").replace(", env: 'production'", "");
    this.completeInsertionTag = scriptLoadPlus + this.insertionTag;
    const index = this.insertionTag.indexOf("window.adn");
    if (index > -1) {
      this.completeInsertionTag = scriptLoadPlus + this.insertionTag.slice(index);
    }

    this.tagDesc = this.translate.instant('adUnit.tag.' + this.tagName + '.desc');
    this.tagDesc2 = this.translate.instant('adUnit.tag.' + this.tagName + '.desc2');
  }
}
