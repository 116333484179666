/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './trigger-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import { actionsHelper } from "../common/controller/actions-helper";

const MODULE_NAME = "trigger-actions-controller";

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('TriggerActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.triggers.trigger',
      lcase: 'trigger',
      ucase: 'Trigger'
    });
  });

export default MODULE_NAME;