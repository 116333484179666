/**
 * Copyright © 2024 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";
import * as moment from "moment";

export class TrafficHistogramResource {
  private readonly uri = ApiConfig.obtain().getVersionedUri('traffichistogram');

  constructor(@Inject('$http') private $http: any) {
  }

  get(objectId: string,
      param: string,
      types: string[],
      filter: Record<string, string[]>,
      startDate: moment.Moment,
      endDate: moment.Moment) {
    types = types || [];
    filter = filter || {};
    const apiParams = {
      types: types,
      filter: filter,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null
    };
    apiParams[param] = objectId;
    return this.$http.post(this.uri, apiParams);
  }
}
