/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Inject, Input, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'copyright-status-label',
  template: `
    <span class="label {{labelClass[copyrightStatus]}}">{{ "creative.copyrightStatus." + copyrightStatus | translate }}</span>
  `
})
export class CopyrightStatusComponent implements OnInit {
  @Input() copyrightStatus: string;
  labelClass: any;
  translation: string;

  constructor(@Inject('$interpolate') private $interpolate: any, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass.UNSPECIFIED = 'label-warning';
    this.labelClass.ADVERTISER_CONFIRMED = 'label-success';
    this.labelClass.ADVERTISER_UNCONFIRMED = 'label-danger';
  }
}
