/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './team-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';
import { actionsHelper } from "../../common/controller/actions-helper";
import {preTeamSaveFix} from "./team-controller";

const MODULE_NAME = 'team-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('TeamActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.teams.team',
      lcase: 'team',
      ucase: 'Team',
      preCopy: function(copyModel, pCtrl) {
        preTeamSaveFix(pCtrl);
      }
    });
  });

export default MODULE_NAME;