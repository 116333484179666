/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import highchartsNg from 'highcharts-ng';

import {CHART_COLORS} from '../options/chart-colors';
import chartOptionsDirective from '../directives/chart-options/chart-options-directive';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';
import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';

import {Downloader} from "../../../components/util/downloader";

import template from './cdn-stats.html';
import {ADN_CHART_COUNT_TYPES, ADN_CHART_Y_AXIS_OPTIONS} from "../options/chart-options";

const MODULE_NAME = "cdn-stats-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  chartOptionsDirective,
  resources,
  promiseSpinnerComponent,
  localNetworkProfile
])

  .directive('adnCdnStats', function($locale, $translate, $log, localUserProfile, LocalNetworkProfile, ChartOptionsUtil, UserProfile, statsResource) {
    return {
      restrict: 'A',
      scope: {},
      template: template,
      link: {
        // using preLink to fill out chart config BEFORE the highchart directive is compiled
        pre: function preLink(scope) {
          let chartOptions = ChartOptionsUtil.getOptions('CDN_STATS'),
            localUser = localUserProfile.get(),
            profile = localUser.profile,
            profileStartDate = _.get(profile[chartOptions.perfChartOptions], 'startDate'),
            profileEndDate = _.get(profile[chartOptions.perfChartOptions], 'endDate'),
            profileCountType = ADN_CHART_COUNT_TYPES[_.get(profile[chartOptions.perfChartOptions], 'countTypeId')];

          scope.dateOption = ChartOptionsUtil.getDateOptions()[_.get(profile[chartOptions.perfChartOptions], 'dateOptionId')];
          if (!scope.dateOption && (!profileStartDate || !profileEndDate)) {
            scope.dateOption = ChartOptionsUtil.getDateOptions().last30Days;
          }
          scope.startDate = scope.dateOption ? scope.dateOption.startDate() : moment(profileStartDate);
          scope.endDate = scope.dateOption ? scope.dateOption.endDate() : moment(profileEndDate);
          scope.countType = profileCountType ? profileCountType : ADN_CHART_COUNT_TYPES.singular;

          let timezone = LocalNetworkProfile.get('timeZone');
          let networkTimezoneOffset = moment.tz(timezone).utcOffset();

          let advStatsSeries = [_.clone(chartOptions.fields.traffic)],
            createChartConfig = function(type, series, yAxisData, height) {
              return {
                chart: {
                  type: type,
                  zoomType: 'x',
                  showAxes: true,
                  height: height
                },
                plotOptions: {
                  series: {
                    marker: {
                      enabled: false
                    },
                    turboThreshold: 2000
                  }
                },
                tooltip: {
                  shared: true
                },
                xAxis: {
                  type: 'datetime',
                  title: {
                    enabled: false,
                    text: 'Date and Time'
                  }
                },
                yAxis: yAxisData,
                series: series,
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                accessibility: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            };

          scope.statsChart = createChartConfig('line', advStatsSeries, {
            title: {text: null},
            min: 0,
            opposite: false,
            allowDecimals: true,
            decimalPlaces: 3,
            name: 'Traffic',
            labels: {format: '{value} GB', style: {color: CHART_COLORS[0]}},
            id: 0
          }, 300);

          const toGigs = function(num, pDivisors) {
            const divisor = Math.pow(1024, (pDivisors || 3));
            return num ? (num / divisor) : 0;
          };

          let startStatsGet = function() {
            let chunkSize = ChartOptionsUtil.getChunkSize(scope.startDate, scope.endDate);

            let statsParams = {
              startDate: scope.startDate,
              endDate: scope.endDate,
              groupBy: chunkSize.key
            };
            statsResource.getCdn(statsParams, function(stats) {
              Highcharts.setOptions({
                global: {timezoneOffset: networkTimezoneOffset * -1},
                lang: {
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  months: $locale.DATETIME_FORMATS.MONTH,
                  weekdays: $locale.DATETIME_FORMATS.DAY,
                  thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
                  decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
                }
              });
              const bytes = _.get(stats, ['totals', 'bytes']);
              const gigs = toGigs(bytes);
              let divisor = 3;
              scope.showTeras = true;
              if (gigs < 15) {
                scope.showTeras = false;
                divisor = 2;
                scope.statsChart.yAxis.labels.format = "{value} MB";
              }
              scope.totalMegs = toGigs(bytes, 2);
              scope.totalGigs = toGigs(bytes);
              scope.totalTeras = toGigs(bytes, 4);
              scope.error = false;

              let allSeries = scope.statsChart.series;
              let keyedChunks = _.keyBy(stats.chunks, 'chunkStart');
              _.forEach(allSeries, function(series) {
                series.yAxis = 0;

                series.color = CHART_COLORS[0];

                let runningTotal = 0;
                series.data = _.map(stats.chunkRange, function(statsDate) {
                  let dataPoint = null;
                  if (keyedChunks[statsDate]) {
                    dataPoint = keyedChunks[statsDate].bytes;
                  }
                  const value = toGigs(dataPoint, divisor);
                  runningTotal += value;
                  return {
                    x: moment(statsDate).valueOf(),
                    y: scope.countType === ADN_CHART_COUNT_TYPES.cumulative && !series.singularOnly ? runningTotal : value
                  };
                });
              });
              scope.haveStats = true;
              scope.isPending = false;
            }, function(data) {
              $log.warn(data);
              if (data.status !== -1) {
                scope.error = true;
              }
              scope.isPending = false;
            }, scope);
          };
          startStatsGet();

          scope.changeCallback = function(dateOptionId, startDate, endDate, countTypeId, statTypeId, yAxisOptionId, selFieldIds, selFieldId) {
            scope.dateOption = ChartOptionsUtil.getDateOptions()[dateOptionId];
            scope.startDate = moment(startDate);
            scope.endDate = moment(endDate);
            scope.countType = ADN_CHART_COUNT_TYPES[countTypeId];
            scope.selFieldIds = selFieldIds;
            scope.selFieldId = selFieldId;
            scope.yAxisOption = ADN_CHART_Y_AXIS_OPTIONS[yAxisOptionId];
            startStatsGet();

            profile[chartOptions.perfChartOptions] = {
              dateOptionId: _.get(scope.dateOption, 'id'),
              startDate: scope.startDate.toDate(),
              endDate: scope.endDate.toDate(),
              yAxisOptionId: scope.yAxisOption.id,
              countTypeId: scope.countType.id,
              selFieldIds: scope.selFieldIds,
              selFieldId: scope.selFieldId
            };

            UserProfile.save({
              id: localUser.userId,
              profile: _.pick(profile, chartOptions.perfChartOptions)
            }, function(apiUser) {
              localUser = localUserProfile.safeSaveProfile(chartOptions.perfChartOptions, apiUser.profile[chartOptions.perfChartOptions]);
              profile = localUser.profile;
            });
          };

          scope.save = function() {

            let allDataFields = _.clone(chartOptions.fields);
            let headingFields = _.map(allDataFields, 'name');
            let fields = _.map(allDataFields, 'id');

            let rows = [];
            let refData = angular.copy(scope.statsChart.series[0].data);
            let dataFields = scope.statsChart.series;
            for (let i = 0; i < refData.length; i++) {
              let dateData = {};
              for (let j = 0; j < dataFields.length; j++) {
                if (j === 0) {
                  dateData.date = moment(dataFields[j].data[i].x).toString();
                }
                dateData[dataFields[j].id] = dataFields[j].data[i].y;
              }
              rows.push(dateData);
            }

            headingFields.unshift("Date");
            fields.unshift("date");

            let date = scope.dateOption ? $translate.instant('chart.dateOption.' + scope.dateOption.id) : "custom dates";
            let defaultName = ("cdn-stats-" + date + ".xls").replace(/ /g, "-");
            new Downloader().saveXls2(defaultName, headingFields, fields, rows, []);
          };
        },
        post: function postLink(scope, element) {
          element.addClass('chart');
        }
      }
    };
  });

export default MODULE_NAME;