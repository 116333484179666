/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './site-group-edit.html';
import analysisTemplate from './site-group-analysis.html';
import deleteTemplate from '../common/delete-actions.html';
import listTemplate from './site-group-list.html';

import standardModule from '../common/standard-module';
import siteGroupController from './site-group-controller';
import siteGroupAnalysis from './site-group-analysis';

const MODULE_NAME = 'site-group-module';

angular.module(MODULE_NAME, [
  standardModule,
  siteGroupController,
  siteGroupAnalysis
])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('site-groups.site-group', 'SiteGroup', 'Site', 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                return;
              }
              ctrl.siteGroupFields = ['name', 'description'];
              if (LocalNetworkProfile.isPureMarketplacePlatform()) {
                ctrl.siteGroupFields.push({param: 'OwnerTeam', label: 'owner'});
                ctrl.siteGroup = true;
              }
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'SiteGroupCtrl',
            controllerAs: 'resource',
            template: twoPanelTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          },
          'leftPanel@app.site-groups.site-group': {
            template: editTemplate,
            controller: 'SiteGroupCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.site-groups.site-group': {
            template: analysisTemplate,
            controller: 'SiteGroupAnalysisCtrl',
            controllerAs: 'resource'
          }
        }
      }, 'SiteGroup'));
  });

export default MODULE_NAME;