import {Inject, Pipe, PipeTransform} from "@angular/core";

import {PercentPipe, DecimalPipe, CurrencyPipe} from "@angular/common";
import * as _ from "lodash";
import {PERCENTAGE_FIELDS} from "./percent-fields";
import {RATIO_FIELDS} from "./percent-fields";
import {CURRENCIES} from "../../../components/util/iso-currencies";

@Pipe({name: 'stat'})
export class StatPipe implements PipeTransform {
  constructor(private percentPipe: PercentPipe, private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe, @Inject('LocalNetworkProfile') private localNetworkProfile: any) {
  }

  transform(stat, field, fractionSize) {
    if (_.isNull(stat) || _.isNaN(stat) || _.isUndefined(stat)) {
      return '-';
    }
    if (_.isFinite(stat)) {
      return field && PERCENTAGE_FIELDS[field] ?
        this.percentPipe.transform(stat, "0.0-" + PERCENTAGE_FIELDS[field].decPlaces.toString()) : this.decimalPipe.transform(stat, "0.0-" + (fractionSize || _.get(RATIO_FIELDS, [field, 'decPlaces']) || 0).toString());
    }
    // handle non-numeric results
    if (_.has(stat, 'currency')) {
      const currencyValue = this.currencyPipe.transform(stat.amount, stat.currency, "symbol-narrow");
      const defaultCurrency = this.localNetworkProfile.get('defaultCurrency');
      if (defaultCurrency !== stat.currency && currencyValue.indexOf(CURRENCIES[defaultCurrency].symbol) > -1) {
        return currencyValue.replace(CURRENCIES[defaultCurrency].symbol, stat.currency + " ");
      }
      return currencyValue;
    }
    return stat.toString();
  }
}