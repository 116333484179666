import bulkActionTemplate from "./review-bulk-action.html";
import _ from "lodash";

const REJECTED_REASONS = [
  'ADULT_CONTENT',
  'BLANK_CONTENT',
  'MALFORMED_CLICK_THROUGH',
  'DRUG_RELATED_CONTENT',
  'WEAPON_RELATED_CONTENT',
  'HATE_SPEECH_CONTENT',
  'LANDING_PAGE_REJECTED',
  'OFFENSIVE_CONTENT',
  'UNACCEPTABLE_VISUAL_EFFECTS',
  'DECEPTIVE_CONTENT',
  'GAMBLING_CONTENT',
  'PROHIBITED_ELECTION_RELATED_CONTENT',
  'SHOCKING_CONTENT',
  'MALWARE_CONTENT',
  'POOR_QUALITY'
];

export const reviewBulkAction = function(ctrl, $resources, resources) {

  const $uibModal = $resources.$uibModal,
    Creative = resources.Creative,
    LineItem = resources.LineItem;

  return function(reviewItem, creative) {
    const modalInstance = $uibModal.open({
      template: bulkActionTemplate,
      controllerAs: 'modalCtrl',
      windowClass: '',
      controller: /*@ngInject*/ function() {
        const modalCtrl = this;

        modalCtrl.item = {};
        modalCtrl.form = {};
        modalCtrl.editing = {};
        modalCtrl.item.name = "{currentName}";
        modalCtrl.count = _.keys(ctrl.bulkSelection).length;

        modalCtrl.reasons = _.cloneDeep(REJECTED_REASONS);

        modalCtrl.review = function() {
          const creativeResource = new Creative({
            id: creative.creative.id,
            userState: 'REJECTED',
            rejectedReason: modalCtrl.rejectedReason
          });


          creativeResource.savePartial().then(function() {
            const lineItemResource = new LineItem({id: reviewItem.lineItem.id, userState: 'PROPOSED'});
            lineItemResource.savePartial().catch(function() {
              modalCtrl.form.bulkError = true;
            }).finally(function() {
              modalInstance.close();
              ctrl.makeQuery();
            });
          }).catch(function() {
            modalCtrl.form.bulkError = true;
            modalInstance.close();
            ctrl.makeQuery();
          });
        };
      }
    });
  };
};
