/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import formControlWrapper from './form-control-wrapper-directive';
import formControlValidation from './form-control-validation';
import formControlHookable from './form-control-hookable-directive';
import formControl from './form-control-directive';

const MODULE_NAME = 'form-module';

angular.module(MODULE_NAME, [
  formControlWrapper,
  formControlValidation,
  formControlHookable,
  formControl
]);

export default MODULE_NAME;