/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import {OBJECT_TYPE} from "../common/constants/object-type";

import listTitleTemplate from '../base/default-title-template.html';
import defaultContextTemplate from '../common/list-default-actions.html';

import editTemplate from './report-request.html';
import listTemplate from './report-list.html';

import resourceListController from '../common/controller/resource-list-controller';
import standardModule from '../common/standard-module';
import reportRequester from '../line-item/widget/report-requester-widget-directive';

const MODULE_NAME = 'report-module';

angular.module(MODULE_NAME, [
  standardModule,
  resourceListController,
  reportRequester
])

  .config(function($stateProvider) {
    $stateProvider.state('app.reports', {
      url: '/reports',
      parent: 'app.rep',
      resolve: {
        extension: /*@ngInject*/ function(localUserProfile) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                _.forEach(ctrl.items, function(item) {
                  item.execTime = moment(item.executionEndTime).diff(moment(item.executionStartTime), 'seconds');
                });
              }
              ctrl.reportFields = ['name', {'label': 'reportTemplate'}, 'status', 'updateTime'];
              const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
              if (isSpecialUser) {
                ctrl.reportFields.push({label: 'execTime'});
              }
            }
          };
        },
        ResourceFactory: function(ReportExecution) {
          return ReportExecution;
        },
        ChildResourceFactory: function() {
        },
        listConfig: function() {
        },
        orderByParam: function() {
          return 'DESCexecutionStartTime';
        },
        objectType: function() {
          return OBJECT_TYPE.ReportExecution;
        },
        sectionParam: function() {
        },
        bulk: function() {
        },
        listExtension: function() {
        }
      },
      views: {
        'content@app': {
          controller: 'ResourceListCtrl',
          controllerAs: 'list',
          template: listTemplate
        },
        "context@app": {
          controller: /*@ngInject*/ function(readOnly) {
            this.readOnly = readOnly;
          },
          template: defaultContextTemplate
        },
        'title@app': {
          template: listTitleTemplate
        }
      },
      data: {
        title: "{{'report.plural' | translate}}"
      }
    }).state('app.reports.new', {
      url: '/new',
      resolve: {
        ResourceFactory: function(ReportExecution) {
          return ReportExecution;
        },
        ChildResourceFactory: function() {
        },
        orderByParam: function() {
          return 'DESCexecutionStartTime';
        },
        objectType: function() {
          return OBJECT_TYPE.ReportExecution;
        }
      },
      views: {
        'content@app': {
          controller: _.noop,
          controllerAs: 'ctrl',
          template: editTemplate
        },
        'context@app': {},
        'title@app': {
          template: listTitleTemplate
        }
      },
      data: {
        title: "Request a Report"
      }
    });
  });

export default MODULE_NAME;