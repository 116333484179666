/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import ngFileUpload from 'ng-file-upload';
import uiCodemirror from 'angular-ui-codemirror';

import {MEDIA_TYPE, MediaTypeHelper} from "../../../components/util/media-type-helper";
import {Uuid} from "../../../components/util/uuid";

import template from './video-assets.html';

import standardFormModule from '../../common/standard-form-module';
import mediaTypeKeyFilter from '../../common/filters/mimeTypeFilter';

const MODULE_NAME = 'video-assets-directive';

const FILE_UPLOAD_LIMIT = 1;

angular.module(MODULE_NAME, [ngFileUpload, uiCodemirror, standardFormModule, mediaTypeKeyFilter])

  .directive('adnVideoAssets', function($q, Asset) {
    return {
      template: template,
      restrict: 'A',
      require: '?^^form',
      scope: {
        creative: '<?',
        name: '@adnName',
        accept: '@adnAccept',
        eventHook: '<',
        isNew: '<?',
        readOnly: '@adnReadOnly',
        maxFiles: '@adnMaxFiles',
        blockTranscode: '<blockTranscode',
        doHelpful: '<?'
      },
      link: {
        pre: function(scope) {
          scope.name = scope.name || 'assets';
          scope.accept = scope.accept || '.mp4,.webm';
          scope.allFilesProcessed = true;
          scope.validationWarnings = [];
          scope.parentObject = scope.creative;
        },
        post: function(scope, element, attrs, form) {
          if (!scope.parentObject) {
            return;
          }
          let assessAssets = function(afterSave) {
            _.forEach(scope.parentObject.assets, function(asset) {
              asset.hideMe = asset.htmlCreativeType && !asset.primaryHtmlAsset;

              let assignedTag = false;
              _.forEach(scope.parentObject.cToAssets || [], function(assignedAsset, tag) {
                if (assignedAsset && asset && assignedAsset.id === asset.id) {
                  assignedTag = tag;
                }
              });
              asset.assigned = assignedTag;

              if (afterSave) {
                asset.assignedAndSaved = assignedTag;
              }
            });
          };
          scope.parentObject.assets = scope.parentObject.assets || [];
          assessAssets(true);

          scope.eventHook.onCreativeSave = function() {
            assessAssets(true);
          };

          scope.eventHook.onAssetUpdate = function() {
            assessAssets();
          };

          scope.assign = function(asset, tag) {
            scope.eventHook.assignAsset(asset, tag);
            assessAssets();
          };

          let fileUploadLimit = scope.maxFiles || FILE_UPLOAD_LIMIT,
            ctrl = form || {$setValidity: _.noop},
            previousAssets = [];

          scope.cancel = function(asset) {
            let previousAsset = _.find(previousAssets, ['id', asset.id]);
            let currentAsset = _.find(scope.parentObject.assets, ['id', asset.id]);
            if (previousAsset && currentAsset) {
              currentAsset.fileName = previousAsset.fileName;
            }
          };

          scope.edit = function(asset) {
            previousAssets = _.reject(previousAssets, ['id', asset.id]);
            previousAssets.push(angular.copy(asset));
          };

          scope.delete = function(asset) {
            asset.objectState = 'HIDDEN';
            if (asset.primaryHtmlAsset) {
              let assetIdMatch = asset.id.substring(0, asset.id.length - 3);
              let relatedAssets = _.filter(scope.parentObject.assets, function(a) {
                return _.startsWith(a.id, assetIdMatch) && a.id !== asset.id;
              });
              _.forEach(relatedAssets, function(a) {
                Asset.update(scope.parentObject.id, {objectState: asset.objectState}, a.id);
              });
            }
            Asset.update(scope.parentObject.id, {objectState: asset.objectState}, asset.id).then(function() {
              scope.eventHook.onUpdate();
            });
          };

          scope.save = function(asset) {
            Asset.update(scope.parentObject.id, {fileName: asset.fileName}, asset.id);
          };

          let updateWarnings = function(paramWarnings) {
            if (paramWarnings) {
              scope.validationWarnings = scope.validationWarnings.concat(_.isArray(paramWarnings) ? paramWarnings : [paramWarnings]);
            } else {
              scope.validationWarnings = [];
            }
          };

          let creativePreSave = false;
          scope.upload = function(allFiles) {
            scope.filesFailedUpload = [];
            updateWarnings();

            if (!allFiles || !_.isArray(allFiles) || allFiles.length < 1) {
              ctrl.$setValidity('upload', false);
              return;
            }

            let files = _.clone(_.slice(allFiles, 0, fileUploadLimit));
            if (allFiles.length > fileUploadLimit) {
              updateWarnings({
                text: 'Only {{fileUploadLimit}} files can be uploaded at a time. The last {{restFilesCount}} of the {{allFilesCount}} selected files were not uploaded.',
                parameters: {
                  fileUploadLimit: fileUploadLimit,
                  restFilesCount: allFiles.length - fileUploadLimit,
                  allFilesCount: allFiles.length
                },
                preventsAdServing: false
              });
            }
            allFiles = null;

            // add a pending validation
            ctrl.$setValidity('upload');
            scope.allFilesProcessed = false;
            scope.allFilesProgress = 0;

            let preUpdatePromises = [];
            if ((scope.creative && !scope.creative.id) || (scope.creative && scope.creative.id && scope.isNew && !creativePreSave)) {
              scope.creative.id = scope.creative.id || Uuid.generate();
              scope.creative.name = scope.creative.name || "Creative"; // need a name otherwise no save is possible

              if (!_.get(scope.creative, ['lineItem', 'id'])) {
                delete scope.creative.lineItem;
              }

              // need to do this to enable a second creative being used.
              scope.parentObject = scope.creative;
              let creativeToSave = angular.copy(scope.creative);
              preUpdatePromises.push(creativeToSave.$save());
              creativePreSave = true;
            }
            $q.all(preUpdatePromises).then(function() {
              let setAllFilesProgress = function() {
                  let progress = _.reduce(files, function(result, file) {
                    return result + (_.isFinite(file.progress) ? file.progress : 0);
                  }, 0);
                  scope.allFilesProgress = Math.min(100, parseInt(progress / files.length, 10));
                },
                allFilesChecks = function() {
                  if (!_.find(files, ['uploaded', undefined])) {
                    scope.allFilesProcessed = true;
                  }

                  // if at least one file is valid, then say the upload is a success
                  ctrl.$setValidity('upload', _.some(files, 'validity'));

                  scope.filesFailedUpload = _.filter(files, ['validity', false]);
                },
                refreshAssetsAfterZipCheck = function() {
                  if (hasZip && scope.allFilesProcessed && scope.creative) {
                    return Asset.fromCreative(scope.creative.id).then(function(assets) {
                      scope.creative.assets = assets;
                      assessAssets();
                    });
                  }
                },
                hasZip = false;

              // TODO: when/if the API supports it, should do multi-file upload with one request.
              _.forEach(files, function(file) {
                file.validity = undefined;

                const parentId = scope.creative.id;
                const uploadMethod = scope.doHelpful ? 'helpfulBulkUpload' : 'upload';
                const fileData = {file: file};
                if (scope.blockTranscode) {
                  fileData.transcodeConfig = {enabled: false};
                }
                Asset[uploadMethod](parentId, fileData).then(function(assets) {
                  file.validity = true;

                  const mappedAssets = _.flatten(_.map(assets, function(a) {
                    return a.results ? a.results : a;
                  }));
                  scope.parentObject.assets = scope.parentObject.assets || [];
                  let allAssets = scope.parentObject.assets.concat(mappedAssets);
                  if (scope.maxFiles && allAssets.length > scope.maxFiles) {
                    allAssets = _.takeRight(allAssets, scope.maxFiles);
                  }

                  let isZip = MediaTypeHelper.isMediaType(file.type, MEDIA_TYPE.zip);
                  if (!isZip) {
                    scope.parentObject.assets = allAssets;
                  }
                  hasZip = hasZip || isZip;

                  allFilesChecks();
                  let promise = refreshAssetsAfterZipCheck();
                  $q.when(promise).then(function() {
                    scope.eventHook.onUpdate(assets);
                  });
                  assessAssets();
                }, function(error) {
                  file.validity = false;

                  let warnings = [];
                  if (!error.data || (error.status === -1 && error.data === null)) {
                    // sometimes the API can crap out and issue a time-out, which is handled here.
                    warnings.push({
                      text: "The upload failed to complete. Try uploading again or, if possible, refreshing the page to see which resources have been uploaded.",
                      preventsAdServing: false
                    });
                  } else {
                    // API can return validation error or general error.
                    // General error on unsupported mime type, validation error on everything else (like file size too big).
                    if (error.data.errors) {
                      warnings = _.flatten(warnings.concat(_.map(error.data.errors, function(value) {
                        let returnValue = angular.copy(value.error);
                        returnValue.preventsAdServing = true;
                        return returnValue;
                      })));
                    } else if (error.data.map) {
                      warnings = _.flatten(warnings.concat(_.map(error.data.map, function(value) {
                        return {text: value, preventsAdServing: true};
                      })));
                    } else if (error.data) {
                      warnings.push(error.data);
                    }
                  }

                  updateWarnings(warnings);
                  allFilesChecks();
                }, function(progressEvent) {
                  file.progress = Math.min(100, parseInt(100 * progressEvent.loaded / progressEvent.total, 10));
                  setAllFilesProgress();
                });
              });
            }, function() {
              scope.parentObject.id = undefined;
              ctrl.$setValidity('upload', false);
            });
          };
        }
      }
    };
  });

export default MODULE_NAME;