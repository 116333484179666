/**
 * Copyright © 2024 Adnuntius AS.
 */
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'coupon-status-component',
  template: `
    <span class="label {{labelClass[couponStatus]}}">{{ "coupon.couponStatus." + couponStatus | translate }}</span>
  `
})
export class CouponStatusComponent implements OnInit {
  labelClass: any;
  @Input() couponStatus: string;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass.PENDING = 'label-info';
    this.labelClass.PUBLISHED = 'label-success';
    this.labelClass.STOPPED = 'label-warning';
  }
}
