/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = 'external-demand-source-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('ExternalDemandSourceCtrl', function(currenciesResource, ExternalAdUnit, Creative, model, modelComms, adnListHelper) {
    const ctrl = this;
    adnFormHelper.setUpForm(ctrl, model, {modelComms: modelComms});
    ctrl.model.externalPartner = ctrl.model.externalPartner || 'PUBMATIC_SSP';

    currenciesResource.get().then(function(currencies) {
      ctrl.currencies = _.map(currencies, "currency");
    });

    adnListHelper.setUpBasicList(ctrl, ExternalAdUnit, 'fullExternalAdUnitsForExternalDemandSource', {moreParams: {externalDemandSource: model.id}, noInitQuery: true});
    if (!ctrl.isNew) {
      ctrl.makeQuery();
    }

    ctrl.creativeList = {};
    adnListHelper.setUpBasicList(ctrl.creativeList, Creative, 'fullCreativesForExternalDemandSource', {moreParams: {externalDemandSource: model.id}, noInitQuery: true});
    if (!ctrl.isNew) {
      ctrl.creativeList.makeQuery();
    }
  });

export default MODULE_NAME;