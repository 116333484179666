import _ from "lodash";
import moment from 'moment';
import {FIELD_TYPE_OPERATIONS, BEHAVIOUR_TIMES, VALID_DURATION_UNITS, BEHAVIOUR_TYPES, DEVICE_OS_TYPES, DEVICE_TYPES} from "./field-type-operations";
import {COUNTRIES} from "../common/constants/locations";
import angular from "angular";
import {adnFormHelper} from "../common/controller/form-helper";
import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";

const logicInvalidWarnings = ['validation.warning.trigger.invalid.logic', 'validation.warning.trigger.unknown.variable'];

const prefixMaker = function(entry) {
  if (entry.matchingStyle === 'ALL') {
    return "and ";
  } else if (entry.matchingStyle === 'ANY') {
    return "or ";
  }
};

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').concat('AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,AO,AP,AQ,AR,AS,AT,AU,AV,AW,AX,AY,AZ'.split(','));

export const triggerConditionFormatter = function($translate, entry) {
  const loopData = [];
  let prefix = "";
  _.forEach(entry.criteria, function(c) {
    loopData.push(prefix + c);
    prefix = prefixMaker(entry);
  });
  _.forEach(entry.pageViews, function(domains, time) {
    _.forEach(domains, function(domain) {
      loopData.push(prefix + domain + " times " + $translate.instant('trigger.pageView.time.' + time));
      prefix = prefixMaker(entry);
    });
  });
  return loopData;
};

export const triggerController = function(ctrl, model, modelComms, $q, adnTimeShifter, Folder, searchResource, VisitorProfileFields, locationResource) {
  ctrl.settingUp = false;

  const promise = VisitorProfileFields.get().then(function(fields) {
    ctrl.fields = _.map(fields, function(f) {
      return {name: f.name, dataType: f.type, displayName: _.lowerCase(f.name), description: f.description};
    });
    _.forEach(fields, function(f) {
      if (f.mapping) {
        ctrl.fields.push({name: f.mapping, displayName: _.lowerCase(f.mapping), dataType: f.type});
      }
      _.forEach(f.aliases || [], function(a) {
        ctrl.fields.push({name: a, displayName: _.lowerCase(a), dataType: f.type});
      });
    });

    ctrl.nameToType = {};
    _.forEach(ctrl.fields, function(f) {
      ctrl.nameToType[f.name] = f.dataType;
    });

    ctrl.operationIdToControlType = {};
    _.forEach(FIELD_TYPE_OPERATIONS, function(val, key) {
      ctrl.operationIdToControlType[key] = {};
      _.forEach(val, function(subVal) {
        ctrl.operationIdToControlType[key][subVal.id] = subVal.controlType;
      });
    });

    ctrl.nameToDescription = {};
    _.forEach(ctrl.fields, function(f) {
      ctrl.nameToDescription[f.name] = f.description;
    });

    ctrl.nameOpHelp = {};
    _.forEach(ctrl.fields, function(f) {
      const el = FIELD_TYPE_OPERATIONS[f.dataType];
      ctrl.nameOpHelp[f.name] = {};
      _.forEach(el, function(val) {
        if (_.isObject(val)) {
          ctrl.nameOpHelp[f.name][val.id] = val.help;
        }
      });
    });
  });

  ctrl.behaviorTypes = BEHAVIOUR_TYPES;
  ctrl.fieldTypeOperations = FIELD_TYPE_OPERATIONS;
  ctrl.durationUnits = VALID_DURATION_UNITS;
  ctrl.pageViewTimes = BEHAVIOUR_TIMES;
  ctrl.deviceTypes = DEVICE_TYPES;
  ctrl.deviceOsTypes = DEVICE_OS_TYPES;
  ctrl.countries = COUNTRIES;
  ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);

  const afterInitAfterSubmit = function() {
    ctrl.model.global = ctrl.model.folders.length === 0;

    let allCriteria = [];
    if (!_.isEmpty(ctrl.model.conditions)) {
      _.forEach(ctrl.model.conditions, function(v, k) {
        if (v.period) {
          allCriteria.push({
            cType: 'BEHAVIOUR',
            type: v.type,
            time: v.period,
            condition: v.behaviour,
            conditionKey: k
          });
        } else {
          allCriteria.push({
            cType: 'CHARACTERISTIC',
            condition: v.condition,
            conditionKey: k
          });
        }
      });
    }

    ctrl.model.workingLogic = ctrl.model.logic;
    if (ctrl.model.logic) {
      const hasWarning = _.find(ctrl.model.validationWarnings, function(vw) {
        return logicInvalidWarnings.indexOf(vw.code) > -1;
      });

      const hasAnd = ctrl.model.logic.indexOf("AND") > -1;
      const hasOr = ctrl.model.logic.indexOf("OR") > -1;
      const hasNot = ctrl.model.logic.indexOf("NOT") > -1;
      const hasBrackets = ctrl.model.logic.indexOf("(") > -1 || ctrl.model.logic.indexOf(")") > -1;


      if (hasWarning || hasNot || hasBrackets) {
        ctrl.model.matchingStyle = 'CUSTOM';
      } else if (!hasAnd && hasOr) {
        ctrl.model.matchingStyle = 'ANY';
      } else if (!hasOr) {
        ctrl.model.matchingStyle = 'ALL';
      } else {
        ctrl.model.matchingStyle = 'CUSTOM';
      }
    }

    function deriveFieldTypeOperation(dataType, opAsText, theSplit) {
      return _.find(FIELD_TYPE_OPERATIONS[dataType], function(entry) {
        return entry.operation === opAsText && (!entry.controlType || (entry.controlType === 'MovingFeast' && theSplit.indexOf("ago") > -1) || (entry.controlType !== 'MovingFeast' && theSplit.indexOf("ago") < 0));
      });
    }

    ctrl.model.helpfulCriteria = _.map(allCriteria, function(c) {
      const theSplit = c.condition.split(" ");
      let theField;
      let theOperationAsText;
      let theValue;
      let theOperationObj;

      if (c.cType === 'CHARACTERISTIC') {
        // if there are multiple fields, just pick the first one
        theField = theSplit[0].split(",")[0];
        theOperationAsText = theSplit[1];
        theValue = theSplit.slice(2).join(" ");

        const matchField = _.find(ctrl.fields, function(f) {
          return f.name === theField;
        });
        let durationUnit = "days";
        if (matchField.dataType === 'Integer') {
          theValue = _.parseInt(theValue);
        }
        theOperationObj = deriveFieldTypeOperation(matchField.dataType, theOperationAsText, theSplit);
        if (theOperationObj.controlType === 'MovingFeast') {
          _.forEach(VALID_DURATION_UNITS, function(vdu) {
            if (theValue.indexOf(" " + vdu) > 0) {
              theValue = _.parseInt(theValue);
              durationUnit = vdu;
              return false;
            }
          });
        } else if (theOperationObj.controlType === 'Date') {
          theValue = moment(theValue).toDate();
        } else if (theOperationObj.controlType === 'DateAndTime') {
          theValue = adnTimeShifter.timeShift(theValue);
        }
        if (theOperationAsText.indexOf("in") > -1) {
          if (_.startsWith(theValue, "[")) {
            theValue = theValue.substr(1);
          }
          if (_.endsWith(theValue, "]")) {
            theValue = theValue.substr(0, theValue.length - 1);
          }
          theValue = _.split(theValue, ",").join(", ");
        }
        const dataObj = {cType: 'CHARACTERISTIC', conditionKey: c.conditionKey, criterion: c, dataType: matchField.dataType, name: theField, operation: theOperationObj, durationUnit: durationUnit};
        if (theOperationObj.controlType === 'Date' || theOperationObj.controlType === 'DateAndTime') {
          dataObj.valueDate = theValue;
        } else {
          dataObj.value = theValue;
        }
        return dataObj;
      } else if (c.cType === 'BEHAVIOUR') {
        // deal with possible whitespace,
        // then, if there are multiple fields, just pick the first one
        theField = theSplit
          .slice(0, theSplit.length - 2)
          .reduce((acc, cv) => acc + " " + cv)
          .split(",")[0];
        theOperationAsText = theSplit[theSplit.length - 2];
        theValue = theSplit[theSplit.length - 1];
        theOperationObj = deriveFieldTypeOperation('Behaviour', theOperationAsText, theSplit.length);
        return {cType: 'BEHAVIOUR', domain: theField, type: c.type, conditionKey: c.conditionKey, operation: theOperationObj, time: c.time, value: _.parseInt(theValue)};
      }
    });
    ctrl.model.helpfulCriteria = ctrl.model.helpfulCriteria || [];
    ctrl.model.helpfulCriteria = _.sortBy(ctrl.model.helpfulCriteria, function(c) {
      return _.get(c, 'conditionKey');
    });
  };

  adnFormHelper.setUpForm(ctrl, model, {
    modelComms: modelComms,
    afterInit: function() {
      $q.when(promise).then(function() {
        ctrl.settingUp = true;
        afterInitAfterSubmit();
      });

      ctrl.model.folders = _.map(ctrl.model.folders, _.iteratee("id"));
      ctrl.model.matchingStyle = ctrl.model.matchingStyle || 'ALL';
    },
    afterSave: function() {
      afterInitAfterSubmit();
    }
  });

  function getNextLetter() {
    let maxLetter = -1;
    _.forEach(ctrl.model.helpfulCriteria, function(c) {
      maxLetter = Math.max(alphabet.indexOf(c.conditionKey), maxLetter);
    });
    return alphabet[maxLetter + 1];
  }

  ctrl.addCondition = function() {
    ctrl.model.helpfulCriteria.push({cType: 'CHARACTERISTIC', criterion: "", conditionKey: getNextLetter()});
    ctrl.editsMade();
  };

  ctrl.addBehaviour = function() {
    ctrl.model.helpfulCriteria.push({cType: 'BEHAVIOUR', criterion: "", conditionKey: getNextLetter()});
    ctrl.editsMade();
  };

  ctrl.removeCondition = function(index) {
    _.pullAt(ctrl.model.helpfulCriteria, index);
    ctrl.editsMade();
  };

  Folder.query(function(page) {
    ctrl.allFolders = page.results;
  });

  ctrl.segSearch = function(searchElement, postFix) {
    searchResource.query({"q": searchElement.search, "types": 'UserSegment', objectState: 'ACTIVE', dataSource: 'ADNUNTIUS'}).then(function(data) {
      ctrl["segmentResults" + (postFix || "")] = data.searchResults;
    });
  };

  ctrl.onBeforeSubmit = function() {
    const characteristics = _.map(_.filter(ctrl.model.helpfulCriteria, function(c) {
      return c.cType === 'CHARACTERISTIC' && c.name && c.operation &&
        ((c.operation.controlType === 'Date' && moment(c.valueDate).isValid()) || (c.operation.controlType === 'DateAndTime' && moment(c.valueDate).isValid()) || _.isFinite(c.value) || (_.isString(c.value) && _.trim(c.value).length > 0));
    }), function(c) {
      let theValue = c.value;
      if (c.operation.operation.indexOf('in') > -1) {
        theValue = "[" + _.map(c.value.split(","), function(v) {
          return _.trim(v);
        }).join(",") + "]";
      }
      if (c.operation.controlType === 'MovingFeast') {
        theValue += " " + c.durationUnit + " ago";
      } else if (c.operation.controlType === 'Date') {
        theValue = moment(c.valueDate).format("YYYY-MM-DD");
      } else if (c.operation.controlType === 'DateAndTime') {
        theValue = moment(adnTimeShifter.timeShift(c.valueDate, true)).format();
      }
      return {
        key: c.conditionKey,
        value: c.name + " " + c.operation.operation + " " + theValue
      };
    });

    const behaviours = [];
    _.forEach(_.filter(ctrl.model.helpfulCriteria, function(c) {
      return c.cType === 'BEHAVIOUR' && c.domain && c.operation && _.isFinite(c.value) && c.value >= 0 && c.time;
    }), function(c) {
      behaviours.push({
        key: c.conditionKey,
        period: c.time,
        type: c.type,
        behaviour: c.domain + " " + c.operation.operation + " " + _.parseInt(c.value)
      });
    });

    ctrl.model.conditions = {};
    const conditionLetters = [];
    _.forEach(characteristics, function(c) {
      let letter = '';
      if (ctrl.model.matchingStyle === 'CUSTOM') {
        letter = c.key;
      } else {
        letter = alphabet[conditionLetters.length];
        conditionLetters.push(letter);
      }
      ctrl.model.conditions[letter] = {condition: c.value};
    });
    _.forEach(behaviours, function(b) {
      let letter = '';
      if (ctrl.model.matchingStyle === 'CUSTOM') {
        letter = b.key;
      } else {
        letter = alphabet[conditionLetters.length];
        conditionLetters.push(letter);
      }
      ctrl.model.conditions[letter] = b;
    });
    if (ctrl.model.matchingStyle === 'CUSTOM') {
      ctrl.model.logic = ctrl.model.workingLogic;
    } else {
      ctrl.model.logic = conditionLetters.join(ctrl.model.matchingStyle === 'ALL' ? " AND " : " OR ");
    }
    ctrl.model.folders = ctrl.model.global ? [] : ctrl.model.folders;
  };

  ctrl.searchRegion = function(select, index) {
    return locationResource.query({q: select.search, types: 'REGION'}).then(function(page) {
      ctrl.model.helpfulCriteria[index].searchResults = select.search === '' ?
        [{name: ctrl.model.helpfulCriteria[index].domain}] :
        _.uniqBy(page.searchResults, 'name');
    });
  };

  ctrl.searchCities = function(select, index) {
    return locationResource.query({q: select.search, types: 'CITY'}).then(function(page) {
      ctrl.model.helpfulCriteria[index].searchResults = select.search === '' ?
        [{name: ctrl.model.helpfulCriteria[index].domain}] :
        _.uniqBy(page.searchResults, 'name');
    });
  };
};
