/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import translate from 'angular-translate';
import Highcharts from 'highcharts';
import treemap from 'highcharts/modules/treemap';
treemap(Highcharts);
import heatmap from 'highcharts/modules/heatmap';
heatmap(Highcharts);
import highchartsNg from 'highcharts-ng';

import template from './stats-widget.html';

import localNetworkProfile from '../../../components/session/local-network-profile';
import {OBJECT_TYPE} from "../../common/constants/object-type";

const MODULE_NAME = "tree-map-widget-directive";

angular.module(MODULE_NAME, [highchartsNg, localNetworkProfile, translate])

  .directive('adnTreeMapWidget', function(statsResource, ChartOptionsUtil, $translate, $log, $locale, LocalNetworkProfile) {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        widgetType: '<adnTreeMapWidget'
      },
      template: template,
      controllerAs: 'ctrl',
      controller: function postLink() {
        const ctrl = this;

        ctrl.isCollection = true;

        let timezone = LocalNetworkProfile.get('timeZone');
        let networkTimezoneOffset = moment.tz(timezone).utcOffset();

        let chunkYValue = function(yval) {
          if (_.has(yval, 'amount')) {
            return yval.amount;
          } else if (_.has(yval, 'count')) {
            return yval.count;
          }
          return yval;
        };

        ctrl.$onInit = function() {

          ctrl.dateOption = ChartOptionsUtil.getDateOptions().last7Days;
          if (ctrl.widgetType.dateOption && ChartOptionsUtil.getDateOptions()[ctrl.widgetType.dateOption]) {
            ctrl.dateOption = ChartOptionsUtil.getDateOptions()[ctrl.widgetType.dateOption];
          }
          ctrl.startDate = ctrl.dateOption.startDate();
          ctrl.endDate = ctrl.dateOption.endDate();

          const networkTransFieldName = LocalNetworkProfile.getNetworkTranslations(ctrl.widgetType.selField);
          const fieldName = networkTransFieldName || $translate.instant("report.field." + ctrl.widgetType.selField);
          const networkTransField2Name = LocalNetworkProfile.getNetworkTranslations(ctrl.widgetType.selField2);
          const field2Name = ctrl.widgetType.selField2 ? networkTransField2Name || $translate.instant("report.field." + ctrl.widgetType.selField2) : "";

          ctrl.chartTitle = networkTransFieldName || fieldName;
          if (field2Name) {
            ctrl.chartTitle += " and " + (networkTransField2Name || field2Name);
          }
          ctrl.chartTitle += " for " + $translate.instant('chart.dateOption.' + ctrl.dateOption.id + '.sentence');

          ctrl.ids = _.map(ctrl.widgetType.objIds, 'id');
          if (!ctrl.ids.length) {
            ctrl.isPending = false;
            ctrl.noData = "Select at least one object to report stats on.";
            return;
          }

          let advStatsSeries = [{
              type: 'treemap',
              data: []
            }],
            createChartConfig = function(series) {
              return {
                colorAxis: {
                    min: 0,
                    minColor: '#fafaff',
                    maxColor: Highcharts.getOptions().colors[0]
                },
                chart: {
                  type: 'treemap',
                  height: ctrl.widgetType.height || 300
                },
                tooltip: {
                  formatter: function() {
                    var tip = this.point.name + "<br>" + this.point.fieldName + ": <strong>" + this.point.value + "</strong>";
                    if (this.point.field2Name) {
                      tip += "<br>" + this.point.field2Name + ": <strong>" + this.point.colorValue + "</strong>";
                    }
                    return tip;
                  }
                },
                series: series,
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                },
                accessibility: {
                  enabled: false
                },
                legend: {
                  enabled: !!field2Name
                }
              };
            };

          ctrl.statsChart = createChartConfig(advStatsSeries);

          ctrl.selFieldId = ctrl.widgetType.selFieldId;

          const objType = OBJECT_TYPE[ctrl.widgetType.objectType] || OBJECT_TYPE.LineItem;
          ctrl.chartType = ChartOptionsUtil.getOptions(objType.caps);

          let startStatsGet = function() {

            let statsParams = {
              id: _.slice(ctrl.ids, 0, 15).join(';'),
              idKey: ctrl.chartType.idKey,
              startDate: ctrl.startDate,
              endDate: ctrl.endDate,
              groupBy: objType.caps
            };
            statsResource.get(statsParams, function(stats) {
              Highcharts.setOptions({
                global: {timezoneOffset: networkTimezoneOffset * -1},
                lang: {
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  months: $locale.DATETIME_FORMATS.MONTH,
                  weekdays: $locale.DATETIME_FORMATS.DAY,
                  thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
                  decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
                }
              });
              ctrl.totals = stats.totals || stats;
              ctrl.error = false;

              let allSeries = ctrl.statsChart.series[0];

              allSeries.data = _.map(stats.chunks, function(chunk) {
                const dataPoint = chunk[ctrl.widgetType.selField];
                const value = chunkYValue(dataPoint) || 0;

                const chartsData = {
                  name: chunk[objType.apiParam].name,
                  fieldName: fieldName,
                  value: value
                };
                if (field2Name) {
                  const dataPoint2 = chunk[ctrl.widgetType.selField2];
                  const value2 = chunkYValue(dataPoint2) || 0;
                  chartsData.field2Name = field2Name;
                  chartsData.colorValue = value2;
                }
                return chartsData;
              });

              ctrl.statsChart.colorAxis.max = chunkYValue(stats.totals[ctrl.widgetType.selField2]);

              if (!stats.chunks.length) {
                ctrl.noData = "No data was found for your selected " + $translate.instant('object.' + ctrl.widgetType.objectType + '.plural').toLowerCase();
              }
              ctrl.haveStats = true;
              ctrl.isPending = false;
            }, function(data) {
              $log.warn(data);
              if (data.status !== -1) {
                ctrl.error = true;
              }
              ctrl.isPending = false;
            }, ctrl);
          };
          startStatsGet();

        };
      }
    };
  });

export default MODULE_NAME;