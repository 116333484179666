/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ad-status-label',
  template: `
    <span class="label {{labelClass}}">{{ translation }}</span>
  `
})
export class AdStatusLabelComponent implements OnInit {
  labelClass: any;
  translation: string;
  @Input() requestedInLastDay: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.labelClass = this.requestedInLastDay ? 'label-success' : 'label-warning';
    this.translation = this.requestedInLastDay ? 'Has Traffic' : 'No Recent Traffic';
  }
}
