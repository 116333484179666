/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import standardFormModule from '../../common/standard-form-module';
import integerValidator from '../../common/directives/form-controls/integer-validation';
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = 'tier-controller';

angular.module(MODULE_NAME, [standardFormModule, integerValidator])

  .controller('TierCtrl', function(tierEnabled, model, modelComms) {
    const ctrl = this;
    adnFormHelper.setUpForm(ctrl, model, {modelComms: modelComms, afterInit: function() {
        ctrl.model.minimumTraffic = ctrl.model.minimumTraffic || 100;
        ctrl.model.maximumTraffic = ctrl.model.maximumTraffic || 100;
        ctrl.model.type = ctrl.model.type || 'STANDARD';
      }});

    ctrl.tierEnabled = tierEnabled;
  });

export default MODULE_NAME;