/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'custom-event-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('CustomEventCtrl', function(model, modelComms) {
    const ctrl = this;
    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInitAfterSave: function(saveObj) {
        ctrl.model.type = _.get(saveObj || model, ['type']) || 'COUNT';
      }
    });

    ctrl.exampleCode = "adn.inIframe.sendCustomEvent('{{adId}}', {customType: '" + model.id + "'});";
    ctrl.exampleCodeWithCustomValue = "adn.inIframe.sendCustomEvent('{{adId}}', {customType: '" + model.id + "', customValue: 2});";
    ctrl.exampleCodeWithMultipleEvents = "adn.inIframe.sendCustomEvent('{{adId}}', {events:[{customType: '" + model.id + "', customValue: 2}, {customType: 'another_custom_event_id'}]});";

    ctrl.onBeforeSubmit = function() {
      LocalNetworkInfo.obtain().addCustomEvent(ctrl.model);
    };
  });

export default MODULE_NAME;