/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";
import _ from "lodash";

const MODULE_NAME = 'api-key-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('ApiKeyCtrl', function($stateParams, model, modelComms, adnTimeShifter) {
    const ctrl = this;
    ctrl.isNew = $stateParams.isNew;

    function afterSave(savedObj) {
      // the newly generated api key is returned to the edit form exactly once
      ctrl.model.apiKey = savedObj.apiKey;
      ctrl.model.expiry = savedObj.expiry;
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterSave: afterSave
    });

    ctrl.onBeforeSubmit = function () {
      ctrl.model.expiry = _.isDate(ctrl.model.modExpiry) ? adnTimeShifter.timeShift(ctrl.model.modExpiry, true) : null;
    };
  });

export default MODULE_NAME;