/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import objMembershipCtrl from "./obj-membership-ctrl";
import { adnFormHelper } from "../../common/controller/form-helper";
import {getAvailableTeamTypes} from "../team/team-controller";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'team-group-controller';

angular.module(MODULE_NAME, [standardFormModule, objMembershipCtrl])

  .controller('TeamGroupCtrl', function(Team, LocalNetworkProfile, LocalUserPermissions, $stateParams, model, modelComms) {
    const ctrl = this;
    ctrl.isNew = $stateParams.isNew;

    ctrl.canUpdateTeamGroup = LocalUserPermissions.hasNetwork('MANAGE_SYSTEM');

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(_.filter(ctrl.allTeams, function(team) {
        return team.type === ctrl.model.type;
      }), 'id');
    };

    ctrl.typeChange = function() {
      ctrl.deselectTeams();
    };

    function afterSaveAfterInit(data) {
      _.set(ctrl.model, 'teams', _.map(_.get(data || ctrl.model, 'teams') || [], 'id'));
      ctrl.model.type = _.get(data, ['type']) || ctrl.model.type;
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterSave: function(data) {
        afterSaveAfterInit(data);
      },
      afterInit: function() {
        afterSaveAfterInit();
      }
    });

    getAvailableTeamTypes(ctrl, LocalNetworkProfile, LocalUserPermissions);

    Team.query({minimal: true}).$promise.then(function(page) {
      ctrl.allTeams = page.results;
    });

    ctrl.onBeforeSubmit = function() {
      if (ctrl.isNew) {
        LocalNetworkInfo.obtain().increment('teamGroupsCount');
      }
    };
  });

export default MODULE_NAME;