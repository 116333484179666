/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './marketplace-product-edit.html';
import listTemplate from './marketplace-product-list.html';
import copyTemplate from '../../common/copy-actions.html';

import adminModule from '../admin-module';
import productController from './marketplace-product-controller';
import productActionsController from './marketplace-product-actions-controller';
import _ from "lodash";
import {Uuid} from "../../../components/util/uuid";

const MODULE_NAME = 'marketplace-product-module';

angular.module(MODULE_NAME, [adminModule, productController, productActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('marketplace-products.marketplace-product', 'MarketplaceProduct', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        readOnly: function(LocalUserPermissions) {
          const perms = LocalUserPermissions.getAllNetworkPermissions();
          return !perms.AX_PUBLISHER_ADMIN && !perms.MANAGE_SYSTEM;
        },
        extension: /*@ngInject*/ function(NetworkData, LocalUserPermissions, Team, MarketplaceProduct, $q, $log) {
          return {
            func: function(ctrl) {
              if (ctrl.initialised) {
                return false;
              }

              const allPromises = [];
              ctrl.perms = LocalUserPermissions.getAllNetworkPermissions();

              allPromises.push(MarketplaceProduct.query({pageSize: 501}).$promise.then(function(page) {
                ctrl.productInfo = {};
                _.forEach(page.results, function(item) {
                  _.forEach(item.teams, function(team) {
                    ctrl.productInfo[team.id] = ctrl.productInfo[team.id] || [];
                    ctrl.productInfo[team.id].push({
                      id: item.id,
                      name: item.name
                    });
                  });
                });
              }));

              allPromises.push(Team.query({pageSize: 501, filterBy: 'type', filterByValue: 'AX_ADVERTISER'}).$promise.then(function(page) {
                ctrl.teamInfo = {};
                _.forEach(page.results, function(item) {
                  ctrl.teamInfo[item.id] = {
                    id: item.id,
                    name: item.name
                  };
                });
                ctrl.noTeams = !page.results.length;
              }));

              function cleanData() {
                ctrl.networkData = ctrl.networkData || new NetworkData({id: Uuid.generate(), name: 'Default Marketplace Products', type: 'defaultMarketplaceProducts'});
                ctrl.networkData.data = ctrl.networkData.data || {};
                ctrl.networkData.data.teamToProduct = ctrl.networkData.data.teamToProduct || {};
                _.forEach(ctrl.teamInfo, function(team) {
                  ctrl.networkData.data.teamToProduct[team.id] = ctrl.networkData.data.teamToProduct[team.id] || null;
                });
                const teamsToDelete = [];
                _.forEach(ctrl.networkData.data.teamToProduct, function(val, key) {
                  const teamStillExists = _.find(ctrl.teamInfo, function(team) {
                    return team.id === key;
                  });
                  if (!teamStillExists) {
                    teamsToDelete.push(key);
                  }
                });
                _.forEach(teamsToDelete, function(k) {
                  delete ctrl.networkData.data.teamToProduct[k];
                });
              }

              cleanData();

              let prevNetworkData;
              NetworkData.get({filterByValue: 'defaultMarketplaceProducts', filterBy: 'type'}, function(page) {
                $q.all(allPromises).then(function() {
                  if (page.results.length) {
                    const entry = page.results[0];
                    try {
                      entry.data.teamToProduct = JSON.parse(entry.data.teamToProduct);
                      ctrl.networkData = new NetworkData(_.cloneDeep(entry));
                      cleanData();
                      prevNetworkData = _.cloneDeep(ctrl.networkData);
                    } catch {
                      $log.warn("Found a problem saving");
                    }
                  }
                  ctrl.isSetUp = true;
                });
              });

              ctrl.edit = function() {
                ctrl.editsMade = true;
              };

              ctrl.saveProductDefaults = function() {
                ctrl.editsMade = false;
                ctrl.productSelectionPass = false;
                ctrl.productSelectionError = false;
                cleanData();
                ctrl.networkData.savePartial().then(function() {
                  ctrl.productSelectionPass = true;
                  prevNetworkData = _.cloneDeep(ctrl.networkData);
                }).catch(function() {
                  ctrl.productSelectionError = true;
                });
              };

              ctrl.cancelProductDefaults = function() {
                ctrl.networkData = _.cloneDeep(prevNetworkData);
                ctrl.editsMade = false;
                ctrl.productSelectionPass = false;
                ctrl.productSelectionError = false;
              };

              ctrl.initialised = true;
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'MarketplaceProductCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'MarketplaceProductActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'MarketplaceProduct'));
  });

export default MODULE_NAME;