/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import ngFileUpload from 'ng-file-upload';
import uiCodemirror from 'angular-ui-codemirror';

import template from './asset.html';

import standardFormModule from '../../common/standard-form-module';
import mediaTypeKeyFilter from '../../common/filters/mimeTypeFilter';
import {MediaTypeHelper} from "../../../components/util/media-type-helper";
import fileSizeOutput from './file-size-output-component';

const MODULE_NAME = 'asset-directive';

const FILE_UPLOAD_LIMIT = 1;

angular.module(MODULE_NAME, [ngFileUpload, uiCodemirror, standardFormModule, mediaTypeKeyFilter, fileSizeOutput])

  .directive('adnAsset', function(CdnAsset, Asset) {
    return {
      template: template,
      restrict: 'A',
      require: '?^^form',
      scope: {
        creative: '<?',
        paramName: '@adnParamName',
        eventHook: '<',
        type: '@',
        readOnly: '@adnReadOnly'
      },
      link: {
        pre: function(scope) {
          scope.paramName = scope.paramName || 'assets';
          scope.allFilesProcessed = true;
          scope.validationWarnings = [];
          scope.parentObject = scope.creative || scope.layout;
          scope.isReadOnly = scope.readOnly === true || scope.readOnly === "true";
        },
        post: function(scope, element, attrs, form) {
          if (!scope.parentObject) {
            return;
          }
          let assessAssets = function() {
            if (scope.parentObject[scope.paramName].length < 1) {
              if (scope.parentObject.creativeFileName) {
                scope.parentObject[scope.paramName].push(_.pick(scope.parentObject, ['creativeFileName', 'creativeWidth', 'creativeHeight', 'creativeFileSizeBytes', 'creativeMimeType', 'creativeCdnId']));
              }
              if (scope.parentObject.fileName) {
                scope.parentObject[scope.paramName].push(_.pick(scope.parentObject, ['fileName', 'width', 'height', 'fileSizeBytes', 'mimeType', 'cdnId']));
              }
            }
          };
          scope.parentObject[scope.paramName] = scope.parentObject[scope.paramName] || [];
          if (!_.isArray(scope.parentObject[scope.paramName])) {
            scope.parentObject[scope.paramName] = [scope.parentObject[scope.paramName]];
          }
          assessAssets();

          let uploadMethod = CdnAsset.upload;
          let acceptableMimeTypeMethod = function() {
            return true;
          };
          scope.accept = '.webp,.jpg,.png,.jpeg,.gif,.zip,.swf,.html,.htm,.js,.txt,.json,.css,.xhtml,.xml,.woff,.eot,.svg,.ttf,.woff,.woff2,.htc,.mp4,.mp3,.webm,.vtt,.srt';
          if (scope.type === 'BULK_THIRD_PARTY') {
            uploadMethod = Asset.bulkThirdPartyUpload;
            scope.accept = '.xls,.xlsx,.csv';
            scope.maxFiles = 1;
          } else if (scope.type === 'BULK_IMAGE') {
            uploadMethod = Asset.bulkUpload;
            scope.accept = '.jpg,.png,.jpeg,.gif';
            scope.maxFiles = 10;
            acceptableMimeTypeMethod = function(file) {
              return _.get(MediaTypeHelper.getMediaType(file.type), 'image') || false;
            };
          } else if (scope.type === 'BULK_VIDEO') {
            uploadMethod = Asset.bulkUpload;
            scope.accept = '.mp4,.webm,.mp3,.quicktime,.hls,.dash,.mov';
            scope.maxFiles = 10;
            acceptableMimeTypeMethod = function(file) {
              return _.get(MediaTypeHelper.getMediaType(file.type), 'video') || false;
            };
          } else if (scope.type === 'BULK_HTML') {
            uploadMethod = Asset.bulkUpload;
            scope.accept = '.zip';
            scope.maxFiles = 10;
            acceptableMimeTypeMethod = function(file) {
              return _.get(MediaTypeHelper.getMediaType(file.type), 'zip') || false;
            };
          }

          let fileUploadLimit = scope.maxFiles || FILE_UPLOAD_LIMIT,
            ctrl = form || {$setValidity: _.noop};

          let updateWarnings = function(paramWarnings) {
            if (paramWarnings) {
              scope.validationWarnings = scope.validationWarnings.concat(_.isArray(paramWarnings) ? paramWarnings : [paramWarnings]);
            } else {
              scope.validationWarnings = [];
            }
          };

          scope.upload = function(allFiles) {
            scope.filesFailedUpload = [];
            const incorrectMimeType = [];
            updateWarnings();
            if (!allFiles || !_.isArray(allFiles) || allFiles.length < 1) {
              ctrl.$setValidity('upload', false);
              return;
            }

            let files = _.clone(_.slice(allFiles, 0, fileUploadLimit));
            if (allFiles.length > fileUploadLimit) {
              updateWarnings({
                text: 'Only {{fileUploadLimit}} files can be uploaded at a time. The last {{restFilesCount}} of the {{allFilesCount}} selected files were not uploaded.',
                parameters: {
                  fileUploadLimit: fileUploadLimit,
                  restFilesCount: allFiles.length - fileUploadLimit,
                  allFilesCount: allFiles.length
                },
                preventsAdServing: false
              });
            }
            files = _.filter(files, function(f) {
              let mimeTypeResult = acceptableMimeTypeMethod(f);
              if (!mimeTypeResult) {
                updateWarnings({
                  text: 'The file named {{fileName}} was not uploaded because it is not the correct type.',
                  parameters: {
                    fileName: f.name
                  },
                  preventsAdServing: false
                });
                incorrectMimeType.push(f);
              }
              return mimeTypeResult;
            });

            const uploadStats = {
              allFilesCount: allFiles.length,
              fileUploadLimit: fileUploadLimit,
              filesCount: files.length,
              filesFailedUpload: scope.filesFailedUpload,
              incorrectMimeType: incorrectMimeType,
              apiResponseCount: 0
            };

            allFiles = null;

            if (files.length < 1) {
              return;
            }

            // add a pending validation
            ctrl.$setValidity('upload');
            scope.allFilesProcessed = false;
            scope.allFilesProgress = 0;

            let setAllFilesProgress = function() {
                let progress = _.reduce(files, function(result, file) {
                  return result + (_.isFinite(file.progress) ? file.progress : 0);
                }, 0);
                scope.allFilesProgress = Math.min(100, parseInt(progress / files.length, 10));
              },
              allFilesChecks = function() {
                if (!_.find(files, ['uploaded', undefined])) {
                  scope.allFilesProcessed = true;
                }

                // if at least one file is valid, then say the upload is a success
                ctrl.$setValidity('upload', _.some(files, 'validity'));

                scope.filesFailedUpload = _.filter(files, ['validity', false]);
              };

            _.forEach(files, function(file) {
              file.validity = undefined;

              const errorMethod = function(error) {
                file.validity = false;

                let warnings = [];
                if (!error.data || (error.status === -1 && error.data === null)) {
                  if (scope.type === 'BULK_THIRD_PARTY' || scope.type === 'BULK_IMAGE' || scope.type === 'BULK_VIDEO') {
                    warnings.push({
                      text: 'We could not extract any third-party creative details from the document that was uploaded.',
                      preventsAdServing: false
                    });
                  } else {
                    // sometimes the API can crap out and issue a time-out, which is handled here.
                    warnings.push({
                      text: "The upload failed to complete. Try uploading again or, if possible, refreshing the page to see which resources have been uploaded.",
                      preventsAdServing: false
                    });
                  }
                } else {
                  // API can return validation error or general error.
                  // General error on unsupported mime type, validation error on everything else (like file size too big).
                  if (error.data.errors) {
                    warnings = _.flatten(warnings.concat(_.map(error.data.errors, function(value) {
                      let returnValue = angular.copy(value.error);
                      returnValue.preventsAdServing = true;
                      return returnValue;
                    })));
                  } else if (error.data.map) {
                    warnings = _.flatten(warnings.concat(_.map(error.data.map, function(value) {
                      return {text: value, preventsAdServing: true};
                    })));
                  } else if (error.data) {
                    warnings.push(error.data);
                  }
                }

                uploadStats.apiResponseCount++;
                updateWarnings(warnings);
                allFilesChecks();
              };

              const progressMethod = function(progressEvent) {
                file.progress = Math.min(100, parseInt(100 * progressEvent.loaded / progressEvent.total, 10));
                setAllFilesProgress();
              };

              uploadMethod(scope.parentObject.id, {file: file}).then(function(parentObject) {
                file.validity = true;

                if (parentObject[scope.paramName]) {
                  scope.parentObject[scope.paramName] = _.isArray(parentObject[scope.paramName]) ? [_.last(parentObject[scope.paramName])] : [parentObject[scope.paramName]];
                  scope.isReadOnly = true;
                } else {
                  scope.parentObject[scope.paramName] = _.isArray(parentObject) ? parentObject : [parentObject];
                }

                allFilesChecks();
                uploadStats.apiResponseCount++;
                if (_.isFunction(_.get(scope.eventHook, 'onUpdate'))) {
                  scope.eventHook.onUpdate(parentObject, scope.allFilesProcessed, uploadStats);
                }
                assessAssets();
              }, errorMethod, progressMethod);
            });
          };
        }
      }
    };
  });

export default MODULE_NAME;