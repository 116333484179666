/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import {PERMISSIONS} from "../../components/auth/auth-constants";

import localUserPermissions from '../../components/session/local-user-permissions';

const MODULE_NAME = 'admin-module';

angular.module(MODULE_NAME, [
  uiRouter,
  localUserPermissions
])

  .config(function($stateProvider) {
    $stateProvider.state('app.adm', {
      abstract: true,
      data: {
        app: 'ADVERTISING'
      },
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageSystem.apiKey)
            && !LocalUserPermissions.hasDataNetworkPermission(PERMISSIONS.dataAdmin.apiKey);
        }
      },
      url: '/admin'
    });
  });

export default MODULE_NAME;