import angular from 'angular';

import editTemplate from './folio-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './folio-list.html';

import standardModule from '../common/standard-module';
import folioController from './folio-controller';
import folioInformationCtrl from './folio-information';
import folioListComponent from '../common/controller/folio-list-component';
import folioActionsController from './folio-actions-controller';
import informationTemplate from "./folio-information.html";
import singlePanelBottomTemplate from "../common/single-panel-bottom.html";
import {folioListCommons} from "./folio-commons";

const MODULE_NAME = 'folio-module';

angular.module(MODULE_NAME, [standardModule, folioController, folioListComponent, folioActionsController, folioInformationCtrl])
  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('folios.folio', 'Folio', 'CreativeSet', 'app.adv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile, LocalUserPermissions) {
          return {
            func: function(ctrl, afterFill) {
              folioListCommons(ctrl, afterFill, LocalNetworkProfile, LocalUserPermissions);
            }
          };
        },
        sectionParam: function() {
          return 'folios';
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: singlePanelBottomTemplate
          },
          'topPanel@app.folios.folio': {
            template: editTemplate,
            controller: 'FolioCtrl',
            controllerAs: 'resource'
          },
          'bottomPanel@app.folios.folio': {
            template: informationTemplate,
            controller: 'FolioInformationCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'FolioActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Folio', [{type: 'Advertiser', func: 'canBeShownAdvertiser'}]));
  });

export default MODULE_NAME;
