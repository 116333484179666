/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

import {Inject} from "@angular/core";
import {ApiConfig} from "../../api";

export class LivePreviewResource {
  private uri = ApiConfig.obtain().getVersionedUri('livepreview/create');

  constructor(@Inject('$http') private $http) {
  }

  create(viewUrl: string, networkId: string, teamId: string, layoutId: string, layoutParams, width, height) {
    return this.$http.post(this.uri, {
      networkId: networkId,
      layoutId: layoutId,
      teamId: teamId,
      layoutParameters: layoutParams,
      viewUrl: viewUrl,
      width: width,
      height: height
    }, {params: {context: networkId}}).then(_.iteratee('data'));
  }
}
