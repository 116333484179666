/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './report-template-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './report-template-list.html';

import standardModule from '../common/standard-module';
import reportTemplateController from './report-template-controller';
import reportTemplateActions from './report-template-actions-controller';

const MODULE_NAME = 'report-templates-module';

angular.module(MODULE_NAME, [standardModule, reportTemplateController, reportTemplateActions])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('report-templates.report-template', 'ReportTemplate', 'app.rep', 'app', listTemplate);

    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'ReportTemplateCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: 'ReportTemplateActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          }
        }
      }, 'ReportTemplate'));
  });

export default MODULE_NAME;