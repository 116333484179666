/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'label-repeater-component';

angular.module(MODULE_NAME, [])

  .component('adnLabelRepeater', {
    template: `
        <a class="resourceLabel label label-primary" ng-if="!ctrl.readOnly" ng-repeat="label in ctrl.labels" ui-sref="app.search({labels: label})">{{label}}</a>
        <span class="resourceLabel label label-primary" ng-if="ctrl.readOnly" ng-repeat="label in ctrl.labels">{{label}}</span>
    `,
    bindings: {
      labels: '<',
      readOnly: '<'
    },
    controllerAs: 'ctrl',
    controller: function() {
    }
  });

export default MODULE_NAME;