/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {ALL_COUNTRIES, Country} from "./countries";

@Component({
  selector: 'country-select',
  template: `<select adn-form-control (ngModelChange)="onChange()" class="form-control" style="width:auto" [(ngModel)]="countryModel[countryParam]"><option value=""></option><option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option></select>`
})
export class CountrySelectComponent implements OnInit {
  @Input() countryModel: any;
  @Input() countryParam: any;
  @Input() countryPrio?: Array<string> = ['IS', 'FI', 'CH', 'RO', 'AT', 'SE', 'NO', 'DK', 'MD', 'TH', 'AE', 'AU', 'LT', 'GB', 'US', 'GR', 'DE', 'ES', 'EG', 'JO', 'QA', 'VN', 'ID', 'PH', 'MM', 'PK'];
  @Input() changeHook: () => void;
  countries: Array<Country>;

  ngOnInit(): void {
    let allCountries: Array<Country> = _.clone(ALL_COUNTRIES);

    _.forEach(allCountries, (c) => {
      if (_.indexOf(this.countryPrio, c.code) > -1) {
        c.prio = 1;
      } else {
        c.prio = 10;
      }
    });

    allCountries.push({
      name: '────────────────────',
      code: '',
      prio: 2,
      disabled: true
    });

    allCountries = _.orderBy(allCountries, ['prio']);

    this.countries = allCountries;
  }

  onChange(): void {
    if (_.isFunction(this.changeHook)) {
      this.changeHook();
    }
  }
}
