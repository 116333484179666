/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './coupon-edit.html';
import listTemplate from './coupon-list.html';
import copyTemplate from '../../common/copy-actions.html';

import adminModule from '../admin-module';
import couponController from './coupon-controller';
import couponActionsController from './coupon-actions-controller';
import { validityCalculation } from "./coupon-controller";

const MODULE_NAME = 'coupon-module';

angular.module(MODULE_NAME, [adminModule, couponController, couponActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('coupons.coupon', 'Coupon', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function() {
          return {
            func: function(ctrl) {
              _.forEach(ctrl.items, function(item) {
                validityCalculation(item);
              });
            }
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'CouponCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'CouponActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'Coupon'));
  });

export default MODULE_NAME;