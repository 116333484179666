/**
 * Copyright © 2024 Adnuntius AS.
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'form-submission-message',
  template: `<div *ngIf="form.validSubmission && form.$pristine" class="alert alert-success clickable" (click)="form.validSubmission=false" title="Click to dismiss message"><span class="fa fa-times pull-right"></span>Your updates were saved successfully</div>`
})
export class FormSubmissionMessage {
  @Input() form: any;
}