/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../common/constants/object-type";

import titleTemplate from '../base/default-title-template.html';
import queryTemplate from '../report/query/query-edit.html';

import standardModule from '../common/standard-module';
import queryHelper from '../common/controller/query-helper';

const MODULE_NAME = 'data-queries-module';

angular.module(MODULE_NAME, [standardModule, queryHelper])

  .config(function($stateProvider) {
    $stateProvider.state('app.data-queries', {
      url: '/data-queries',
      views: {
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Queries"
      }
    }).state('app.data-queries.folder', {
            url: '/data-queries/folder',
            views: {
              'content@app': {
                controller: function(adnQueryHelper) {
                  const ctrl = this;
                  adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.Folder);
                },
                controllerAs: 'ctrl',
                template: queryTemplate
              }
            },
            data: {
              title: "Folder Queries"
            }
          }).state('app.data-queries.targeting', {
      url: '/data-queries/targeting',
      views: {
        'content@app': {
          controller: function(adnQueryHelper) {
            const ctrl = this;
            adnQueryHelper.setUpQuery(ctrl, OBJECT_TYPE.UserSegment);
          },
          controllerAs: 'ctrl',
          template: queryTemplate
        }
      },
      data: {
        title: "Targeted Advertising Queries"
      }
    });
  });

export default MODULE_NAME;