/**
 * Copyright © 2024 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as _ from "lodash";

type Creative = {
  id: string;
  name: string;
};

export class LocalBulkCreativeInfo {
  private static instance: LocalBulkCreativeInfo;
  private static readonly BULK_CREATIVE_INFO = 'bulkCreative';
  private static readonly BULK_LIBRARY_CREATIVE_INFO = 'bulkLibraryCreative';
  private static readonly VIDEO_CREATIVE_INFO = 'videoCreative';

  creatives: Array<Creative>;
  libraryCreatives: Array<Creative>;
  videoCreatives: Array<Creative>;

  private constructor() {
  }

  public static obtain(): LocalBulkCreativeInfo {
    if (!this.instance) {
      this.instance = new LocalBulkCreativeInfo();
    }
    this.instance.refresh();
    return this.instance;
  }

  private save() {
    LocalStorage.obtain().setItem(LocalBulkCreativeInfo.BULK_CREATIVE_INFO, this.creatives);
    LocalStorage.obtain().setItem(LocalBulkCreativeInfo.BULK_LIBRARY_CREATIVE_INFO, this.libraryCreatives);
    LocalStorage.obtain().setItem(LocalBulkCreativeInfo.VIDEO_CREATIVE_INFO, this.videoCreatives);
  }

  private refresh() {
    const localStorageInfo = LocalStorage.obtain().getItem(LocalBulkCreativeInfo.BULK_CREATIVE_INFO);
    this.creatives = _.isEmpty(localStorageInfo) ? _.isEmpty(this.creatives) ? {} : this.creatives : localStorageInfo;

    const bulkLibraryStorageInfo = LocalStorage.obtain().getItem(LocalBulkCreativeInfo.BULK_LIBRARY_CREATIVE_INFO);
    this.libraryCreatives = _.isEmpty(bulkLibraryStorageInfo) ? _.isEmpty(this.libraryCreatives) ? {} : this.libraryCreatives : bulkLibraryStorageInfo;

    const videoStorageInfo = LocalStorage.obtain().getItem(LocalBulkCreativeInfo.VIDEO_CREATIVE_INFO);
    this.videoCreatives = _.isEmpty(videoStorageInfo) ? _.isEmpty(this.videoCreatives) ? {} : this.videoCreatives : videoStorageInfo;
  }

  setCreatives(creatives: Array<any>) {
    this.updateCreatives(creatives, 'creatives');
  }

  getCreatives(): Array<Creative> {
    LocalBulkCreativeInfo.instance.refresh();
    return this.creatives;
  }

  setLibraryCreatives(creatives: Array<any>) {
    this.updateCreatives(creatives, 'libraryCreatives');
  }

  getLibraryCreatives(): Array<Creative> {
    LocalBulkCreativeInfo.instance.refresh();
    return this.libraryCreatives;
  }

  setVideoCreatives(creatives: Array<any>) {
    this.updateCreatives(creatives, 'videoCreatives');
  }

  getVideoCreatives(): Array<Creative> {
    LocalBulkCreativeInfo.instance.refresh();
    return this.videoCreatives;
  }

  private updateCreatives(creatives: Array<any>, param: string) {
    this[param] = _.map(_.filter(creatives, function (c) {
      return c.id && c.name;
    }), function (c) {
      return {
        id: c.id,
        name: c.name
      };
    });
    LocalBulkCreativeInfo.instance.save();
  }
}
