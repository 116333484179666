/**
 * Copyright © 2024 Adnuntius AS.
 */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'coupon-validity-component',
  template: `
    <span class="label {{labelClass[validity]}}">{{ validity }}</span>
  `
})
export class CouponValidityComponent implements OnInit {
  labelClass: any;
  @Input() validity: string;

  constructor() {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass.Valid = 'label-success';
    this.labelClass.Invalid = 'label-warning';
  }
}
