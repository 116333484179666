/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

import {IpAddress} from "../../../util/ip-valid";
import {AdServerConfig} from "../../api";
import {Inject} from "@angular/core";

export class DiagRequestResource {
  constructor(@Inject('$http') private $http: any, @Inject('$log') private $log: any, @Inject('$q') private $q: any) {
  }

  private updateParams(val: any) {
    if (val) {
      return _.map(_.filter(val.split(","), (c: string) => {
        return _.trim(c);
      }), (c: string) => {
        return _.trim(c);
      });
    }
    return val;
  }

  diagReq(type, qStringParams) {
    if (!qStringParams.auId) {
      this.$log.warn("Need an auId to do the diagnostic request.");
    }
    const qs = _.clone(qStringParams);

    qs.segments = this.updateParams(qs.segments);
    qs.c = this.updateParams(qs.c);
    qs.keywords = this.updateParams(qs.keywords);

    if (_.isArray(qs.kv)) {
      qs.kv = _.map(_.filter(qs.kv, (kv) => {
        return _.trim(kv.k) && _.trim(kv.v);
      }), (kv) => {
        const entry = {};
        entry[_.trim(kv.k)] = this.updateParams(kv.v);
        return entry;
      });
    }

    if (qs.ip) {
      if (!IpAddress.isValid(qs.ip)) {
        return this.$q.reject({info: 'Must enter a valid IP address to test against'});
      }
    }
    if (qs.longitude || qs.latitude) {
      if (!(qs.longitude && qs.latitude)) {
        return this.$q.reject({info: 'Both latitude and longitude must be specified. Not just one or the other.'});
      }
      if (_.get(qs, 'longitude').length > 0 && isNaN(qs.longitude)) {
        return this.$q.reject({info: 'If specified, both latitude and longitude must be a number.'});
      }
      if (_.get(qs, 'latitude').length > 0 && isNaN(qs.latitude)) {
        return this.$q.reject({info: 'If specified, both latitude and longitude must be a number.'});
      }
      qs.latitude = parseFloat(qs.latitude);
      qs.longitude = parseFloat(qs.longitude);
    }
    const uri = AdServerConfig.obtain().getUri('i');
    return this.$http.get(uri, {
      params: _.assign({}, {format: type, tzo: new Date().getTimezoneOffset()}, qs)
    }).then(_.iteratee('data'));
  }

  dx(qStringParams) {
    return this.diagReq("dx", qStringParams);
  }

  dxMx(qStringParams) {
    return this.diagReq("dxMx", qStringParams);
  }
}