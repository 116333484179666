/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './creative-set-edit.html';
import analysisTemplate from './creative-set-analysis.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './creative-set-list.html';

import standardModule from '../common/standard-module';
import creativeSetController from './creative-set-controller';
import creativeSetAnalysis from './creative-set-analysis';
import creativeSetActions from './creative-set-actions-controller';
import creativeSetListComponent from './creative-set-list-component';
import {creativeSetBulkAction, creativeSetListCommons} from "./creative-set-commons";
import {PERMISSIONS} from "../../components/auth/auth-constants";

const MODULE_NAME = 'creative-set-module';

angular.module(MODULE_NAME, [standardModule, creativeSetController, creativeSetAnalysis, creativeSetActions, creativeSetListComponent])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('creative-sets.creative-set', 'CreativeSet', 'Creative', 'app.adv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasAny(PERMISSIONS.manageAdvertising.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.axAdvertiser.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.creativeAgency.apiKey);
        },
        listConfig: function() {
          return {};
        },
        extension: /*@ngInject*/ function(LocalNetworkProfile, adnTimeShifter) {
          return {
            func: function(ctrl, afterFill) {
              creativeSetListCommons(ctrl, afterFill, LocalNetworkProfile, adnTimeShifter);
            }
          };
        },
        bulk: function() {
          return {
            bulkAction: creativeSetBulkAction
          };
        },
        sectionParam: function() {
          return 'creativeSets';
        },
        model: function() {
          return {};
        },
        modelComms: function() {
          return {};
        }
      },
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          advertiserFromParam: /*@ngInject*/ function($stateParams) {
            if ($stateParams.advertiserId && $stateParams.advertiserName) {
              return {
                id: $stateParams.advertiserId,
                name: $stateParams.advertiserName
              };
            }
          },
          folioFromParam: /*@ngInject*/ function($stateParams) {
            if ($stateParams.folioId && $stateParams.folioName) {
              return {
                id: $stateParams.folioId,
                name: $stateParams.folioName
              };
            }
          },
        },
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            controller: 'CreativeSetActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          },
          'leftPanel@app.creative-sets.creative-set': {
            template: editTemplate,
            controller: 'CreativeSetCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.creative-sets.creative-set': {
            template: analysisTemplate,
            controller: 'CreativeSetAnalysisCtrl',
            controllerAs: 'resource'
          }
        }

      }, 'CreativeSet', ['Advertiser', 'Folio']));
  });

export default MODULE_NAME;